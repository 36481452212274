import {
  PARTNER_SELECT_ONE,
  POST_PARTNER_SELECT_ONE,
  PARTNER_CHANGE_SECTION,

  PARTNER_GET_USER_PARTNER_LIST,
  PARTNER_GET_USER_PARTNER_LIST_SUCCESS,
  PARTNER_GET_USER_PARTNER_LIST_ERROR,

  PARTNER_GET_LIST,
  PARTNER_GET_LIST_SUCCESS,
  PARTNER_GET_LIST_ERROR,

  PARTNER_GET_ONE,
  PARTNER_GET_ONE_SUCCESS,
  PARTNER_GET_ONE_ERROR,

  PARTNER_ADD_ONE,
  PARTNER_ADD_ONE_SUCCESS,
  PARTNER_ADD_ONE_ERROR,

  PARTNER_EDIT_ONE,
  PARTNER_EDIT_ONE_SUCCESS,
  PARTNER_EDIT_ONE_ERROR,

  PARTNER_DELETE_ONE,
  PARTNER_DELETE_ONE_SUCCESS,
  PARTNER_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

let partner = null;
let post_partner = null;
let partner_inst = null;
try {
  
  partner = JSON.parse(localStorage.getItem('partner'))
  post_partner = JSON.parse(localStorage.getItem('partner'))
 // partner_inst= JSON.parse(localStorage.getItem('partner_inst'))
  //console.log("partner :", partner);
  //console.log("partner_inst :", partner_inst);
  
} catch (error) {
  partner = null;
  //console.log("error :",error);
  
}


const INIT_STATE = {
  partners: [],
  count: 0,
  partner,
  partner_inst:partner_inst,
  post_partner,
  error: '',
  loading: false,
  section: { name: '' }
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    // GET USER PARTNERS LIST
    case PARTNER_GET_USER_PARTNER_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PARTNER_GET_USER_PARTNER_LIST_SUCCESS:
      const sorted_partners = action.payload.partners || []
      sorted_partners.sort((a, b) => {
        let keyA = a.partnertype,
          keyB = b.partnertype;
        if (keyA > keyB) return -1;
        if (keyA < keyB) return 1;
        return 0;
      });
      let post_partner = sorted_partners[0]
      let partner = sorted_partners[0]

      return {
        ...state, loading: false,
        partners: sorted_partners,
        count: action.payload.count || 0,
        post_partner,
        //partner,

      };
    case PARTNER_GET_USER_PARTNER_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // GET LIST
    case PARTNER_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PARTNER_GET_LIST_SUCCESS:
      // if (action.payload.partners.length === 0)
      //   localStorage.setItem("partner", null)
      return {
        ...state, loading: false,
        partners: action.payload.partners || [],
        count: action.payload.count || 0
      };
    case PARTNER_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






    // GET ONE
    case PARTNER_GET_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        partner: action.payload.partner
      };
    case PARTNER_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };





    //SELECT PARTNER (to be global var)
    case PARTNER_SELECT_ONE:
      console.log('action.payload', action.payload);

      if (!action.payload) localStorage.removeItem('partner')
      else {
        localStorage.setItem("partner", JSON.stringify(action.payload))
        localStorage.setItem("partner_inst", JSON.stringify(action.payload.institution))

      }

      return { ...state, partner: action.payload, partner_inst: action.payload.institution };
    //SELECT POST PARTNER (to be global var)
    case POST_PARTNER_SELECT_ONE:

      if (!action.payload) localStorage.removeItem('post_partner')
      else {

        localStorage.setItem("partner_inst", JSON.stringify(action.payload.institution))
        localStorage.setItem("post_partner", JSON.stringify(action.payload))
      }

      return { ...state, post_partner: action.payload, partner_inst: action.payload.institution };

    //CHANGE PARTNER SECTION (LIKE TIMETABLE)
    case PARTNER_CHANGE_SECTION:
      if (!action.payload) return { ...state, section: { name: '' } };
      return { ...state, section: action.payload };









    // ADD ONE
    case PARTNER_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return {
        ...state, loading: false,
        partners: [action.payload.partner, ...state.partners]
      };
    case PARTNER_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case PARTNER_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        partner: action.payload.partner
      };
    case PARTNER_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // DELETE ONE
    case PARTNER_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case PARTNER_DELETE_ONE_SUCCESS:
      const partners = state.partners
      if (action.payload.index != null && action.payload.index != undefined)
        partners.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        partners
      };
    case PARTNER_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}
