import {
  DIPLOMA_GET_LIST,
	DIPLOMA_GET_LIST_SUCCESS,
  DIPLOMA_GET_LIST_ERROR,

  DIPLOMA_GET_ONE,
	DIPLOMA_GET_ONE_SUCCESS,
  DIPLOMA_GET_ONE_ERROR,

  DIPLOMA_ADD_ONE,
  DIPLOMA_ADD_ONE_SUCCESS,
  DIPLOMA_ADD_ONE_ERROR,

  DIPLOMA_EDIT_ONE,
  DIPLOMA_EDIT_ONE_SUCCESS,
  DIPLOMA_EDIT_ONE_ERROR,

  DIPLOMA_DELETE_ONE,
  DIPLOMA_DELETE_ONE_SUCCESS,
  DIPLOMA_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getDiplomasList = (payload) => ({
  type: DIPLOMA_GET_LIST,
  payload
});
export const getDiplomasListSuccess = (payload) => ({
  type: DIPLOMA_GET_LIST_SUCCESS,
  payload
});
export const getDiplomasListError = (payload) => ({
  type: DIPLOMA_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneDiploma = (payload) => ({
  type: DIPLOMA_GET_ONE,
  payload
});
export const getOneDiplomaSuccess = (payload) => ({
  type: DIPLOMA_GET_ONE_SUCCESS,
  payload
});
export const getOneDiplomaError = (payload) => ({
  type: DIPLOMA_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addDiploma = (payload) => ({
  type: DIPLOMA_ADD_ONE,
  payload
});
export const addDiplomaSuccess = (payload) => ({
  type: DIPLOMA_ADD_ONE_SUCCESS,
  payload
});
export const addDiplomaError = (payload) => ({
  type: DIPLOMA_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editDiploma = (payload) => ({
  type: DIPLOMA_EDIT_ONE,
  payload
});
export const editDiplomaSuccess = (payload) => ({
  type: DIPLOMA_EDIT_ONE_SUCCESS,
  payload
});
export const editDiplomaError = (payload) => ({
  type: DIPLOMA_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneDiploma = (payload) => ({
  type: DIPLOMA_DELETE_ONE,
  payload
});
export const deleteOneDiplomaSuccess = (payload) => ({
  type: DIPLOMA_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneDiplomaError = (payload) => ({
  type: DIPLOMA_DELETE_ONE_ERROR,
  payload
});