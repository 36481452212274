import {
  PAYMENT_GET_LIST,
  PAYMENT_GET_LIST_SUCCESS,
  PAYMENT_GET_LIST_ERROR,
  PAYMENT_GET_LIST_BY_FILTER,
	PAYMENT_GET_LIST_BY_FILTER_SUCCESS,
  PAYMENT_GET_LIST_BY_FILTER_ERROR,
  PAYMENT_GET_ONE,
  PAYMENT_GET_ONE_SUCCESS,
  PAYMENT_GET_ONE_ERROR,
  PAYMENT_ADD_ONE,
  PAYMENT_ADD_ONE_SUCCESS,
  PAYMENT_ADD_ONE_ERROR,
  PAYMENT_EDIT_ONE,
  PAYMENT_EDIT_ONE_SUCCESS,
  PAYMENT_EDIT_ONE_ERROR,
  PAYMENT_PRINT,
  PAYMENT_PRINT_SUCCESS,
  PAYMENT_PRINT_ERROR,
  PAYMENT_CHARGE,
  PAYMENT_CHARGE_SUCCESS,
  PAYMENT_CHARGE_ERROR,
  PAYMENT_DELETE_ONE,
  PAYMENT_DELETE_ONE_SUCCESS,
  PAYMENT_DELETE_ONE_ERROR,
  PAYMENTS_DELETE_MANY,
  PAYMENTS_DELETE_MANY_SUCCESS,
  PAYMENTS_DELETE_MANY_ERROR,
  PAYMENT_GET_ID,
  PAYMENT_GET_ID_SUCCESS ,
  PAYMENT_GET_ID_ERROR ,
  PAYMENT_GET_SELECTED_FILTER
} from '../../actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import  notificationMessages  from '../../../lang/locales/notificationMsg';

const INIT_STATE = {
  payments: [],
  count: 0,
  payment_id:"",
  filter:[],
  payment: null,
  error: '',
  print_success:null
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case PAYMENT_GET_LIST:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PAYMENT_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        payments: action.payload.payments || [],
        count: action.payload.count || 0
      };
    case PAYMENT_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET BY FILTER
    case PAYMENT_GET_LIST_BY_FILTER:
      return {
        ...state, loading: true, error: '',
        count: 0
      };
    case PAYMENT_GET_LIST_BY_FILTER_SUCCESS:
      return {
        ...state, loading: false,
        payments: action.payload.payments || [],
        count: action.payload.count || 0
      };
    case PAYMENT_GET_LIST_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET ONE
    case PAYMENT_GET_ONE:
      return { ...state, loading: true, error: '', payment: null };
    case PAYMENT_GET_ONE_SUCCESS:


      return {
        ...state, loading: false, error: '',
        payment: action.payload.payment
      };
    case PAYMENT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET PAYMENT ID
    case PAYMENT_GET_ID:
      return { ...state, loading: true, error: ''};
    case PAYMENT_GET_ID_SUCCESS:
      return { ...state, loading: false, error: '',
        payment_id: action.payload.payment_id
      };
    case PAYMENT_GET_ID_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    case   PAYMENT_GET_SELECTED_FILTER:
      // console.log("PAYMENT_GET_SELECTED_FILTER ",action.payload);
      return { ...state, loading: true, error: '',
        filter: action.payload || null,
  
      };


    // ADD ONE
    case PAYMENT_ADD_ONE:
      //console.log('reducer add one');

      return { ...state, loading: true, error: '' };
    case PAYMENT_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false,
        payments: [action.payload.payment, ...state.payments]
      };
    case PAYMENT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"],  messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case PAYMENT_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case PAYMENT_EDIT_ONE_SUCCESS:
      NotificationManager.success("Edited", messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        payment: action.payload.payment
      };
    case PAYMENT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // PRINT 
    case PAYMENT_PRINT:
      return { ...state, loading: true,print_success:false, error: '' };
    case PAYMENT_PRINT_SUCCESS:
      NotificationManager.success(messages["Print"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',print_success:true,
        path: action.payload.path
      };
    case PAYMENT_PRINT_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message,print_success:false, };


      
    // CHARGE
    case PAYMENT_CHARGE:
      return { ...state, loading: true,charge_success:false, error: '' };
    case PAYMENT_CHARGE_SUCCESS:
      NotificationManager.success(messages["Print"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',charge_success:true,
        charge_path: action.payload.path
      };
    case PAYMENT_CHARGE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"] , messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message,charge_success:false, };





    // DELETE ONE
    case PAYMENT_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case PAYMENT_DELETE_ONE_SUCCESS:
      const payments = state.payments
      if (action.payload.index != null && action.payload.index != undefined)
        payments.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        payments
      };
    case PAYMENT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"] , messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

  // DELETE MANY
  case PAYMENTS_DELETE_MANY:
    return { ...state, loading: true, error: '' };
  case PAYMENTS_DELETE_MANY_SUCCESS:
    const payments_list = state.payments
   

    if (action.payload.data.ids.length > 0) {
 
      for (let i = 0; i < action.payload.data.ids.length; i++) {

        let index_payment = -1
        for (let j = 0; j < payments_list.length; j++) {
          if (payments_list[j]._id === action.payload.data.ids[i]) {
            index_payment = j; break;
          }
        }
        if (index_payment > -1) payments_list.splice(index_payment, 1)
      }
    }
    return {
      ...state, loading: false, error: '',
      payments: payments_list
    };
  case PAYMENTS_DELETE_MANY_ERROR:
    NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
    return { ...state, loading: false, error: action.payload.message };



    default: return { ...state };
  }
}
