import {
    EXAMNOTES_GET_LIST,
    EXAMNOTES_GET_LIST_SUCCESS,
    EXAMNOTES_GET_LIST_ERROR,

    EXAMNOTES_GET_ONE,
    EXAMNOTES_GET_ONE_SUCCESS,
    EXAMNOTES_GET_ONE_ERROR,
    
    EXAMNOTES_ADD_ONE,
    EXAMNOTES_ADD_ONE_SUCCESS,
    EXAMNOTES_ADD_ONE_ERROR,

    EXAMNOTES_GET_BY_FILTER,
    EXAMNOTES_GET_BY_FILTER_SUCCESS,
    EXAMNOTES_GET_BY_FILTER_ERROR,
    
    EXAMNOTES_EDIT_ONE,
    EXAMNOTES_EDIT_ONE_SUCCESS,
    EXAMNOTES_EDIT_ONE_ERROR,
   
    EXAMNOTES_DELETE_ONE,
    EXAMNOTES_DELETE_ONE_SUCCESS,
    EXAMNOTES_DELETE_ONE_ERROR,
    
    EXAMNOTES_DELETE_MANY,
    EXAMNOTES_DELETE_MANY_SUCCESS,
    EXAMNOTES_DELETE_MANY_ERROR,
  } from '../actions';

// GET LIST
export const getExamNotesList = (payload) => ({
    type: EXAMNOTES_GET_LIST,
    payload
  });
export const getExamNotesListSuccess = (payload) => ({
    type: EXAMNOTES_GET_LIST_SUCCESS,
    payload
  });
export const getExamNotesListError = (payload) => ({
    type: EXAMNOTES_GET_LIST_ERROR,
    payload
  });


// GET One
export const getOneExamNotes = (payload) => ({
    type: EXAMNOTES_GET_ONE,
    payload
  });
export const getOneExamNotesSuccess = (payload) => ({
    type: EXAMNOTES_GET_ONE_SUCCESS,
    payload
  });
export const getOneExamNotesError = (payload) => ({
    type: EXAMNOTES_GET_ONE_ERROR,
    payload
  });
 

// GET EXam NOTE BY FILTER

export const getExamNotesByFilter = (payload) => ({
  type: EXAMNOTES_GET_BY_FILTER,
  payload
});
export const getExamNotesByFilterSuccess = (payload) => ({
  type: EXAMNOTES_GET_BY_FILTER_SUCCESS,
  payload
});
export const getExamNotesByFilterError = (payload) => ({
  type: EXAMNOTES_GET_BY_FILTER_ERROR,
  payload
});


 // ADD ONE
 export const addExamNotes = (payload) => ({
    type: EXAMNOTES_ADD_ONE,
    payload
  });
export const addExamNotesSuccess = (payload) => ({
    type: EXAMNOTES_ADD_ONE_SUCCESS,
    payload
  });
export const addExamNotesError = (payload) => ({
    type: EXAMNOTES_ADD_ONE_ERROR,
    payload
  });

  // EDIT ONE
export const editOneExamNotes = (payload) => ({
    type: EXAMNOTES_EDIT_ONE,
    payload
  });
export const editOneExamNotesSuccess = (payload) => ({
    type: EXAMNOTES_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneExamNotesError = (payload) => ({
    type: EXAMNOTES_EDIT_ONE_ERROR,
    payload
  });


// DELETE ONE
export const deleteOneExamNotes= (payload) => ({
    type: EXAMNOTES_DELETE_ONE,
    payload
  });
export const deleteOneExamNotesSuccess = (payload) => ({
    type: EXAMNOTES_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneExamNotesError = (payload) => ({
    type: EXAMNOTES_DELETE_ONE_ERROR,
    payload
  });
// DELETE MANY
export const deleteManyExamNotes = (payload) => ({
  type: EXAMNOTES_DELETE_MANY,
  payload
});
export const deleteManyExamNotesSuccess = (payload) => ({
  type:EXAMNOTES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyExamNotesError = (payload) => ({
  type: EXAMNOTES_DELETE_MANY_ERROR,
  payload
});
