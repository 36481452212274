import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { 
    
    ADMIN_COMMENTS_GET_LIST,ADMIN_COMMENT_GET_ONE,ADMIN_COMMENT_ADD_ONE,ADMIN_COMMENT_EDIT_ONE,ADMIN_COMMENT_DELETE_ONE,ADMIN_COMMENTS_DELETE_MANY
} from "../actions";

import {
    getAdminCommentsListSuccess,
    getAdminCommentsListError,

    getOneAdminCommentSuccess,
    getOneAdminCommentError,

    addAdminCommentSuccess,
    addAdminCommentError,

    editOneAdminCommentSuccess,
    editOneAdminCommentError,

    deleteOneAdminCommentSuccess,
    deleteOneAdminCommentError,

    deleteManyAdminCommentsSuccess,
    deleteManyAdminCommentsError,

} from "./actions";

 //GET LIST
 const getAdminCommentsListRequest = async (payload) => {
    return await axios.get(api.admin_comments+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getAdminCommentsList({payload}) {
    try {
      const ret = yield call(getAdminCommentsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getAdminCommentsListSuccess({admin_comments: ret.data.admin_comments || [], count: ret.data.count}));
      
      else yield put(getAdminCommentsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getAdminCommentsListError({error, message: (error) ? error.message : ''}));
    }
  }

//GET ONE
const getOneAdminCommentRequest = async (payload) => {
    return await axios.get(api.admin_comments+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneAdminComment({payload}) {
    try {
      const ret = yield call(getOneAdminCommentRequest, payload);
  
      if (ret && ret.status==200) yield put(getOneAdminCommentSuccess({admin_comment: ret.data.admin_comment}));
   
      else yield put(getOneAdminCommentError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(getOneAdminCommentError({error, message: (error) ? error.message : ''}));
    }
  }

//ADD ONE
const addAdminCommentRequest = async (payload) => {
    return await axios.post(api.admin_comments, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addAdminComment({payload}) {  
    try {
      const ret = yield call(addAdminCommentRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/exams/admin-comments')
        yield put(addAdminCommentSuccess({admin_comment: ret.data.admin_comment}));
      }
      else yield put(addAdminCommentError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addAdminCommentError({error, message: (error) ? error.message : ''}));
    }
  }

//EDIT ONE
const editOneAdminCommentRequest = async (payload) => {
    return await axios.patch(`${api.admin_comments}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneAdminComment({payload}) {  
    try {
      const ret = yield call(editOneAdminCommentRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/exams/admin-comments')
        yield put(editOneAdminCommentSuccess({admin_comment: ret.data.admin_comment}));
      }
      else yield put(editOneAdminCommentError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneAdminCommentError({error, message: (error) ? error.message : ''}));
    }
  }

// DELETE ONE
const deleteOneAdminCommentRequest = async (payload) => {
    return await axios.delete(`${api.admin_comments}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneAdminComment({payload}) {  
    try {
      const ret = yield call(deleteOneAdminCommentRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/exams/admin-comments')
        yield put(deleteOneAdminCommentSuccess({index}));
      }
      else yield put(deleteOneAdminCommentError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneAdminCommentError({error, message: (error) ? error.message : ''}));
    }
  }

//DELETE MANY
const deleteManyAdminCommentsRequest = async (payload) => {
    return await axios.delete(`${api.admin_comments}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
  function* deleteManyAdminComments({ payload }) {
    try {
      const ret = yield call(deleteManyAdminCommentsRequest, payload);
  
      if (ret && ret.status == 202) {
        const { history, data } = payload;
  
        if (history) history.push('/app/exams/admin-comments')
        yield put( deleteManyAdminCommentsSuccess({ data }));
      }
      else yield put(deleteManyAdminCommentsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteManyAdminCommentsError({ error, message: (error) ? error.message : '' }));
    }
  }

//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(ADMIN_COMMENTS_GET_LIST, getAdminCommentsList);
  }


export function* watchGetOne() {
    yield takeEvery(ADMIN_COMMENT_GET_ONE, getOneAdminComment);
  }
  

export function* watchEditOne() {
    yield takeEvery(ADMIN_COMMENT_EDIT_ONE, editOneAdminComment);
  }
  
export function* watchAddOne() {
    yield takeEvery(ADMIN_COMMENT_ADD_ONE, addAdminComment);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(ADMIN_COMMENT_DELETE_ONE, deleteOneAdminComment);
  }
export function* watchDeleteMany() {
    yield takeEvery(ADMIN_COMMENTS_DELETE_MANY,deleteManyAdminComments);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)


    ]);
  }