import {
	FEE_GET_LIST,
	FEE_GET_LIST_SUCCESS,
  FEE_GET_LIST_ERROR,

  FEE_GET_ONE,
	FEE_GET_ONE_SUCCESS,
  FEE_GET_ONE_ERROR,

  FEE_GET_BY_FILTER,
  FEE_GET_BY_FILTER_SUCCESS,
  FEE_GET_BY_FILTER_ERROR,

  FEE_ADD_ONE,
  FEE_ADD_ONE_SUCCESS,
  FEE_ADD_ONE_ERROR,

  FEE_EDIT_ONE,
  FEE_EDIT_ONE_SUCCESS,
  FEE_EDIT_ONE_ERROR,

  FEE_DELETE_ONE,
  FEE_DELETE_ONE_SUCCESS,
  FEE_DELETE_ONE_ERROR,

  FEES_DELETE_MANY,
  FEES_DELETE_MANY_SUCCESS,
  FEES_DELETE_MANY_ERROR,

  FEE_GET_ID,
  FEE_GET_ID_ERROR,
  FEE_GET_ID_SUCCESS
} from '../../actions';

import { NotificationManager } from '../../../components/common/react-notifications';
import  notificationMessages  from '../../../lang/locales/notificationMsg';

const INIT_STATE = {
	fees: [],
  count: 0,
  fee: null,
  fee_id:'',
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case FEE_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case FEE_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        fees: action.payload.fees || [],
        count: action.payload.count || 0
      };
		case FEE_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

      // GET ONE
    case FEE_GET_ONE:
      return { ...state, loading: true, error: '', fee: null};
    case FEE_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        fee: action.payload.fee
      };
    case FEE_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    // GET BY FILTER
     case FEE_GET_BY_FILTER:
      
     return { ...state, loading: true, error: ''};
     case FEE_GET_BY_FILTER_SUCCESS:
   
     return { ...state, loading: false, error: '',
     fees: action.payload.fees || [],
      count: action.payload.count || 0
     };
     case FEE_GET_BY_FILTER_ERROR:
     return { ...state, loading: false, error: action.payload.message };

      // ADD ONE
    case FEE_ADD_ONE:
      //console.log('reducer add one');
      
      return { ...state, loading: true, error: ''};
    case FEE_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"],messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        fees: [action.payload.fee, ...state.fees]
      };
    case FEE_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // GET FEE ID
    case FEE_GET_ID:
      return { ...state, loading: true, error: ''};
    case FEE_GET_ID_SUCCESS:
      return { ...state, loading: false, error: '',
      fee_id: action.payload.fee_id
      };
    case FEE_GET_ID_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    
      // EDIT ONE
    case FEE_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case FEE_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      fee: action.payload.fee
      };
    case FEE_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case FEE_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case FEE_DELETE_ONE_SUCCESS:  
      const fees = state.fees 
      if(action.payload.index!=null && action.payload.index!=undefined)
      fees.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        fees
      };
    case FEE_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
      
      // DELETE MANY
    case FEES_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case FEES_DELETE_MANY_SUCCESS:
      const fees_list = state.fees
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_fee = -1
          for (let j = 0; j < fees_list.length; j++) {
            if (fees_list[j]._id === action.payload.data.ids[i]) {
              index_fee = j; break;
            }
          }
          if (index_fee > -1) fees_list.splice(index_fee, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        fees: fees_list
      };
    case FEES_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    
		default: return { ...state };
	}
}
