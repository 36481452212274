import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import {EXAMRESULTS_GET_LIST,EXAMRESULTS_GET_ONE,EXAMRESULTS_ADD_ONE,EXAMRESULTS_EDIT_ONE,EXAMRESULTS_DELETE_ONE,EXAMRESULTS_DELETE_MANY, EXAMMODULES_DELETE_MANY
} from "../actions";
import {
    getExamResultsListSuccess,
    getExamResultsListError,

    getOneExamResultsSuccess,
    getOneExamResultsError,

    addExamResultsSuccess,
    addExamResultsError,

    editOneExamResultsSuccess,
    editOneExamResultsError,

    deleteOneExamResultsSuccess,
    deleteOneExamResultsError,

    deleteManyExamResultsSuccess,
    deleteManyExamResultsError,
  } from "./actions";

//GET LIST
const getExamResultsListRequest = async (payload) => {
  return await axios.get(api.exam_results+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getExamResultsList({payload}) {
  try {
    const ret = yield call(getExamResultsListRequest, payload);
    
    if (ret && ret.status==200)
  
    yield put(getExamResultsListSuccess({exam_results: ret.data.exam_results || [], count: ret.data.count || 0}));

  
    else yield put(getExamResultsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getExamResultsListError({error, message: (error) ? error.message : ''}));
  }
}

//GET ONE
const getOneExamResultsRequest = async (payload) => {
  return await axios.get(api.exam_results+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneExamResults({payload}) {  
  try {
    const ret = yield call(getOneExamResultsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneExamResultsSuccess({exam_result: ret.data.exam_result}));
    
    else yield put(getOneExamResultsError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneExamResultsError({error, message: (error) ? error.message : ''}));
  }
}
//ADD ONE
const addExamResultsRequest = async (payload) => {
 
  return await axios.post(api.exam_results,payload.data, { 
    headers: {Authorization: payload.token},

  })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addExamResults({payload}) {  
  try {
    const ret = yield call(addExamResultsRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/exams/exam-results')
      yield put(addExamResultsSuccess({exam_result: ret.data.exam_result}));
    }
    else yield put(addExamResultsError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addExamResultsError({error, message: (error) ? error.message : ''}));
  }
}


  //EDIT ONE
const editOneExamResultsRequest = async (payload) => {
  
    return await axios.patch(`${api.exam_results}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

   })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneExamResults({payload}) {  
    try {
      const ret = yield call(editOneExamResultsRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exam-results/list')
        yield put(editOneExamResultsSuccess({exam_result: ret.data.exam_result}));
      }
      else yield put(editOneExamResultsError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneExamResultsError({error, message: (error) ? error.message : ''}));
    }
  }
// DELETE ONE
const deleteOneExamResultsRequest = async (payload) => {
  return await axios.delete(`${api.exam_results}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneExamResults({payload}) {  
  try {
    const ret = yield call(deleteOneExamResultsRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/exams/exam-results')
      yield put(deleteOneExamResultsSuccess({index}));
    }
    else yield put(deleteOneExamResultsError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneExamResultsError({error, message: (error) ? error.message : ''}));
  }
}

//DELETE MANY
const deleteManyExamResultsRequest = async (payload) => {
  return await axios.delete(`${api.exam_results}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyExamResults({ payload }) {
  try {
    const ret = yield call(deleteManyExamResultsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/exams/exam-results')
      yield put(deleteManyExamResultsSuccess({ data }));
    }
    else yield put(deleteManyExamResultsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyExamResultsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(EXAMRESULTS_GET_LIST, getExamResultsList);
}

export function* watchGetOne() {
  yield takeEvery(EXAMRESULTS_GET_ONE, getOneExamResults);
}


export function* watchEditOne() {
  yield takeEvery(EXAMRESULTS_EDIT_ONE,editOneExamResults);
}

export function* watchAddOne() {
  yield takeEvery(EXAMRESULTS_ADD_ONE, addExamResults);
}

export function* watchDeleteOne() {
  yield takeEvery(EXAMRESULTS_DELETE_ONE, deleteOneExamResults);
}
export function* watchDeleteMany() {
  yield takeEvery(EXAMRESULTS_DELETE_MANY,deleteManyExamResults);
} 
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}