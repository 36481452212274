import {
  SKILL_GET_LIST,
	SKILL_GET_LIST_SUCCESS,
  SKILL_GET_LIST_ERROR,

  SKILL_GET_ONE,
	SKILL_GET_ONE_SUCCESS,
  SKILL_GET_ONE_ERROR,

  SKILL_ADD_ONE,
  SKILL_ADD_ONE_SUCCESS,
  SKILL_ADD_ONE_ERROR,

  SKILL_EDIT_ONE,
  SKILL_EDIT_ONE_SUCCESS,
  SKILL_EDIT_ONE_ERROR,

  SKILL_DELETE_ONE,
  SKILL_DELETE_ONE_SUCCESS,
  SKILL_DELETE_ONE_ERROR,

  SKILLS_DELETE_MANY,
  SKILLS_DELETE_MANY_SUCCESS,
  SKILLS_DELETE_MANY_ERROR,
} from '../actions';

  // GET LIST
export const getSkillsList = (payload) => ({
  type: SKILL_GET_LIST,
  payload
});
export const getSkillsListSuccess = (payload) => ({
  type: SKILL_GET_LIST_SUCCESS,
  payload
});
export const getSkillsListError = (payload) => ({
  type: SKILL_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneSkill = (payload) => ({
  type: SKILL_GET_ONE,
  payload
});
export const getOneSkillSuccess = (payload) => ({
  type: SKILL_GET_ONE_SUCCESS,
  payload
});
export const getOneSkillError = (payload) => ({
  type: SKILL_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addSkill = (payload) => ({
  type: SKILL_ADD_ONE,
  payload
});
export const addSkillSuccess = (payload) => ({
  type: SKILL_ADD_ONE_SUCCESS,
  payload
});
export const addSkillError = (payload) => ({
  type: SKILL_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editSkill = (payload) => ({
  type: SKILL_EDIT_ONE,
  payload
});
export const editSkillSuccess = (payload) => ({
  type: SKILL_EDIT_ONE_SUCCESS,
  payload
});
export const editSkillError = (payload) => ({
  type: SKILL_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneSkill = (payload) => ({
  type: SKILL_DELETE_ONE,
  payload
});
export const deleteOneSkillSuccess = (payload) => ({
  type: SKILL_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSkillError = (payload) => ({
  type: SKILL_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManySkills = (payload) => ({
  type:   SKILLS_DELETE_MANY,
  payload
});
export const deleteManySkillsSuccess = (payload) => ({
  type: SKILLS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySkillsError = (payload) => ({
  type: SKILLS_DELETE_MANY_ERROR,
  payload
});