import {
  PARENT_GET_LIST,
	PARENT_GET_LIST_SUCCESS,
  PARENT_GET_LIST_ERROR,

  PARENT_GET_LIST_BY_FILTER,
	PARENT_GET_LIST_BY_FILTER_SUCCESS,
  PARENT_GET_LIST_BY_FILTER_ERROR,

  PARENT_GET_ONE,
	PARENT_GET_ONE_SUCCESS,
  PARENT_GET_ONE_ERROR,

  PARENT_ADD_ONE,
  PARENT_ADD_ONE_SUCCESS,
  PARENT_ADD_ONE_ERROR,

  PARENT_EDIT_ONE,
  PARENT_EDIT_ONE_SUCCESS,
  PARENT_EDIT_ONE_ERROR,

  PARENT_DELETE_ONE,
  PARENT_DELETE_ONE_SUCCESS,
  PARENT_DELETE_ONE_ERROR,

  
  PARENTS_DELETE_MANY,
  PARENTS_DELETE_MANY_SUCCESS,
  PARENTS_DELETE_MANY_ERROR,

  
} from '../actions';

  // GET LIST
export const getParentsList = (payload) => ({
  type: PARENT_GET_LIST,
  payload
});
export const getParentsListSuccess = (payload) => ({
  type: PARENT_GET_LIST_SUCCESS,
  payload
});
export const getParentsListError = (payload) => ({
  type: PARENT_GET_LIST_ERROR,
  payload
});
//GET PARENTS BY FILTER
export const getParentsByFilter = (payload) => ({
  type: PARENT_GET_LIST_BY_FILTER,
  payload
});
export const getParentsByFilterSuccess = (payload) => ({
  type: PARENT_GET_LIST_BY_FILTER_SUCCESS,
  payload
});
export const getParentsByFilterError = (payload) => ({
  type: PARENT_GET_LIST_BY_FILTER_ERROR,
  payload
});






  // GET LIST
export const getOneParent = (payload) => ({
  type: PARENT_GET_ONE,
  payload
});
export const getOneParentSuccess = (payload) => ({
  type: PARENT_GET_ONE_SUCCESS,
  payload
});
export const getOneParentError = (payload) => ({
  type: PARENT_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addParent = (payload) => ({
  type: PARENT_ADD_ONE,
  payload
});
export const addParentSuccess = (payload) => ({
  type: PARENT_ADD_ONE_SUCCESS,
  payload
});
export const addParentError = (payload) => ({
  type: PARENT_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editParent = (payload) => ({
  type: PARENT_EDIT_ONE,
  payload
});
export const editParentSuccess = (payload) => ({
  type: PARENT_EDIT_ONE_SUCCESS,
  payload
});
export const editParentError = (payload) => ({
  type: PARENT_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneParent = (payload) => ({
  type: PARENT_DELETE_ONE,
  payload
});
export const deleteOneParentSuccess = (payload) => ({
  type: PARENT_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneParentError = (payload) => ({
  type: PARENT_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyParents = (payload) => ({
  type: PARENTS_DELETE_MANY,
  payload
});
export const deleteManyParentsSuccess = (payload) => ({
  type: PARENTS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyParentsError = (payload) => ({
  type: PARENTS_DELETE_MANY_ERROR,
  payload
});