import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../../constants/defaultValues";
import axios from "axios";

import { FEE_TYPE_GET_LIST, FEE_TYPE_ADD_ONE, FEE_TYPE_GET_ONE, FEE_TYPE_EDIT_ONE, FEE_TYPE_DELETE_ONE
} from "../../actions";

import {
  getFeeTypesListSuccess,
  getFeeTypesListError,

  getOneFeeTypeSuccess,
  getOneFeeTypeError,

  addFeeTypeSuccess,
  addFeeTypeError,

  editFeeTypeSuccess,
  editFeeTypeError,
  
  deleteOneFeeTypeSuccess,
  deleteOneFeeTypeError,
} from "./actions";


  //GET LIST
const getFeeTypesListRequest = async (payload) => {
  return await axios.get(api.fee_types+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};

function* getFeeTypesList({payload}) {
  try {
    const ret = yield call(getFeeTypesListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getFeeTypesListSuccess({fee_types: ret.data.fee_types || [], count: ret.data.count || 0}));
    
    else yield put(getFeeTypesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getFeeTypesListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneFeeTypeRequest = async (payload) => {
  return await axios.get(api.fee_types+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneFeeType({payload}) {
  try {
    const ret = yield call(getOneFeeTypeRequest, payload);

    if (ret && ret.status==200) yield put(getOneFeeTypeSuccess({fee_type: ret.data.fee_type}));
    else yield put(getOneFeeTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(getOneFeeTypeError({error, message: (error) ? error.message : ''}));
  }
}


  //ADD ONE
const addFeeTypeRequest = async (payload) => {
 
  return await axios.post(api.fee_types, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addFeeType({payload}) { 
  //console.log('saga add fee-composition',payload);
   
  try {
    const ret = yield call(addFeeTypeRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/billing/fee-types')
      yield put(addFeeTypeSuccess({fee_type: ret.data.fee_type}));
    }
    else yield put(addFeeTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(addFeeTypeError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneFeeTypeRequest = async (payload) => {
  return await axios.patch(`${api.fee_types}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneFeeType({payload}) {  
  try {
    const ret = yield call(editOneFeeTypeRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/billing/fee-types')
      yield put(editFeeTypeSuccess({fee_type: ret.data.fee_type}));
    }
    else yield put(editFeeTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(editFeeTypeError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneFeeTypeRequest = async (payload) => {
  return await axios.delete(`${api.fee_types}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneFeeType({payload}) {  
  try {
    const ret = yield call(deleteOneFeeTypeRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/billing/fee-types')
      yield put(deleteOneFeeTypeSuccess({index}));
    }
    else yield put(deleteOneFeeTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    //console.log('saga err', error);
    yield put(deleteOneFeeTypeError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(FEE_TYPE_GET_LIST, getFeeTypesList);
}

export function* watchGetOne() {
  yield takeEvery(FEE_TYPE_GET_ONE, getOneFeeType);
}

export function* watchEditOne() {
  yield takeEvery(FEE_TYPE_EDIT_ONE, editOneFeeType);
}

export function* watchAddOne() {
  yield takeEvery(FEE_TYPE_ADD_ONE, addFeeType);
}

export function* watchDeleteOne() {
  yield takeEvery(FEE_TYPE_DELETE_ONE, deleteOneFeeType);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
