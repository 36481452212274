import {
    EXAMSEARCHMODULES_GET_BY_FILTER,
    EXAMSEARCHMODULES_GET_BY_FILTER_SUCCESS,
    EXAMSEARCHMODULES_GET_BY_FILTER_ERROR,

    EXAMSEARCHMODULE_DELETE_ONE,
    EXAMSEARCHMODULE_DELETE_ONE_SUCCESS,
    EXAMSEARCHMODULE_DELETE_ONE_ERROR,


    EXAMSEARCHMODULES_ADD_ONE,
    EXAMSEARCHMODULES_ADD_ONE_SUCCESS,
    EXAMSEARCHMODULES_ADD_ONE_ERROR,



    EXAMSEARCHMODULE_GET_ONE,
    EXAMSEARCHMODULE_GET_ONE_SUCCESS,
    EXAMSEARCHMODULE_GET_ONE_ERROR,

    EXAMSEARCHMODULE_BY_USER,
    EXAMSEARCHMODULE_BY_USER_SUCCESS,
    EXAMSEARCHMODULE_BY_USER_ERROR,

    EXAMSEARCHMODULE_PRINT_BY_USER,
    EXAMSEARCHMODULE_PRINT_BY_USER_SUCCESS,
    EXAMSEARCHMODULE_PRINT_BY_USER_ERROR,

    EXAMSEARCHMODULE_EDIT_ONE,
    EXAMSEARCHMODULE_EDIT_ONE_SUCCESS,
    EXAMSEARCHMODULE_EDIT_ONE_ERROR,
  } from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  research_modules: [],
  count: 0,
  research_module: null,
  error: '',
  print_success:null
  

}
export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
      switch (action.type) {



      // GET BY FILTER
      case EXAMSEARCHMODULES_GET_BY_FILTER:
              
        return { ...state, loading: true, error: ''};
        case EXAMSEARCHMODULES_GET_BY_FILTER_SUCCESS:
          
        return { ...state, loading: false, error: '',
        research_modules: action.payload.research_modules || [],count: action.payload.count || 0
        };
        case EXAMSEARCHMODULES_GET_BY_FILTER_ERROR:
          
        return { ...state, loading: false, error: action.payload.message };


       // GET ONE
        case EXAMSEARCHMODULE_GET_ONE:
                return { ...state, loading: true, error: ''};
        case EXAMSEARCHMODULE_GET_ONE_SUCCESS:
                return { ...state, loading: false, error: '',
                research_module: action.payload.research_module
                };
        case EXAMSEARCHMODULE_GET_ONE_ERROR:
                return { ...state, loading: false, error: action.payload.message };


       // GETBY USER
        case EXAMSEARCHMODULE_BY_USER:
          return { ...state, loading: true, error: ''};
       case EXAMSEARCHMODULE_BY_USER_SUCCESS:
          return { ...state, loading: false, error: '',
          research_module:  action.payload.research_modules || [],count: action.payload.count || 0
          };
      case EXAMSEARCHMODULE_BY_USER_ERROR:
          return { ...state, loading: false, error: action.payload.message };

  // PRINT ONE
  case EXAMSEARCHMODULE_PRINT_BY_USER:
    return { ...state, loading: true,print_success:false, error: '' };
  case EXAMSEARCHMODULE_PRINT_BY_USER_SUCCESS:
    NotificationManager.success(messages["Print"],  messages["Success"], 3000, null, null, '');
    return {
      ...state, loading: false, error: '',print_success:true,
      path: action.payload.path
    };
  case EXAMSEARCHMODULE_PRINT_BY_USER_ERROR:
    NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
    return { ...state, loading: false, error: action.payload.message,print_success:false, };




        // ADD ONE
        case EXAMSEARCHMODULES_ADD_ONE:
          return { ...state, loading: true, error: ''};
          case EXAMSEARCHMODULES_ADD_ONE_SUCCESS:    
          NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
          return { ...state, loading: false, 
            research_modules: [action.payload.research_module, ...state.research_modules]
          };
          case EXAMSEARCHMODULES_ADD_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          return { ...state, loading: false, error: action.payload.message };



       // EDIT ONE
          case EXAMSEARCHMODULE_EDIT_ONE:
            return { ...state, loading: true,edit_sucess:false, error: ''};
          case EXAMSEARCHMODULE_EDIT_ONE_SUCCESS:    
            NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
            return { ...state, loading: false,edit_sucess:true, error: '',
            research_module: action.payload.research_module
            };
          case EXAMSEARCHMODULE_EDIT_ONE_ERROR:
              NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
            return { ...state, loading: false,edit_sucess:false, error: action.payload.message };

      // DELETE ONE
      case EXAMSEARCHMODULE_DELETE_ONE:
        return { ...state, loading: true, error: ''};
      case EXAMSEARCHMODULE_DELETE_ONE_SUCCESS:  
        const research_modules = state.research_modules
        if(action.payload.index!=null && action.payload.index!=undefined)
        research_modules.splice(action.payload.index, 1)
  
        return { ...state, loading: false, error: '',
        research_modules
        };
      case EXAMSEARCHMODULE_DELETE_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };





        default: return { ...state };
    }}