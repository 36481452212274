import {
	SUBJECT_GET_LIST,
	SUBJECT_GET_LIST_SUCCESS,
  SUBJECT_GET_LIST_ERROR,

  SUBJECT_GET_ONE,
	SUBJECT_GET_ONE_SUCCESS,
  SUBJECT_GET_ONE_ERROR,

  SUBJECT_GET_BY_FILTER,
  SUBJECT_GET_BY_FILTER_SUCCESS,
  SUBJECT_GET_BY_FILTER_ERROR,

  SUBJECT_ADD_ONE,
  SUBJECT_ADD_ONE_SUCCESS,
  SUBJECT_ADD_ONE_ERROR,

  SUBJECT_EDIT_ONE,
  SUBJECT_EDIT_ONE_SUCCESS,
  SUBJECT_EDIT_ONE_ERROR,

  SUBJECT_DELETE_ONE,
  SUBJECT_DELETE_ONE_SUCCESS,
  SUBJECT_DELETE_ONE_ERROR,

  SUBJECTS_DELETE_MANY,
  SUBJECTS_DELETE_MANY_SUCCESS,
  SUBJECTS_DELETE_MANY_ERROR,
} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	subjects: [],
  count: 0,
  subject: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
    case SUBJECT_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case SUBJECT_GET_LIST_SUCCESS:
      //console.log("SUBJECT_GET_LIST_SUCCESS  : ",action.payload.subjects);
      return { ...state, loading: false, 
        subjects: action.payload.subjects || [],
        count: action.payload.count || 0
      };
    case SUBJECT_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case SUBJECT_GET_ONE:
      return { ...state, loading: true, error: ''};
    case SUBJECT_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        subject: action.payload.subject
      };
    case SUBJECT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };


    //GET BY FILTER
    case SUBJECT_GET_BY_FILTER:
        return { ...state, loading: true, error: ''};
    case SUBJECT_GET_BY_FILTER_SUCCESS:
     // console.log("SUBJECT_GET_BY_FILTER_SUCCESS  : ",action.payload.subjects);
        return { ...state, loading: false, error: '',
        subjects: action.payload.subjects, count: action.payload.count };
    case SUBJECT_GET_BY_FILTER_ERROR:
        return { ...state, loading: false, error: action.payload.message };




      // ADD ONE
    case SUBJECT_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case SUBJECT_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        subjects: [action.payload.subject, ...state.subjects]
      };
    case SUBJECT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case SUBJECT_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case SUBJECT_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      subject: action.payload.subject
      };
    case SUBJECT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case SUBJECT_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case SUBJECT_DELETE_ONE_SUCCESS:  
      const subjects = state.subjects 
      if(action.payload.index!=null && action.payload.index!=undefined)
      subjects.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        subjects
      };
    case SUBJECT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

       // DELETE MANY
    case SUBJECTS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case SUBJECTS_DELETE_MANY_SUCCESS:
      const subjects_list = state.subjects
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_subject = -1
          for (let j = 0; j < subjects_list.length; j++) {
            if (subjects_list[j]._id === action.payload.data.ids[i]) {
              index_subject = j; break;
            }
          }
          if (index_subject > -1) subjects_list.splice(index_subject, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        subjects: subjects_list
      };
    case SUBJECTS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
		default: return { ...state };
	}
}
