const notificationMessages = () => {

	const lang = localStorage.getItem('currentLanguage')

	switch (lang) {
		case "fr":
			return {
				Error: "Erreur",
				Success: "Succès",
				"please-check-your-data": "Veuillez vérifier vos données",
				"please-check-your-fee-month": "Veuillez vérifier le mois du frais",
				"invalid-state":"Etat non valide",
				"lack-of-information":"Manque d'information",
				"please-check-your-data-and-try-again":"veuillez vérifier vos données et réessayer",
				"name-and-type-are-required":"Nom et type sont obligatoires",
				"login-error":"erreur d'identification",
				"please-check-your-maximal-number":"vérifier le nombre maximal",
				Added:"Ajouté",
				Edited:"Modifié",
				Deleted:"Supprimé",
				"Unkown Error":"Erreur inconnu",
				Print:"Imprimé",
				sent:"Envoyé",
				Affected:"Affecté(e)",
			}
		
		
		
		
	
		
		case "en":
		
		return {
			Error: "Error",
			Success: "Success",
			"please-check-your-data": "Please check your data",
			"please-check-your-fee-month": "Please check your fee month",
			"invalid-state":"Invalid state",
			"lack-of-information":"Lack of information",
			"please-check-your-data-and-try-again":"Please check your data and try again",
			"name-and-type-are-required":"Name and type are required",
			"login-error":"Login error",
			"please-check-your-maximal-number":"check the maximum number",
			Added:"Added",
			Edited:"Edited",
			Deleted:"Deleted",
			"Unkown Error":"Unkown Error",
			Print:"Print",
			sent:"Sent",
			Affected:"Affected",
		}
		default:
			break;
	}
	//return data
}

export default notificationMessages