import {
    SANCTION_TYPES_GET_LIST,
    SANCTION_TYPES_GET_LIST_SUCCESS,
    SANCTION_TYPES_GET_LIST_ERROR,
    
    SANCTION_TYPES_GET_ONE,
    SANCTION_TYPES_GET_ONE_SUCCESS,
    SANCTION_TYPES_GET_ONE_ERROR,

    SANCTION_TYPES_ADD_ONE,
    SANCTION_TYPES_ADD_ONE_SUCCESS,
    SANCTION_TYPES_ADD_ONE_ERROR,

    SANCTION_TYPES_EDIT_ONE,
    SANCTION_TYPES_EDIT_ONE_SUCCESS,
    SANCTION_TYPES_EDIT_ONE_ERROR,

    SANCTION_TYPES_DELETE_ONE,
    SANCTION_TYPES_DELETE_ONE_SUCCESS,
    SANCTION_TYPES_DELETE_ONE_ERROR,

    SANCTION_TYPES_DELETE_MANY,
    SANCTION_TYPES_DELETE_MANY_SUCCESS,
    SANCTION_TYPES_DELETE_MANY_ERROR,
} from '../actions';

//GET LIST

export const getSanctionTypesList = (payload) => ({
    type: SANCTION_TYPES_GET_LIST,
    payload
  });
export const getSanctionTypesListSuccess = (payload) => ({
    type: SANCTION_TYPES_GET_LIST_SUCCESS,
    payload
  });
export const getSanctionTypesListError = (payload) => ({
    type: SANCTION_TYPES_GET_LIST_ERROR,
    payload
  });



  // GET ONE
  export const getOneSanctionTypes = (payload) => ({
    type: SANCTION_TYPES_GET_ONE,
    payload
  });
  export const getOneSanctionTypesSuccess = (payload) => ({
    type: SANCTION_TYPES_GET_ONE_SUCCESS,
    payload
  });
  export const getOneSanctionTypesError = (payload) => ({
    type: SANCTION_TYPES_GET_ONE_ERROR,
    payload
  });
  

   // ADD ONE
export const addSanctionTypes = (payload) => ({
    type: SANCTION_TYPES_ADD_ONE,
    payload
  });
export const addSanctionTypesSuccess = (payload) => ({
    type: SANCTION_TYPES_ADD_ONE_SUCCESS,
    payload
  });
export const addSanctionTypesError = (payload) => ({
    type: SANCTION_TYPES_ADD_ONE_ERROR,
    payload
  });
  

// EDIT ONE
export const editSanctionTypes= (payload) => ({
    type: SANCTION_TYPES_EDIT_ONE,
    payload
  });
export const editSanctionTypesSuccess = (payload) => ({
    type: SANCTION_TYPES_EDIT_ONE_SUCCESS,
    payload
  });
export const editSanctionTypesError = (payload) => ({
    type: SANCTION_TYPES_EDIT_ONE_ERROR,
    payload
  });
  


  // DELETE ONE
export const deleteOneSanctionTypes = (payload) => ({
    type: SANCTION_TYPES_DELETE_ONE,
    payload
  });
export const deleteOneSanctionTypesSuccess = (payload) => ({
    type: SANCTION_TYPES_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneSanctionTypesError = (payload) => ({
    type: SANCTION_TYPES_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManySanctionTypes = (payload) => ({
  type: SANCTION_TYPES_DELETE_MANY,
  payload
});
export const deleteManySanctionTypesSuccess = (payload) => ({
  type: SANCTION_TYPES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySanctionTypesError = (payload) => ({
  type: SANCTION_TYPES_DELETE_MANY_ERROR,
  payload
});