import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { EXAMMARKSNOTES_GET_BY_FILTERS,EXAMMARKSNOTES_ADD_ONE,EXAMMARKSNOTES_EDIT_ONE,EXAMMARKSNOTES_DELETE_ONE,EXAMMARKSNOTES_GET_ONE,EXAMMARKSNOTES_GET_BY_FILTER
} from "../actions";
import {
  getExamMarksNotesByFiltersSuccess,
  getExamMarksNotesByFiltersError,

    getOneExamMarksNotesSuccess,
    getOneExamMarksNotesError,

    addExamMarksNotesSuccess,
    addExamMarksNotesError,

    editOneExamMarksNotesSuccess,
    editOneExamMarksNotesError,

    deleteOneExamMarksNotesSuccess,
    deleteOneExamMarksNotesError,
 
    getExamMarksNotesByFilterSuccess,
    getExamMarksNotesByFilterError,
  } from "./actions";
//GET EXAM MARK NOTES BY FILTERS

const getExamMarksNotesByFiltersRequest = async (payload) => {
    return await axios.post(api.mark_note+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getExamMarksNotesByFilters({payload}) {
    try {
      const ret = yield call(getExamMarksNotesByFiltersRequest, payload);
      
  
      if (ret && ret.status==200)
      yield put(getExamMarksNotesByFiltersSuccess({exam_mark_notes: ret.data.exam_mark_notes,count:ret.data.count}));
      
      else yield put(getExamMarksNotesByFiltersError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getExamMarksNotesByFiltersError({error, message: (error) ? error.message : ''}));
    }
}
//GET EXAM MARK NOTES BY FILTER

const getExamMarksNotesByFilterRequest = async (payload) => {
  return await axios.post(api.mark_note+`/filter/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getExamMarksNotesByFilter({payload}) {
  try {
    const ret = yield call(getExamMarksNotesByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getExamMarksNotesByFilterSuccess({exam_mark_notes: ret.data.exam_mark_notes, count:ret.data.count}));
    
    else yield put(getExamMarksNotesByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getExamMarksNotesByFilterError({error, message: (error) ? error.message : ''}));
  }
}
//GET ONE
const getOneExamMarksNotesRequest = async (payload) => {
  return await axios.get(api.mark_note+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneExamMarksNotes({payload}) {  
  try {
    const ret = yield call(getOneExamMarksNotesRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneExamMarksNotesSuccess({exam_mark_note: ret.data.exam_mark_note}));
    
    else yield put(getOneExamMarksNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneExamMarksNotesError({error, message: (error) ? error.message : ''}));
  }
}
//ADD EXAM MARK NOTES

const addExamMarksNotesRequest = async (payload) => {
 
  return await axios.post(api.mark_note,payload.data, { 
    headers: {Authorization: payload.token},

  })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addExamMarksNotes({payload}) {  
  try {
    const ret = yield call(addExamMarksNotesRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/exams/exam-marks-note')
      yield put(addExamMarksNotesSuccess({exam_mark_note: ret.data.exam_mark_note}));
    }
    else yield put(addExamMarksNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addExamMarksNotesError({error, message: (error) ? error.message : ''}));
  }
}

 //EDIT ONE
 const editOneExamMarksNotesRequest = async (payload) => {
 
  return await axios.patch(`${api.mark_note}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

 })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneExamMarksNotes({payload}) {  
  try {
    const ret = yield call(editOneExamMarksNotesRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/exams/exam-marks-note')
      yield put(editOneExamMarksNotesSuccess({exam_mark_note: ret.data.exam_mark_note}));
    }
    else yield put(editOneExamMarksNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneExamMarksNotesError({error, message: (error) ? error.message : ''}));
  }
}


// DELETE ONE
const deleteOneExamMarksNotesRequest = async (payload) => {
  return await axios.delete(`${api.mark_note}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneExamMarksNotes({payload}) {  
  try {
    const ret = yield call(deleteOneExamMarksNotesRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/exams/exam-marks-note')
      yield put(deleteOneExamMarksNotesSuccess({index}));
    }
    else yield put(deleteOneExamMarksNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneExamMarksNotesError({error, message: (error) ? error.message : ''}));
  }
}


//WATCHERS
export function* watchGetFilters() {
    yield takeEvery(EXAMMARKSNOTES_GET_BY_FILTERS, getExamMarksNotesByFilters);
  }
export function* watchGetFilter() {
    yield takeEvery(EXAMMARKSNOTES_GET_BY_FILTER, getExamMarksNotesByFilter);
  }
export function* watchGetOne() {
    yield takeEvery(EXAMMARKSNOTES_GET_ONE, getOneExamMarksNotes);
  }
export function* watchAddOne() {
    yield takeEvery(EXAMMARKSNOTES_ADD_ONE, addExamMarksNotes);
  }

export function* watchEditOne() {
    yield takeEvery(EXAMMARKSNOTES_EDIT_ONE,editOneExamMarksNotes);
  }
export function* watchDeleteOne() {
    yield takeEvery(EXAMMARKSNOTES_DELETE_ONE, deleteOneExamMarksNotes);
  }
export default function* rootSaga() {
    yield all([
    
      fork(watchGetFilters),
      fork(watchGetFilter),
      fork(watchGetOne),
      fork(watchAddOne),
      fork(watchEditOne),
      fork(watchDeleteOne),




    ]);
  }