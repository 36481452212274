import {
    RESERVATION_TYPES_GET_LIST,
    RESERVATION_TYPES_GET_LIST_SUCCESS,
    RESERVATION_TYPES_GET_LIST_ERROR,

    RESERVATION_TYPE_GET_ONE,
    RESERVATION_TYPE_GET_ONE_SUCCESS,
    RESERVATION_TYPE_GET_ONE_ERROR,

    RESERVATION_TYPE_ADD_ONE,
    RESERVATION_TYPE_ADD_ONE_SUCCESS,
    RESERVATION_TYPE_ADD_ONE_ERROR,

    RESERVATION_TYPE_EDIT_ONE,
    RESERVATION_TYPE_EDIT_ONE_SUCCESS,
    RESERVATION_TYPE_EDIT_ONE_ERROR,

    RESERVATION_TYPE_DELETE_ONE,
    RESERVATION_TYPE_DELETE_ONE_SUCCESS,
    RESERVATION_TYPE_DELETE_ONE_ERROR,

    RESERVATION_TYPES_DELETE_MANY,
    RESERVATION_TYPES_DELETE_MANY_SUCCESS,
    RESERVATION_TYPES_DELETE_MANY_ERROR,
} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';

const INIT_STATE = {
  reservation_types: [],
  count: 0,
  reservation_types: null,
  error: '',
};
export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
	switch (action.type) {

        //GET LIST
        case RESERVATION_TYPES_GET_LIST:
        return { ...state, loading: true, error: '', get_sanction_type_success:false,
        count: 0
        };
        case RESERVATION_TYPES_GET_LIST_SUCCESS:
        return { ...state, loading: false, get_sanction_type_success:true,
            reservation_types: action.payload.reservation_types || [],
            count: action.payload.count || 0
        };
        case RESERVATION_TYPES_GET_LIST_ERROR:
        return { ...state, loading: false,get_sanction_type_success:false,
             error: action.payload.message };



        // GET ONE
        case RESERVATION_TYPE_GET_ONE:
        return { ...state, loading: true, error: ''};
        case RESERVATION_TYPE_GET_ONE_SUCCESS:
        
        return { ...state, loading: false, error: '',
        reservation_type: action.payload.reservation_type
        
        };
        case RESERVATION_TYPE_GET_ONE_ERROR:
        return { ...state, loading: false, error: action.payload.message };

        // ADD ONE
        case RESERVATION_TYPE_ADD_ONE:
        return { ...state, loading: true, error: ''};
        case RESERVATION_TYPE_ADD_ONE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
            reservation_types: [action.payload.reservation_type, ...state.reservation_types]
        };
        case RESERVATION_TYPE_ADD_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };


         // EDIT ONE
         case RESERVATION_TYPE_EDIT_ONE:
         return { ...state, loading: true,edit_sucess:false, error: ''};

         case RESERVATION_TYPE_EDIT_ONE_SUCCESS:    
         NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
         return { ...state, loading: false, edit_sucess:true,error: '',
         reservation_type: action.payload.reservation_type
         };

         case RESERVATION_TYPE_EDIT_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
         return { ...state, loading: false,edit_sucess:false, error: action.payload.message };
         
         
         // DELETE ONE
        case RESERVATION_TYPE_DELETE_ONE:
        return { ...state, loading: true, error: ''};
        
        case RESERVATION_TYPE_DELETE_ONE_SUCCESS:  
         const reservation_types = state.reservation_types 
        if(action.payload.index!=null && action.payload.index!=undefined)
        reservation_types.splice(action.payload.index, 1)
 
        return { ...state, loading: false, error: '',
        reservation_types
         };
        case RESERVATION_TYPE_DELETE_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
        


        // DELETE MANY
      case RESERVATION_TYPES_DELETE_MANY:
        return { ...state, loading: true, error: '' };
      case RESERVATION_TYPES_DELETE_MANY_SUCCESS:
        const reservation_types_list = state.reservation_types
       
  
        if (action.payload.data.ids.length > 0) {
     
          for (let i = 0; i < action.payload.data.ids.length; i++) {
  
            let index_reservation_type = -1
            for (let j = 0; j < reservation_types_list.length; j++) {
              if (reservation_types_list[j]._id === action.payload.data.ids[i]) {
                index_reservation_type = j; break;
              }
            }
            if (index_reservation_type > -1) reservation_types_list.splice(index_reservation_type, 1)
          }
        }
        return {
          ...state, loading: false, error: '',
          reservation_types: reservation_types_list
        };
      case RESERVATION_TYPES_DELETE_MANY_ERROR:
        NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
   
     default: return { ...state };
       
       
       
       
       
       
       
        }}