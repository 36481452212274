
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
// import { auth } from '../../helpers/Firebase';
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { is_white_mark } from "../../constants/defaultValues";


import {
  USER_GET_LIST,
  USER_CHANGE_STATE,
  LOGIN_USER,
  CHAT_LOGIN_USER,
  GET_CHATROOM_ID,
  REGISTER_USER,
  LOGOUT_USER,
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  EDIT_USER, USER_DELETE_ONE, USER_GET_BY_FILTER
} from '../actions';

import {
  loginUserSuccess,
  loginUserError,

  chatLoginUserSuccess,
  chatLoginUserError,

  getRoomIdSuccess,
  getRoomIdError,

  registerUserSuccess,
  registerUserError,
  forgotPasswordSuccess,
  forgotPasswordError,
  resetPasswordSuccess,
  resetPasswordError,

  editUserSuccess,
  editUserError,

  getUsersListSuccess,
  getUsersListError,
  changeUserStateSuccess,
  changeUserStateError,

  deleteOneUserSuccess,
  deleteOneUserError,
  getUserByFilterSuccess,
  getUserByFilterError,
} from './actions';
import { NotificationManager } from '../../components/common/react-notifications';
// CHANGE USER STATE

const changeUserStateRequest = async (payload) => {
  return await axios.patch(api.users + `/activate/${payload._id}`, payload.data, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* changeUserState({ payload }) {
  try {
    const ret = yield call(changeUserStateRequest, payload);
    console.log('ret', ret);

    if (ret && ret.status === 200)
      yield put(changeUserStateSuccess({ user: ret.data.user || [], ref_code: ret.data.ref_code }));
    const { history, path } = payload;

    if (history) history.replace(path)
    else yield put(changeUserStateError({ message: "Error" }))

  } catch (error) {
    yield put(changeUserStateError({ error, message: (error) ? error.message : '' }));
  }
}

export function* watchchangeUserState() {
  yield takeEvery(USER_CHANGE_STATE, changeUserState);
}


// EDIT USER  

const editUserRequest = async (payload) => {

  return await axios.patch(api.users + `/edit-one/${payload._id}`, payload.data, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* editUser({ payload }) {
  try {
    const ret = yield call(editUserRequest, payload);

    if (ret && ret.status == 200) {
      const { history, path } = payload;
      const old_user = JSON.parse(localStorage.getItem('odesco_user'));

      if (ret.data.user._id === old_user._id) {
        const user = { ...ret.data.user, token: old_user.token }
        localStorage.setItem('odesco_user', JSON.stringify(user));
        yield put(editUserSuccess({ user }));

      }
      if (history) history.push(path);

    }

    else yield put(editUserError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(editUserError({ error, message: (error) ? error.message : '' }));
  }
}


export function* watchEditUser() {
  yield takeEvery(EDIT_USER, editUser);
}

//GET LIST
const getUsersListRequest = async (payload) => {
  return await axios.get(api.users + `/list/${payload.offset}/${payload.limit}${payload.role ? '?role=' + payload.role : '?'}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};

function* getUsersList({ payload }) {
  try {
    const ret = yield call(getUsersListRequest, payload);

    if (ret && ret.status == 200)
      yield put(getUsersListSuccess({ users: ret.data.users || [], count: ret.data.count || 0 }));

    else yield put(getUsersListError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getUsersListError({ error, message: (error) ? error.message : '' }));
  }
}

export function* watchGetUsersList() {
  yield takeEvery(USER_GET_LIST, getUsersList);
}





export function* watchLoginUser() {
  yield takeEvery(LOGIN_USER, loginWithEmailPassword);
}

// const loginWithEmailPasswordAsync = async (email, pass) => {
//   await axios.post(api.users + '/login', { email, pass })
//     .then(authUser => authUser)
//     .catch(error => error);
// }

const loginWithEmailPasswordAsync = async (email, pass) => {
  return await axios.post(api.users + '/login', { email, pass })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* loginWithEmailPassword({ payload }) {
  const { email, password } = payload.user;
  const { history } = payload;

  try {
    const connect = yield call(loginWithEmailPasswordAsync, email, password);

    if (connect && connect.status == 200) {
      const user = { ...connect.data.user, token: "Bearer " + connect.data.token }
      // console.log('USER', user);

      localStorage.setItem('odesco_user', JSON.stringify(user));
      yield put(loginUserSuccess(user));
      history.push('/');
    } else {
      yield put(loginUserError((connect.data) ? connect.data.msg : "Network Error"));
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}
/******************************************************************************************/
export function* watchChatLoginUser() {
  yield takeLatest(CHAT_LOGIN_USER, chatLoginWithEmailPassword);
}


const chatLoginWithEmailPasswordAsync = async (user, pass) => {
  return await axios.post(api.users + '/rocketLogin', { user, pass })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}

function* chatLoginWithEmailPassword({ payload }) {
  const { user, password } = payload.user;
  const { history } = payload;

  try {
    const connect = yield call(chatLoginWithEmailPasswordAsync, user, password);

    if (connect && connect.status == 200) {
      const rocket_user = { ...connect.data.rocket_user }


      localStorage.setItem('rocket_user', JSON.stringify(rocket_user));
      yield put(chatLoginUserSuccess(rocket_user));
      return (rocket_user);
    } else {
      NotificationManager.warning(connect.data, "Problème de connexion", 3000, null, null, '');
      yield put(chatLoginUserError((connect.data) ? connect.data.msg : "Network Error"));
      // history.push('/');
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}


// DELETE ONE
const deleteOneUserRequest = async (payload) => {
  return await axios.delete(`${api.users}/${payload._id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneUser({ payload }) {
  try {
    const ret = yield call(deleteOneUserRequest, payload);

    if (ret && ret.status == 200) {
      const { history, index } = payload;

      if (history) history.push('/app/schooling/subscription-requests/list')
      yield put(deleteOneUserSuccess({ index }));
    }
    else yield put(deleteOneUserError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneUserError({ error, message: (error) ? error.message : '' }));
  }
}




export function* watchGetChatroomId() {
  yield takeEvery(GET_CHATROOM_ID, getChatroomId);
}


const getChatroomIdAsync = async (data) => {

  return await axios.post(api.users + '/rocketRoomId', data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* getChatroomId({ payload }) {

  const { partner, username } = payload.data;
  const { history } = payload.history;
  // let config = {
  //   headers: {
  //     "rocketAuthToken": rocketAuthToken,
  //     "rocketId": rocketId
  //   }
  // }

  try {
    const connect = yield call(getChatroomIdAsync, { username, partner, text: "" });

    if (connect && connect.status == 200) {
      const rocket_room_id = connect.data.roomId;

      yield put(getRoomIdSuccess(rocket_room_id));

    } else {
      yield put(getRoomIdError((connect.data) ? connect.data.msg : "Network Error"));
      // history.push('/');
    }
  } catch (error) {
    // yield put(loginUserError(error));
  }
}
/******************************************************************************************/





export function* watchRegisterUser() {
  yield takeEvery(REGISTER_USER, webRegister);
}
const webRegisterAsync = async (data) => {
  return await axios.post(api.users + '/signup', data)
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}
function* webRegister({ payload }) {
  const { history, user } = payload;
  try {
    const register = yield call(webRegisterAsync, user);
    // console.log('======+++++++++++++++> register', register)

    if (register && register.status == 201) {
      const user = { ...register.data.user, token: "Bearer " + register.data.token }


      if (!is_white_mark) {
        localStorage.setItem('odesco_user', JSON.stringify(user));
        yield put(loginUserSuccess(user));
      }

      history.push('/');
      yield put(registerUserSuccess(user));
      // history.push('/');
    } else {
      yield put(registerUserError((register.data) ? register.data.msg : "Network Error"));
    }
  } catch (error) {
    console.log('ERR REGISTER', error)
    // yield put(loginUserError(error));
  }
}


export function* watchLogoutUser() {
  yield takeEvery(LOGOUT_USER, logout);
}

const logoutAsync = async (history) => {
  // await auth.signOut().then(authUser => authUser).catch(error => error);
  history.push('/')
}

const logoutRocketAsync = async (config) => {
  return await axios.post(api.users + '/logout', {}, config)
    .then(resp => resp)
    .catch(error => {
      console.log('error', error);
    })
}
function* logout({ payload }) {
  const { history, token, rocketId, rocketToken } = payload;
  let config = {
    headers: {
      "X-Auth-Token": rocketToken,
      "X-User-Id": rocketId,
      "Authorization": token
    }
  }
  console.log('config', config);


  try {
    if (history) yield call(logoutAsync, history);
    localStorage.clear();
    // const logout = yield call(logoutRocketAsync, config);

  } catch (error) {
  }
}

export function* watchForgotPassword() {
  yield takeEvery(FORGOT_PASSWORD, forgotPassword);
}

const forgotPasswordAsync = async (email) => {
  return await axios.post(api.users + '/forgot-password', { email })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}

function* forgotPassword({ payload }) {
  const { email } = payload.forgotUserMail;
  try {
    const forgotPasswordStatus = yield call(forgotPasswordAsync, email);
    if (forgotPasswordStatus.status === 200) {
      yield put(forgotPasswordSuccess('success'));
      payload.history.push('/user/reset-password');

    } else {
      console.log({ message: (forgotPasswordStatus.data) ? forgotPasswordStatus.data.msg : "Network Error" });
      yield put(forgotPasswordError({ message: (forgotPasswordStatus.data) ? forgotPasswordStatus.data.msg : "Network Error" }));
    }
  } catch (error) {
    yield put(forgotPasswordError(error));

  }
}

export function* watchResetPassword() {
  yield takeEvery(RESET_PASSWORD, resetPassword);
}

const resetPasswordAsync = async (code, password) => {
  return await axios.post(api.users + '/reset-password', { code, password })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
}

function* resetPassword({ payload }) {
  const { code, password } = payload;
  try {
    const resetPasswordStatus = yield call(resetPasswordAsync, code, password);
    if (resetPasswordStatus.status === 200) {
      yield put(resetPasswordSuccess('success'));
      payload.history.push('/user/login');

    } else {
      yield put(resetPasswordError({ message: (resetPasswordStatus.data) ? resetPasswordStatus.data.msg : "Network Error" }));
    }
  } catch (error) {
    yield put(resetPasswordError(error));

  }
}
export function* watchDeleteOne() {
  yield takeEvery(USER_DELETE_ONE, deleteOneUser);
}

//GET USER BY FILTER
const getUserByFilterRequest = async (payload) => {
  return await axios.post(api.users + `/filters/${payload.inst_id}/${payload.offset}/${payload.limit}`, payload.filter, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getUserByFilter({ payload }) {

  try {
    const ret = yield call(getUserByFilterRequest, payload);


    if (ret && ret.status == 200)
      yield put(getUserByFilterSuccess({ users: ret.data.users, count: ret.data.count }));

    else yield put(getUserByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getUserByFilterError({ error, message: (error) ? error.message : '' }));
  }
}
export function* watchGetFilter() {
  yield takeEvery(USER_GET_BY_FILTER, getUserByFilter);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetUsersList),
    fork(watchchangeUserState),
    fork(watchLoginUser),
    fork(watchChatLoginUser),
    fork(watchEditUser),
    fork(watchDeleteOne),
    fork(watchLogoutUser),
    fork(watchRegisterUser),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchGetChatroomId),
    fork(watchGetFilter),
  ]);
}