import {
    ADMIN_COMMENTS_GET_LIST,
    ADMIN_COMMENTS_GET_LIST_SUCCESS,
    ADMIN_COMMENTS_GET_LIST_ERROR,

    ADMIN_COMMENT_GET_ONE,
    ADMIN_COMMENT_GET_ONE_SUCCESS,
    ADMIN_COMMENT_GET_ONE_ERROR,

    ADMIN_COMMENT_ADD_ONE,
    ADMIN_COMMENT_ADD_ONE_SUCCESS,
    ADMIN_COMMENT_ADD_ONE_ERROR,

    ADMIN_COMMENT_EDIT_ONE,
    ADMIN_COMMENT_EDIT_ONE_SUCCESS,
    ADMIN_COMMENT_EDIT_ONE_ERROR,

    ADMIN_COMMENT_DELETE_ONE,
    ADMIN_COMMENT_DELETE_ONE_SUCCESS,
    ADMIN_COMMENT_DELETE_ONE_ERROR,

    ADMIN_COMMENTS_DELETE_MANY,
    ADMIN_COMMENTS_DELETE_MANY_SUCCESS,
    ADMIN_COMMENTS_DELETE_MANY_ERROR,
  } from '../actions';

  //GET LIST
export const getAdminCommentsList = (payload) => ({
    type: ADMIN_COMMENTS_GET_LIST,
    payload
  });
  export const getAdminCommentsListSuccess = (payload) => ({
    type: ADMIN_COMMENTS_GET_LIST_SUCCESS,
    payload
  });
  export const getAdminCommentsListError = (payload) => ({
    type: ADMIN_COMMENTS_GET_LIST_ERROR,
    payload
  });
// GET One
export const getOneAdminComment = (payload) => ({
    type: ADMIN_COMMENT_GET_ONE,
    payload
  });
  export const getOneAdminCommentSuccess = (payload) => ({
    type: ADMIN_COMMENT_GET_ONE_SUCCESS,
    payload
  });
  export const getOneAdminCommentError = (payload) => ({
    type: ADMIN_COMMENT_GET_ONE_ERROR,
    payload
  });

  // ADD ONE
export const addAdminComment = (payload) => ({
    type: ADMIN_COMMENT_ADD_ONE,
    payload
  });
  export const addAdminCommentSuccess = (payload) => ({
    type: ADMIN_COMMENT_ADD_ONE_SUCCESS,
    payload
  });
  export const addAdminCommentError = (payload) => ({
    type: ADMIN_COMMENT_ADD_ONE_ERROR,
    payload
  });

  // EDIT ONE
export const editOneAdminComment = (payload) => ({
    type: ADMIN_COMMENT_EDIT_ONE,
    payload
  });
  export const editOneAdminCommentSuccess = (payload) => ({
    type: ADMIN_COMMENT_EDIT_ONE_SUCCESS,
    payload
  });
  export const editOneAdminCommentError = (payload) => ({
    type: ADMIN_COMMENT_EDIT_ONE_ERROR,
    payload
  });


// DELETE ONE
export const deleteOneAdminComment = (payload) => ({
    type: ADMIN_COMMENT_DELETE_ONE,
    payload
  });
export const deleteOneAdminCommentSuccess = (payload) => ({
    type: ADMIN_COMMENT_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneAdminCommentError = (payload) => ({
    type: ADMIN_COMMENT_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManyAdminComments = (payload) => ({
    type:   ADMIN_COMMENTS_DELETE_MANY,
    payload
  });
export const deleteManyAdminCommentsSuccess = (payload) => ({
    type: ADMIN_COMMENTS_DELETE_MANY_SUCCESS,
    payload
  });
export const deleteManyAdminCommentsError = (payload) => ({
    type: ADMIN_COMMENTS_DELETE_MANY_ERROR,
    payload
  });