import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { CANTEEN_RESERVATIONS_GET_LIST,CANTEEN_RESERVATION_GET_ONE,CANTEEN_RESERVATIONS_GET_BY_DATE,CANTEEN_RESERVATIONS_GET_BY_FILTER,
  CANTEEN_RESERVATION_ADD_ONE,CANTEEN_RESERVATION_EDIT_ONE,CANTEEN_RESERVATION_DELETE_ONE,CANTEEN_RESERVATIONS_DELETE_MANY

} from "../actions";

import {
    getCanteenReservationsListSuccess,
    getCanteenReservationsListError,

    getCanteenReservationsBydateSuccess,
    getCanteenReservationsBydateError,

    getOneCanteenReservationSuccess,
    getOneCanteenReservationError,
    
    getCanteenReservationsByFilterSuccess,

    getCanteenReservationsByFilterError,

    addCanteenReservationSuccess,
    addCanteenReservationError,

    editCanteenReservationSuccess,
    editCanteenReservationError,

    deleteCanteenReservationSuccess,
    deleteCanteenReservationError,

    deleteManyCanteenReservationsSuccess,
    deleteManyCanteenReservationsError

} from "./actions";

 //GET LIST
const getCanteenReservationsListRequest = async (payload) => {
    return await axios.get(api.canteen_reservations+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getCanteenReservationsList({payload}) {
    try {
      const ret = yield call(getCanteenReservationsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getCanteenReservationsListSuccess({canteen_reservations: ret.data.canteen_rsvns || [], count: ret.data.count}));
      
      else yield put(getCanteenReservationsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getCanteenReservationsListError({error, message: (error) ? error.message : ''}));
    }
  }


 //GET  COUNT BY DATE
const getCanteenReservationsBydateRequest = async (payload) => {
    return await axios.get(api.canteen_reservations+`/list/${payload.inst_id}/count-by-date`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getCanteenReservationsBydate({payload}) {
    try {
      const ret = yield call(getCanteenReservationsBydateRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getCanteenReservationsBydateSuccess({canteen_reservations_counts: ret.data.canteen_rsvns_counts || [], count: ret.data.count || 0}));
      
      else yield put(getCanteenReservationsBydateError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getCanteenReservationsBydateError({error, message: (error) ? error.message : ''}));
    }
  }

//GET ONE
const getOneCanteenReservationRequest = async (payload) => {
    return await axios.get(api.canteen_reservations+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneCanteenReservation({payload}) {
    try {
      const ret = yield call(getOneCanteenReservationRequest, payload);
  
      if (ret && ret.status==200) yield put(getOneCanteenReservationSuccess({canteen_reservation: ret.data.canteen_rsvn}));
   
      else yield put(getOneCanteenReservationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(getOneCanteenReservationError({error, message: (error) ? error.message : ''}));
    }
  }

//GET CANTEEN RESERVATIONS BY FILTER
const getCanteenReservationsByFilterRequest = async (payload) => {
  //console.log(payload);
  return await axios.post(api.canteen_reservations+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getCanteenReservationsByFilter({payload}) {
  try {
    const ret = yield call(getCanteenReservationsByFilterRequest, payload);
    

    if (ret && ret.status==200){
      //console.log("Reservation by filter : ",ret.data.canteen_rsvns);
      
    yield put(getCanteenReservationsByFilterSuccess({canteen_reservations: ret.data.canteen_rsvns , count: ret.data.count }));
    }

    else yield put(getCanteenReservationsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getCanteenReservationsByFilterError({error, message: (error) ? error.message : ''}));

   

   
}

}
//ADD ONE
const addCanteenReservationRequest = async (payload) => {
    return await axios.post(api.canteen_reservations, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addCanteenReservation({payload}) {  
    try {
      const ret = yield call(addCanteenReservationRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/reservations')
        yield put(addCanteenReservationSuccess({canteen_reservation: ret.data.canteen_rsvn}));
      }
      else yield put(addCanteenReservationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addCanteenReservationError({error, message: (error) ? error.message : ''}));
    }
  }

//EDIT ONE
const editCanteenReservationRequest = async (payload) => {
    return await axios.patch(`${api.canteen_reservations}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editCanteenReservation({payload}) {  
    try {
      const ret = yield call(editCanteenReservationRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/reservations')
        yield put(editCanteenReservationSuccess({canteen_reservation: ret.data.canteen_rsvn}));
      }
      else yield put(editCanteenReservationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editCanteenReservationError({error, message: (error) ? error.message : ''}));
    }
  }

 // DELETE ONE
const deleteCanteenReservationRequest = async (payload) => {
    return await axios.delete(`${api.canteen_reservations}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteCanteenReservation({payload}) {  
    try {
      const ret = yield call(deleteCanteenReservationRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/canteen/reservations')
        yield put(deleteCanteenReservationSuccess({index}));
      }
      else yield put(deleteCanteenReservationError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteCanteenReservationError({error, message: (error) ? error.message : ''}));
    }
  }
//DELETE MANY
const deleteManyCanteenReservationsRequest = async (payload) => {
  return await axios.delete(`${api.canteen_reservations}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyCanteenReservations({ payload }) {
  try {
    const ret = yield call(deleteManyCanteenReservationsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/canteen/reservations')
      yield put(deleteManyCanteenReservationsSuccess({ data }));
    }
    else yield put(deleteManyCanteenReservationsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyCanteenReservationsError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(CANTEEN_RESERVATIONS_GET_LIST, getCanteenReservationsList);
  }
export function* watchGetByDate() {
    yield takeEvery(CANTEEN_RESERVATIONS_GET_BY_DATE, getCanteenReservationsBydate);
  }

export function* watchGetOne() {
    yield takeEvery(CANTEEN_RESERVATION_GET_ONE, getOneCanteenReservation);
  }
  
export function* watchGetFilter() {
    yield takeEvery(CANTEEN_RESERVATIONS_GET_BY_FILTER, getCanteenReservationsByFilter);
  }
export function* watchEditOne() {
    yield takeEvery(CANTEEN_RESERVATION_EDIT_ONE, editCanteenReservation);
  }
  
export function* watchAddOne() {
    yield takeEvery(CANTEEN_RESERVATION_ADD_ONE, addCanteenReservation);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(CANTEEN_RESERVATION_DELETE_ONE, deleteCanteenReservation);
  }
export function* watchDeleteMany() {
    yield takeEvery(CANTEEN_RESERVATIONS_DELETE_MANY,deleteManyCanteenReservations);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetByDate),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)


    ]);
  }