import {
    EXAMMODULES_GET_LIST,
    EXAMMODULES_GET_LIST_SUCCESS,
    EXAMMODULES_GET_LIST_ERROR,

    EXAMMODULES_GET_ONE,
    EXAMMODULES_GET_ONE_SUCCESS,
    EXAMMODULES_GET_ONE_ERROR,
    
    EXAMMODULES_ADD_ONE,
    EXAMMODULES_ADD_ONE_SUCCESS,
    EXAMMODULES_ADD_ONE_ERROR,

    EXAMMODULES_EDIT_ONE,
    EXAMMODULES_EDIT_ONE_SUCCESS,
    EXAMMODULES_EDIT_ONE_ERROR,

    EXAMMODULES_DELETE_ONE,
    EXAMMODULES_DELETE_ONE_SUCCESS,
    EXAMMODULES_DELETE_ONE_ERROR,

    EXAMMODULES_DELETE_MANY,
    EXAMMODULES_DELETE_MANY_SUCCESS,
    EXAMMODULES_DELETE_MANY_ERROR
  } from '../actions';

// GET LIST
export const getExamModulesList = (payload) => ({
    type: EXAMMODULES_GET_LIST,
    payload
  });
export const getExamModulesListSuccess = (payload) => ({
    type: EXAMMODULES_GET_LIST_SUCCESS,
    payload
  });
export const getExamModulesListError = (payload) => ({
    type: EXAMMODULES_GET_LIST_ERROR,
    payload
  });

// GET One
export const getOneExamModules = (payload) => ({
    type: EXAMMODULES_GET_ONE,
    payload
  });
export const getOneExamModulesSuccess = (payload) => ({
    type: EXAMMODULES_GET_ONE_SUCCESS,
    payload
  });
export const getOneExamModulesError = (payload) => ({
    type: EXAMMODULES_GET_ONE_ERROR,
    payload
  });

 // ADD ONE
 export const addExamModules = (payload) => ({
    type: EXAMMODULES_ADD_ONE,
    payload
  });
export const addExamModulesSuccess = (payload) => ({
    type: EXAMMODULES_ADD_ONE_SUCCESS,
    payload
  });
export const addExamModulesError = (payload) => ({
    type: EXAMMODULES_ADD_ONE_ERROR,
    payload
  });


 // EDIT ONE
 export const editOneExamModules = (payload) => ({
    type: EXAMMODULES_EDIT_ONE,
    payload
  });
export const editOneExamModulesSuccess = (payload) => ({
    type: EXAMMODULES_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneExamModulesError = (payload) => ({
    type: EXAMMODULES_EDIT_ONE_ERROR,
    payload
  });

// DELETE ONE
export const deleteOneExamModules= (payload) => ({
    type: EXAMMODULES_DELETE_ONE,
    payload
  });
export const deleteOneExamModulesSuccess = (payload) => ({
    type: EXAMMODULES_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneExamModulesError = (payload) => ({
    type: EXAMMODULES_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManyExamModules = (payload) => ({
  type: EXAMMODULES_DELETE_MANY,
  payload
});
export const deleteManyExamModulesSuccess = (payload) => ({
  type:EXAMMODULES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyExamModulesError = (payload) => ({
  type: EXAMMODULES_DELETE_MANY_ERROR,
  payload
});