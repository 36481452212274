import {
    ADMIN_COMMENT_TYPES_GET_LIST,
    ADMIN_COMMENT_TYPES_GET_LIST_SUCCESS,
    ADMIN_COMMENT_TYPES_GET_LIST_ERROR,

    ADMIN_COMMENT_TYPE_GET_ONE,
    ADMIN_COMMENT_TYPE_GET_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_GET_ONE_ERROR,

    ADMIN_COMMENT_TYPE_ADD_ONE,
    ADMIN_COMMENT_TYPE_ADD_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_ADD_ONE_ERROR,

    ADMIN_COMMENT_TYPE_EDIT_ONE,
    ADMIN_COMMENT_TYPE_EDIT_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_EDIT_ONE_ERROR,

    ADMIN_COMMENT_TYPE_DELETE_ONE,
    ADMIN_COMMENT_TYPE_DELETE_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_DELETE_ONE_ERROR,

    ADMIN_COMMENT_TYPES_DELETE_MANY,
    ADMIN_COMMENT_TYPES_DELETE_MANY_SUCCESS,
    ADMIN_COMMENT_TYPES_DELETE_MANY_ERROR,
  } from '../actions';
  
//GET LIST
export const getAdminCommentTypesList = (payload) => ({
    type: ADMIN_COMMENT_TYPES_GET_LIST,
    payload
  });
  export const getAdminCommentTypesListSuccess = (payload) => ({
    type: ADMIN_COMMENT_TYPES_GET_LIST_SUCCESS,
    payload
  });
  export const getAdminCommentTypesListError = (payload) => ({
    type: ADMIN_COMMENT_TYPES_GET_LIST_ERROR,
    payload
  });

// GET One
export const getOneAdminCommentType = (payload) => ({
    type: ADMIN_COMMENT_TYPE_GET_ONE,
    payload
  });
  export const getOneAdminCommentTypeSuccess = (payload) => ({
    type: ADMIN_COMMENT_TYPE_GET_ONE_SUCCESS,
    payload
  });
  export const getOneAdminCommentTypeError = (payload) => ({
    type: ADMIN_COMMENT_TYPE_GET_ONE_ERROR,
    payload
  });
  
// ADD ONE
export const addAdminCommentType = (payload) => ({
    type: ADMIN_COMMENT_TYPE_ADD_ONE,
    payload
  });
  export const addAdminCommentTypeSuccess = (payload) => ({
    type: ADMIN_COMMENT_TYPE_ADD_ONE_SUCCESS,
    payload
  });
  export const addAdminCommentTypeError = (payload) => ({
    type: ADMIN_COMMENT_TYPE_ADD_ONE_ERROR,
    payload
  });

// EDIT ONE
export const editOneAdminCommentType = (payload) => ({
    type: ADMIN_COMMENT_TYPE_EDIT_ONE,
    payload
  });
  export const editOneAdminCommentTypeSuccess = (payload) => ({
    type: ADMIN_COMMENT_TYPE_EDIT_ONE_SUCCESS,
    payload
  });
  export const editOneAdminCommentTypeError = (payload) => ({
    type: ADMIN_COMMENT_TYPE_EDIT_ONE_ERROR,
    payload
  });

// DELETE ONE
export const deleteOneAdminCommentType= (payload) => ({
    type: ADMIN_COMMENT_TYPE_DELETE_ONE,
    payload
  });
  export const deleteOneAdminCommentTypeSuccess = (payload) => ({
    type: ADMIN_COMMENT_TYPE_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneAdminCommentTypeError = (payload) => ({
    type: ADMIN_COMMENT_TYPE_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManyAdminCommentTypes = (payload) => ({
    type:   ADMIN_COMMENT_TYPES_DELETE_MANY,
    payload
  });
  export const deleteManyAdminCommentTypesSuccess = (payload) => ({
    type: ADMIN_COMMENT_TYPES_DELETE_MANY_SUCCESS,
    payload
  });
  export const deleteManyAdminCommentTypesError = (payload) => ({
    type: ADMIN_COMMENT_TYPES_DELETE_MANY_ERROR,
    payload
  });