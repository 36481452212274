import {
	DIPLOMA_GET_LIST,
	DIPLOMA_GET_LIST_SUCCESS,
  DIPLOMA_GET_LIST_ERROR,

  DIPLOMA_GET_ONE,
	DIPLOMA_GET_ONE_SUCCESS,
  DIPLOMA_GET_ONE_ERROR,

  DIPLOMA_ADD_ONE,
  DIPLOMA_ADD_ONE_SUCCESS,
  DIPLOMA_ADD_ONE_ERROR,

  DIPLOMA_EDIT_ONE,
  DIPLOMA_EDIT_ONE_SUCCESS,
  DIPLOMA_EDIT_ONE_ERROR,

  DIPLOMA_DELETE_ONE,
  DIPLOMA_DELETE_ONE_SUCCESS,
  DIPLOMA_DELETE_ONE_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	diplomas: [],
  count: 0,
  diploma: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case DIPLOMA_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case DIPLOMA_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        diplomas: action.payload.diplomas || [],
        count: action.payload.count || 0
      };
		case DIPLOMA_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case DIPLOMA_GET_ONE:
      return { ...state, loading: true, error: ''};
    case DIPLOMA_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        diploma: action.payload.diploma
      };
    case DIPLOMA_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case DIPLOMA_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case DIPLOMA_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        diplomas: [action.payload.diploma, ...state.diplomas]
      };
    case DIPLOMA_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case DIPLOMA_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case DIPLOMA_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      diploma: action.payload.diploma
      };
    case DIPLOMA_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case DIPLOMA_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case DIPLOMA_DELETE_ONE_SUCCESS:  
      const diplomas = state.diplomas 
      if(action.payload.index!=null && action.payload.index!=undefined)
      diplomas.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        diplomas
      };
    case DIPLOMA_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
