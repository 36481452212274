import {
	TEACHER_GET_LIST,
	TEACHER_GET_LIST_SUCCESS,
  TEACHER_GET_LIST_ERROR,

	SCHOOL_TEACHER_GET_LIST,
	SCHOOL_TEACHER_GET_LIST_SUCCESS,
  SCHOOL_TEACHER_GET_LIST_ERROR,

  TEACHER_GET_LIST_BY_FILTER,
	TEACHER_GET_LIST_BY_FILTER_SUCCESS,
  TEACHER_GET_LIST_BY_FILTER_ERROR,

  TEACHER_GET_LIST_PRIVATE,
	TEACHER_GET_LIST_PRIVATE_SUCCESS,
  TEACHER_GET_LIST_PRIVATE_ERROR,

  TEACHER_GET_ONE,
	TEACHER_GET_ONE_SUCCESS,
  TEACHER_GET_ONE_ERROR,

  TEACHER_ADD_ONE,
  TEACHER_ADD_ONE_SUCCESS,
  TEACHER_ADD_ONE_ERROR,

  TEACHER_ADD_ONE_PRIVATE,
  TEACHER_ADD_ONE_PRIVATE_SUCCESS,
  TEACHER_ADD_ONE_PRIVATE_ERROR,

  TEACHER_EDIT_ONE,
  TEACHER_EDIT_ONE_SUCCESS,
  TEACHER_EDIT_ONE_ERROR,

  TEACHER_DELETE_ONE,
  TEACHER_DELETE_ONE_SUCCESS,
  TEACHER_DELETE_ONE_ERROR,

  TEACHERS_DELETE_MANY,
  TEACHERS_DELETE_MANY_SUCCESS,
  TEACHERS_DELETE_MANY_ERROR,
} from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	teachers: [],
	school_teachers: [],
  teachers_count: 0,
  count: 0,
  teacher: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case TEACHER_GET_LIST:
      return { ...state, loading: true, error: '', 
    };
    case TEACHER_GET_LIST_SUCCESS:
      //const old = state.teachers
      return { ...state, loading: false, 
        teachers: action.payload.teachers || [],
        teachers_count: action.payload.count || 0
      };
		case TEACHER_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };
/***************************************************************************/
		case SCHOOL_TEACHER_GET_LIST:
      return { ...state, loading: true, error: '', count:0
    };
    case SCHOOL_TEACHER_GET_LIST_SUCCESS:
      const old = state.school_teachers 
      //const old = state.teachers
      return { ...state, loading: false, 
        school_teachers:  [...old, ...action.payload.teachers || []], 
        count: action.payload.count || 0 
      };
		case SCHOOL_TEACHER_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


      case TEACHER_GET_LIST_BY_FILTER:
        return { ...state, loading: true, error: '', 
      };
      case TEACHER_GET_LIST_BY_FILTER_SUCCESS:
        return { ...state, loading: false, 
          teachers:  action.payload.teachers ,
          teachers_count: action.payload.count 
        };
      case TEACHER_GET_LIST_BY_FILTER_ERROR:
        return { ...state, loading: false, error: action.payload.message };





    // PRIVATE TEACHERS LIST 
    case TEACHER_GET_LIST_PRIVATE:
        return { ...state, loading: true, error: '', 
        count: 0
      };
      case TEACHER_GET_LIST_PRIVATE_SUCCESS:
        if(action.payload.stacking){
          const old = state.teachers
          return { ...state, loading: false, 
            teachers: [...old, ...action.payload.teachers || []],
            count: action.payload.count || 0
          };
        }
        
        return { ...state, loading: false, 
          teachers: action.payload.teachers || [],
          count: action.payload.count || 0
        };
      case TEACHER_GET_LIST_PRIVATE_ERROR:
        return { ...state, loading: false, error: action.payload.message };





      // GET ONE
    case TEACHER_GET_ONE:
      return { ...state, loading: true, error: ''};
    case TEACHER_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        teacher: action.payload.teacher
      };
    case TEACHER_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };









      // ADD ONE
    case TEACHER_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case TEACHER_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        teachers: [action.payload.teacher, ...state.teachers]
      };
    case TEACHER_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




      // ADD ONE Private
      case TEACHER_ADD_ONE_PRIVATE:
        return { ...state, loading: true, error: ''};
      case TEACHER_ADD_ONE_PRIVATE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, teacher: action.payload.teacher
          // teachers: [action.payload.teacher, ...state.teachers]
        };
      case TEACHER_ADD_ONE_PRIVATE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };



    
      // EDIT ONE
    case TEACHER_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case TEACHER_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      teacher: action.payload.teacher
      };
    case TEACHER_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case TEACHER_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case TEACHER_DELETE_ONE_SUCCESS:  
      const teachers = state.teachers 
      if(action.payload.index!=null && action.payload.index!=undefined)
      teachers.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        teachers
      };
    case TEACHER_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE MANY
    case TEACHERS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case TEACHERS_DELETE_MANY_SUCCESS:
      const teachers_list = state.teachers
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_teacher = -1
          for (let j = 0; j < teachers_list.length; j++) {
            if (teachers_list[j]._id === action.payload.data.ids[i]) {
              index_teacher = j; break;
            }
          }
          if (index_teacher > -1) teachers_list.splice(index_teacher, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        teachers: teachers_list
      };
    case TEACHERS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    
		default: return { ...state };
	}
}
