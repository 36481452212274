import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { MODULE_GET_LIST, MODULE_ADD_ONE, MODULE_GET_ONE, MODULE_EDIT_ONE, MODULE_DELETE_ONE,MODULES_DELETE_MANY
} from "../actions";

import {
  getModulesListSuccess,
  getModulesListError,

  getOneModuleSuccess,
  getOneModuleError,

  addModuleSuccess,
  addModuleError,

  editModuleSuccess,
  editModuleError,
  deleteOneModuleSuccess,
  deleteOneModuleError,

  deleteManyModulesSuccess,
  deleteManyModulesError
} from "./actions";


  //GET LIST
const getModulesListRequest = async (payload) => {
  return await axios.get(api.modules+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getModulesList({payload}) {
  try {
    const ret = yield call(getModulesListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getModulesListSuccess({modules: ret.data.modules || [], count: ret.data.count || 0}));
    
    else yield put(getModulesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getModulesListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneModulesRequest = async (payload) => {
  return await axios.get(api.modules+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneModule({payload}) {  
  try {
    const ret = yield call(getOneModulesRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneModuleSuccess({module: ret.data.module}));
    
    else yield put(getOneModuleError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneModuleError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addModuleRequest = async (payload) => {
  return await axios.post(api.modules, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addModule({payload}) {  
  try {
    const ret = yield call(addModuleRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/modules')
      yield put(addModuleSuccess({module: ret.data.module}));
    }
    else yield put(addModuleError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addModuleError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneModuleRequest = async (payload) => {
  return await axios.patch(`${api.modules}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneModule({payload}) {  
  try {
    const ret = yield call(editOneModuleRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/modules')
      yield put(editModuleSuccess({module: ret.data.module}));
    }
    else yield put(editModuleError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editModuleError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneModuleRequest = async (payload) => {
  return await axios.delete(`${api.modules}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneModule({payload}) {  
  try {
    const ret = yield call(deleteOneModuleRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/modules')
      yield put(deleteOneModuleSuccess({index}));
    }
    else yield put(deleteOneModuleError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneModuleError({error, message: (error) ? error.message : ''}));
  }
}

//DELETE MANY

const deleteManyModulesRequest = async (payload) => {
  return await axios.delete(`${api.modules}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyModules({ payload }) {
  try {
    const ret = yield call(deleteManyModulesRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/modules')
      yield put(deleteManyModulesSuccess({ data }));
    }
    else yield put(deleteManyModulesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyModulesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(MODULE_GET_LIST, getModulesList);
}

export function* watchGetOne() {
  yield takeEvery(MODULE_GET_ONE, getOneModule);
}

export function* watchEditOne() {
  yield takeEvery(MODULE_EDIT_ONE, editOneModule);
}

export function* watchAddOne() {
  yield takeEvery(MODULE_ADD_ONE, addModule);
}

export function* watchDeleteOne() {
  yield takeEvery(MODULE_DELETE_ONE, deleteOneModule);
}
export function* watchDeleteMany() {
  yield takeEvery(MODULES_DELETE_MANY,deleteManyModules);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
