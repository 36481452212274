import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { EDUCATIONLEVEL_GET_LIST, EDUCATIONLEVEL_ADD_ONE, EDUCATIONLEVEL_GET_ONE, EDUCATIONLEVEL_EDIT_ONE, EDUCATIONLEVEL_DELETE_ONE,EDUCATIONLEVELS_DELETE_MANY
} from "../actions";

import {
  getEducationLevelsListSuccess,
  getEducationLevelsListError,

  getOneEducationLevelSuccess,
  getOneEducationLevelError,

  addEducationLevelSuccess,
  addEducationLevelError,

  editEducationLevelSuccess,
  editEducationLevelError,
  deleteOneEducationLevelSuccess,
  deleteOneEducationLevelError,
  deleteManyEducationLevelsSuccess,
  deleteManyEducationLevelsError
} from "./actions";


  //GET LIST
const getEducationLevelsListRequest = async (payload) => {
  return await axios.get(api.education_levels+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getEducationLevelsList({payload}) {
  try {
    const ret = yield call(getEducationLevelsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getEducationLevelsListSuccess({education_levels: ret.data.education_levels || [], count: ret.data.count || 0}));
    
    else yield put(getEducationLevelsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getEducationLevelsListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneEducationLevelsRequest = async (payload) => {
  return await axios.get(api.education_levels+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneEducationLevel({payload}) {  
  try {
    const ret = yield call(getOneEducationLevelsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneEducationLevelSuccess({education_level: ret.data.education_level}));
    
    else yield put(getOneEducationLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneEducationLevelError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addEducationLevelRequest = async (payload) => {
  return await axios.post(api.education_levels, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addEducationLevel({payload}) {  
  try {
    const ret = yield call(addEducationLevelRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/education-levels')
      yield put(addEducationLevelSuccess({education_level: ret.data.education_level}));
    }
    else yield put(addEducationLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addEducationLevelError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneEducationLevelRequest = async (payload) => {
  return await axios.patch(`${api.education_levels}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneEducationLevel({payload}) {  
  try {
    const ret = yield call(editOneEducationLevelRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/education-levels')
      yield put(editEducationLevelSuccess({education_level: ret.data.education_level}));
    }
    else yield put(editEducationLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editEducationLevelError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneEducationLevelRequest = async (payload) => {
  return await axios.delete(`${api.education_levels}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneEducationLevel({payload}) {  
  try {
    const ret = yield call(deleteOneEducationLevelRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/education-levels')
      yield put(deleteOneEducationLevelSuccess({index}));
    }
    else yield put(deleteOneEducationLevelError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneEducationLevelError({error, message: (error) ? error.message : ''}));
  }
}


//DELETE MANY

const deleteManyEducationLevelsRequest = async (payload) => {
  return await axios.delete(`${api.education_levels}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyEducationLevels({ payload }) {
  try {
    const ret = yield call(deleteManyEducationLevelsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/my-institutions/education-levels')
      yield put(deleteManyEducationLevelsSuccess({ data }));
    }
    else yield put(deleteManyEducationLevelsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyEducationLevelsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(EDUCATIONLEVEL_GET_LIST, getEducationLevelsList);
}

export function* watchGetOne() {
  yield takeEvery(EDUCATIONLEVEL_GET_ONE, getOneEducationLevel);
}

export function* watchEditOne() {
  yield takeEvery(EDUCATIONLEVEL_EDIT_ONE, editOneEducationLevel);
}

export function* watchAddOne() {
  yield takeEvery(EDUCATIONLEVEL_ADD_ONE, addEducationLevel);
}

export function* watchDeleteOne() {
  yield takeEvery(EDUCATIONLEVEL_DELETE_ONE, deleteOneEducationLevel);
}
export function* watchDeleteMany() {
  yield takeEvery(EDUCATIONLEVELS_DELETE_MANY,deleteManyEducationLevels);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchDeleteMany)
  ]);
}
