import {
    MEALS_GET_LIST,
    MEALS_GET_LIST_SUCCESS,
    MEALS_GET_LIST_ERROR,

    MEAL_GET_ONE,
    MEAL_GET_ONE_SUCCESS,
    MEAL_GET_ONE_ERROR,
    
    MEAL_ADD_ONE,
    MEAL_ADD_ONE_SUCCESS,
    MEAL_ADD_ONE_ERROR,

    MEAL_EDIT_ONE,
    MEAL_EDIT_ONE_SUCCESS,
    MEAL_EDIT_ONE_ERROR,

    MEAL_DELETE_ONE,
    MEAL_DELETE_ONE_SUCCESS,
    MEAL_DELETE_ONE_ERROR,

    MEALS_DELETE_MANY,
    MEALS_DELETE_MANY_SUCCESS,
    MEALS_DELETE_MANY_ERROR,

  } from '../actions';
  import { NotificationManager } from '../../components/common/react-notifications';
  import  notificationMessages  from '../../lang/locales/notificationMsg';

const INIT_STATE = {
  meals: [],
  count: 0,
  meal: null,
  error: '',
};
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {

	case MEALS_GET_LIST:
      return { ...state, loading: true,get_meals_success:false, error: '', 
      count: 0
    };
    case MEALS_GET_LIST_SUCCESS:
      return { ...state, loading: false, get_meals_success:true,
        meals: action.payload.meals || [],
        count: action.payload.count || 0
      };
	case MEALS_GET_LIST_ERROR:
      return { ...state, loading: false,get_meals_success:false, error: action.payload.message };


        // GET ONE
    case MEAL_GET_ONE:
    return { ...state, loading: true, error: ''};
    case MEAL_GET_ONE_SUCCESS:
    
    return { ...state, loading: false, error: '',
    meal: action.payload.meal
      
    };
    case MEAL_GET_ONE_ERROR:
    return { ...state, loading: false, error: action.payload.message };


    
      // ADD ONE
    case MEAL_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case MEAL_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        meals: [action.payload.meal, ...state.meals]
      };
    case MEAL_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // EDIT ONE
    case MEAL_EDIT_ONE:
       return { ...state, loading: true,edit_sucess:false, error: ''};
    case MEAL_EDIT_ONE_SUCCESS:    
    NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
       return { ...state, loading: false, edit_sucess:true,error: '',
    meal: action.payload.meal
    };
    case MEAL_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
       return { ...state, loading: false,edit_sucess:false, error: action.payload.message };
  




       // DELETE ONE
    case MEAL_DELETE_ONE:
       return { ...state, loading: true, error: ''};
    case MEAL_DELETE_ONE_SUCCESS:  
       const meals = state.meals 
       if(action.payload.index!=null && action.payload.index!=undefined)
       meals.splice(action.payload.index, 1)
 
       return { ...state, loading: false, error: '',
       meals
       };
    case MEAL_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
       return { ...state, loading: false, error: action.payload.message };
      
       // DELETE MANY
    case MEALS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case MEALS_DELETE_MANY_SUCCESS:
      const meals_list = state.meals
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_meal = -1
          for (let j = 0; j < meals_list.length; j++) {
            if (meals_list[j]._id === action.payload.data.ids[i]) {
              index_meal = j; break;
            }
          }
          if (index_meal > -1) meals_list.splice(index_meal, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        meals: meals_list
      };
    case MEALS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
     
   default: return { ...state };







    }
}