import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { NotificationManager } from '../../components/common/react-notifications';
import notificationMessages from "../../lang/locales/notificationMsg";
import { STUDENT_GET_LIST, STUDENT_ADD_ONE, STUDENT_GET_ONE,STUDENT_GET_BY_FILTER, STUDENT_EDIT_ONE, STUDENT_DELETE_ONE , GET_STUDENT_ASSIGNMENTS,STUDENTS_EDIT_BY_IDS 
  , STUDENT_ASSIGNMENT,STUDENTS_DELETE_MANY,STUDENT_GET_ID,STUDENT_CHECK_PAYMENTS,STUDENT_GET_BY_FILTER_STATUS
} from "../actions";

import {
  getStudentsListSuccess,
  getStudentsListError,

  getOneStudentSuccess,
  getOneStudentError,
  
  getStudentByFilterSuccess,
  getStudentByFilterError,

  getStudentByFilterStatusSuccess,
  getStudentByFilterStatusError,

  addStudentSuccess,
  addStudentError,

  editStudentSuccess,
  editStudentError,
  deleteOneStudentSuccess,
  deleteOneStudentError,

  getStudentAssignmentSuccess,
  getStudentAssignmentError,

  studentAssignmentSuccess,
  studentAssignmentError,

  deleteManyStudentsSuccess,
  deleteManyStudentsError,

  editStudentsByIdsSuccess,
  editStudentsByIdsError,

  getStudentIdSuccess,
  getStudentIdError, 

  checkPaymentsSuccess,
  checkPaymentsError,
} from "./actions";

const msgs = notificationMessages() 
  //GET LIST
const getStudentsListRequest = async (payload) => {
  return await axios.get(api.students+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getStudentsList({payload}) {
  try {
    const ret = yield call(getStudentsListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getStudentsListSuccess({students: ret.data.students || [], count: ret.data.count || 0}));
    
    else yield put(getStudentsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getStudentsListError({error, message: (error) ? error.message : ''}));
  }
}

  //GET STUDENT ASSIGNMENTS
  const getStudentAssignmentsRequest = async (payload) => {
    return await axios.get(api.root+`/assignments/student-assignments/${payload.student_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getStudentAssignments({payload}) {
    try {
      const ret = yield call(getStudentAssignmentsRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getStudentAssignmentSuccess({student_assignments: ret.data.assignments || [], count: ret.data.count || 0}));
      
      else yield put(getStudentAssignmentError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getStudentAssignmentError({error, message: (error) ? error.message : ''}));
    }
  }

  // STUDENT ASSIGNMENT
  const studentAssignmentRequest = async (payload) => {
    return await axios.post(api.root+`/assignments`,payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* studentAssignment({payload}) {
    try {
      const ret = yield call(studentAssignmentRequest, payload);
      if (ret && ret.status==201){
       
        const { history } = payload;
        
        if(history) history.push('/app/schooling/students')
        yield put(studentAssignmentSuccess({student_assignment: ret.data.student_assignment }));
      }
    
      
      else yield put(studentAssignmentError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(studentAssignmentError({error, message: (error) ? error.message : ''}));
    }
  }



//GET ONE
const getOneStudentsRequest = async (payload) => {
  return await axios.get(api.students+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneStudent({payload}) {  
  try {
    const ret = yield call(getOneStudentsRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneStudentSuccess({student: ret.data.student}));
    
    else yield put(getOneStudentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneStudentError({error, message: (error) ? error.message : ''}));
  }
}
//GET STUDENT_ID
const getStudentIdRequest = async (payload) => {
  return await axios.post(api.students+`/get-id/${payload.index}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getStudentId({payload}) {  
  try {
    const ret = yield call(getStudentIdRequest, payload);

    if (ret && ret.status==200)
    yield put(getStudentIdSuccess({student_id: ret.data.student_id}));
    
    else yield put(getStudentIdError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getStudentIdError({error, message: (error) ? error.message : ''}));
  }
}
//GET STUDENT BY FILTER
const getStudentByFilterRequest = async (payload) => {
  
  return await axios.post(api.students+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getStudentByFilter({payload}) {
  
  try {
    const ret = yield call(getStudentByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getStudentByFilterSuccess({students: ret.data.students,count:ret.data.count}));
    
    else yield put(getStudentByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getStudentByFilterError({error, message: (error) ? error.message : ''}));
  }
}



//GET STUDENT BY FILTER
const getStudentByFilterStatusRequest = async (payload) => {

  return await axios.post(api.students+`/filterStatus/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getStudentByFilterStatus({payload}) {
  
  try {
    const ret = yield call(getStudentByFilterStatusRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getStudentByFilterStatusSuccess({students: ret.data.students,count:ret.data.count}));
    
    else yield put(getStudentByFilterStatusError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getStudentByFilterStatusError({error, message: (error) ? error.message : ''}));
  }
}


//CHECK PAYMENT
const checkPaymentsRequest=async (payload)=>{
  return await axios.post(api.students+`/check-payments`,payload.data,{ headers: {Authorization: payload.token}})
  .then(resp=>resp)
  .catch(error=>{return(error && error.response)? error.response:error})
}
function* checkPayments({payload}){
  try{
    const ret = yield call(checkPaymentsRequest,payload);
   if(ret && ret.status==200) {
    yield put(checkPaymentsSuccess({payed:ret.data.payed,never_payed:ret.data.never_payed})); 
    if(! ret.data.payed) NotificationManager.error(msgs["please-check-your-fee-month"] , msgs["Error"], 4000, null, null, '');
   }
   
   else yield put(checkPaymentsError({message:(ret.data)? ret.data.msg:"Network Error"}))

  }catch(error){
    yield put(checkPaymentsError({error,message:(error)?error.message:''}))
  }
}
  //ADD ONE
const addStudentRequest = async (payload) => {
  return await axios.post(api.students, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addStudent({payload}) {  
  try {
    console.log('payload.add_parent',payload)
    const ret = yield call(addStudentRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;
if(history&&payload.data.add_parent===true){

  history.push('/app/schooling/parents/add-new')
} else{
  history.push('/app/schooling/students')
}
      yield put(addStudentSuccess({student: ret.data.student}));
    }
    else yield put(addStudentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {

    yield put(addStudentError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneStudentRequest = async (payload) => {
  return await axios.patch(`${api.students}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneStudent({payload}) {  
  try {
    const ret = yield call(editOneStudentRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;
  
      if(history) history.push('/app/schooling/students')
      yield put(editStudentSuccess({student: ret.data.student}));
    }
    else yield put(editStudentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editStudentError({error, message: (error) ? error.message : ''}));
  }
}

//EDIT BY IDS
const editStudentsByIdsRequest = async (payload) => {
  return await axios.patch(`${api.students}/edit-by-ids`, payload.data , { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editStudentsByIds({payload}) {  
  try {
    const ret = yield call(editStudentsByIdsRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/schooling/students')
      yield put(editStudentsByIdsSuccess({student: ret.data.student}));
    }
    else yield put(editStudentsByIdsError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editStudentsByIdsError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneStudentRequest = async (payload) => {
  return await axios.delete(`${api.students}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneStudent({payload}) {  
  try {
    const ret = yield call(deleteOneStudentRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/schooling/students')
      yield put(deleteOneStudentSuccess({index}));
    }
    else yield put(deleteOneStudentError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneStudentError({error, message: (error) ? error.message : ''}));
  }
}

const deleteManyStudentsRequest = async (payload) => {
  return await axios.delete(`${api.students}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyStudents({ payload }) {
  try {
    const ret = yield call(deleteManyStudentsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/students')
      yield put(deleteManyStudentsSuccess({ data }));
    }
    else yield put(deleteManyStudentsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyStudentsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(STUDENT_GET_LIST, getStudentsList);
}

export function* watchGetStudentAssignments() {
  yield takeEvery(GET_STUDENT_ASSIGNMENTS, getStudentAssignments);
}

export function* watchStudentAssignment() {
  yield takeEvery(STUDENT_ASSIGNMENT, studentAssignment);
}

export function* watchGetOne() {
  yield takeEvery(STUDENT_GET_ONE, getOneStudent);
}
export function* watchGetId() {
  yield takeEvery(STUDENT_GET_ID, getStudentId);
}
export function* watchGetFilter() {
  yield takeEvery(STUDENT_GET_BY_FILTER, getStudentByFilter);
}
export function* watchGetFilterByStatus() {
  yield takeEvery(STUDENT_GET_BY_FILTER_STATUS, getStudentByFilterStatus);
}

export function* watchCheckPayments(){
  yield takeEvery(STUDENT_CHECK_PAYMENTS,checkPayments);
}
export function* watchEditOne() {
  yield takeEvery(STUDENT_EDIT_ONE, editOneStudent);
}

export function* watchAddOne() {
  yield takeEvery(STUDENT_ADD_ONE, addStudent);
}

export function* watchDeleteOne() {
  yield takeEvery(STUDENT_DELETE_ONE, deleteOneStudent);
}

export function* watchDeleteMany() {
  yield takeEvery(STUDENTS_DELETE_MANY, deleteManyStudents);
}
export function* watchEditByIds() {
  yield takeEvery(STUDENTS_EDIT_BY_IDS, editStudentsByIds);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchGetId),
    fork(watchGetFilter),
    fork(watchGetFilterByStatus),
    fork(watchCheckPayments),

    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne),
    fork(watchGetStudentAssignments),
    fork(watchStudentAssignment),
    fork(watchDeleteMany),
    fork(watchEditByIds)

  ]);
}
