// import React, { Component, Suspense } from "react";

// const server = 'http://51.91.99.118:5000'

// const server = 'https://dev-api.odesco.store'

 const server = process.env.REACT_APP_API;


// const server = 'http://localhost:5000';

const sms_server = process.env.REACT_APP_GTEXT || 'http://51.91.109.142:3000';

export const api = {
  root: server,
  sms_server : sms_server,
  users: `${server}/users`,
  institutions: `${server}/institutions`,
  school_years: `${server}/school-years`,
  period_types: `${server}/period-types`,
  education_levels: `${server}/education-levels`,
  school_levels: `${server}/school-levels`,
  diplomas: `${server}/diplomas`,
  specialities: `${server}/specialities`,
  subjects: `${server}/subjects`,
  partners: `${server}/partners`,
  // teachers: `${server}/partners/teachers`,
  teachers: `${server}/teachers`,
  parents: `${server}/parents`,
  students: `${server}/students`,
  skills: `${server}/skills`,
  classes: `${server}/classes`,
  modules: `${server}/modules`,
  rooms: `${server}/rooms`,

  homeworks: `${server}/homeworks`,
  sanctions: `${server}/sanctions`,
  sanction_types: `${server}/sanction-types`,
  exemptions: `${server}/exemptions`,
  absences: `${server}/absences`,
  sessions:`${server}/sessions`,
  timetables: `${server}/timetables`,
  exam_types:`${server}/exam-types`,
  exam_periods:`${server}/exam-periods`,
  exam_sessions:`${server}/exam-sessions`,
  exam_notes:`${server}/exam-notes`,
  exam_modules:`${server}/exam-modules`,
  exam_results:`${server}/exam-results`,
  
  research_module:`${server}/research-modules`,
  research_module_themes:`${server}/research-modules/themes`,
  research_module_profiles:`${server}/research-modules/profiles`,
  research_module_indicators:`${server}/research-modules/indicators`,
  research_module_indicator_notes:`${server}/research-modules/indicator-notes`,
  research_module_keys:`${server}/research-modules/keys`,

  mark_module:`${server}/exam-mark-modules`,
  marks:`${server}/exam-marks`,
  mark_note:`${server}/exam-mark-notes`,
  admin_comment_types:`${server}/admin-comment-types`,
  admin_comments:`${server}/admin-comments`,
  teacher_comments:`${server}/teacher-comments`,
  /*billing*/
  taxes: `${server}/taxes`,
  fees: `${server}/fees`,
  fee_compositions:`${server}/fee-compositions`,
  fee_types:`${server}/fees-types`,
  payments:`${server}/payments`,
  bills:`${server}/bills`,
  /*community*/
  appointements:`${server}/appointments`,
  parents_school:`${server}/parent-school`,
  contact_msg:`${server}/contact-msg`,

  /*cantine*/
  meal_types:`${server}/meal-types`,
  meals:`${server}/meals`,
  canteen_menus:`${server}/canteen-menus`,
  canteen_reservation_types:`${server}/canteen-rsvn-types`,
  canteen_reservations:`${server}/canteen-rsvns`,

  posts: `${server}/posts`,
  likes: `${server}/likes`,
  comments: `${server}/comments`,
  events: `${server}/events`,
  invitations: `${server}/invitations`,
  jobs: `${server}/job-offers`,
  trainings: `${server}/trainings`,
  courses: `${server}/courses`,
  configs:`${server}/configs`,
  tutorings:`${server}/tutorings`,
  groups:`${server}/groups`,
}

/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = "menu-default";

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = "fr";
export const localeOptions = [
  { id: "en", name: "English", direction: "ltr" },
  { id: "fr", name: "Français", direction: "ltr" },
  { id: "ar", name: "العربية", direction: "rtl" }

  // { id: "es", name: "Español", direction: "ltr" },
  // { id: "enrtl", name: "English - RTL", direction: "rtl" }
];

export const firebaseConfig = {
  apiKey: "AIzaSyBBksq-Asxq2M4Ot-75X19IyrEYJqNBPcg",
  authDomain: "gogo-react-login.firebaseapp.com",
  // databaseURL: "https://gogo-react-login.firebaseio.com",
  projectId: "gogo-react-login",
  storageBucket: "gogo-react-login.appspot.com",
  messagingSenderId: "216495999563"
};

export const searchPath = "/app/pages/search";
export const servicePath = "https://api.coloredstrategies.com";

/* 
Color Options:
"light.purple", "light.blue", "light.green", "light.orange", "light.red", "dark.purple", "dark.blue", "dark.green", "dark.orange", "dark.red"
*/
export const isMultiColorActive = true;
export const defaultColor = "light.blue" //"light.purple";
export const defaultDirection = "ltr";
export const isDarkSwitchActive = true;
export const themeColorStorageKey="__theme_color";
export const themeRadiusStorageKey = "__theme_radius";
export const isDemo = false;

export const is_white_mark= true;

export const icons = {
  attendance: '/assets/img/icons/attendance.png',
  events:     '/assets/img/icons/events.png',
  exams:      '/assets/img/icons/exams.png',
  search_modules:  '/assets/img/icons/search_modules.png',
  comments :'/assets/img/icons/comments.png',
  homeworks:  '/assets/img/icons/homeworks.png',
  sanctions: '/assets/img/icons/sanctions.png',
  inst:       '/assets/img/icons/inst.png',
  job:        '/assets/img/icons/job.png',
  members:    '/assets/img/icons/members.png',
  timetable:  '/assets/img/icons/timetable.png',
  training:   '/assets/img/icons/training.png',
  tutors:     '/assets/img/icons/tutors.png',
  canteen:     '/assets/img/icons/canteen.png',
  appointement:'/assets/img/icons/appointement.png',
  user:           '/assets/img/no-user.png',
  student_male:   '/assets/img/student_male.jpeg',
  student_female: '/assets/img/student_female.png',

  teacher_male:   '/assets/img/teacher_male.jpg',
  teacher_female: '/assets/img/teacher_female.jpg',

  father:   '/assets/img/father.jpg',
  mother:   '/assets/img/mother.png',

  empty_page:'/assets/img/empty_page.png',

  event:   '/assets/img/event.jpeg',
  cover: '/assets/img/icons/cover_img.png',

  payment : '/assets/img/icons/icon-paiement.png',
  envelope : '/assets/img/icons/envelope.png',

  pdfDownload:'/assets/img/icons/pdf-01.png',
  send:'/assets/img/icons/send-02.png',


}

