import {
  SCOOLYEAR_GET_LIST,
	SCOOLYEAR_GET_LIST_SUCCESS,
  SCOOLYEAR_GET_LIST_ERROR,

  SCOOLYEAR_GET_ONE,
	SCOOLYEAR_GET_ONE_SUCCESS,
  SCOOLYEAR_GET_ONE_ERROR,

  SCOOLYEAR_ADD_ONE,
  SCOOLYEAR_ADD_ONE_SUCCESS,
  SCOOLYEAR_ADD_ONE_ERROR,

  SCOOLYEAR_EDIT_ONE,
  SCOOLYEAR_EDIT_ONE_SUCCESS,
  SCOOLYEAR_EDIT_ONE_ERROR,

  SCHOOLYEAR_DELETE_ONE,
  SCHOOLYEAR_DELETE_ONE_SUCCESS,
  SCHOOLYEAR_DELETE_ONE_ERROR,

  SCHOOLYEARS_DELETE_MANY,
  SCHOOLYEARS_DELETE_MANY_SUCCESS,
  SCHOOLYEARS_DELETE_MANY_ERROR,

  GET_SELECTED_SCHOOLYEAR
} from '../actions';

  // GET LIST
export const getSchoolYearsList = (payload) => ({
  type: SCOOLYEAR_GET_LIST,
  payload
});
export const getSchoolYearsListSuccess = (payload) => ({
  type: SCOOLYEAR_GET_LIST_SUCCESS,
  payload
});
export const getSchoolYearsListError = (payload) => ({
  type: SCOOLYEAR_GET_LIST_ERROR,
  payload
});


 // GET SELECTED SCHOOL YEAR
 export const getSelectedSchoolYear = (payload) => ({
  type: GET_SELECTED_SCHOOLYEAR,
  payload
});




  // GET LIST
export const getOneSchoolYear = (payload) => ({
  type: SCOOLYEAR_GET_ONE,
  payload
});
export const getOneSchoolYearSuccess = (payload) => ({
  type: SCOOLYEAR_GET_ONE_SUCCESS,
  payload
});
export const getOneSchoolYearError = (payload) => ({
  type: SCOOLYEAR_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addSchoolYear = (payload) => ({
  type: SCOOLYEAR_ADD_ONE,
  payload
});
export const addSchoolYearSuccess = (payload) => ({
  type: SCOOLYEAR_ADD_ONE_SUCCESS,
  payload
});
export const addSchoolYearError = (payload) => ({
  type: SCOOLYEAR_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editSchoolYear = (payload) => ({
  type: SCOOLYEAR_EDIT_ONE,
  payload
});
export const editSchoolYearSuccess = (payload) => ({
  type: SCOOLYEAR_EDIT_ONE_SUCCESS,
  payload
});
export const editSchoolYearError = (payload) => ({
  type: SCOOLYEAR_EDIT_ONE_ERROR,
  payload
});


  // DELETE ONE
  export const deleteOneSchoolYear = (payload) => ({
    type: SCHOOLYEAR_DELETE_ONE,
    payload
  });
  export const deleteOneSchoolYearSuccess = (payload) => ({
    type: SCHOOLYEAR_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneSchoolYearError = (payload) => ({
    type: SCHOOLYEAR_DELETE_ONE_ERROR,
    payload
  });

  // DELETE MANY
export const deleteManySchoolYears = (payload) => ({
  type:   SCHOOLYEARS_DELETE_MANY,
  payload
});
export const deleteManySchoolYearsSuccess = (payload) => ({
  type: SCHOOLYEARS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManySchoolYearsError = (payload) => ({
  type: SCHOOLYEARS_DELETE_MANY_ERROR,
  payload
});