import {
	HOMEWORK_GET_LIST,
	HOMEWORK_GET_LIST_SUCCESS,
  HOMEWORK_GET_LIST_ERROR,

  HOMEWORK_GET_ONE,
	HOMEWORK_GET_ONE_SUCCESS,
  HOMEWORK_GET_ONE_ERROR,

  HOMEWORKS_GET_BY_FILTER,
  HOMEWORKS_GET_BY_FILTER_SUCCESS,
  HOMEWORKS_GET_BY_FILTER_ERROR,

  HOMEWORK_ADD_ONE,
  HOMEWORK_ADD_ONE_SUCCESS,
  HOMEWORK_ADD_ONE_ERROR,

  HOMEWORK_EDIT_ONE,
  HOMEWORK_EDIT_ONE_SUCCESS,
  HOMEWORK_EDIT_ONE_ERROR,

  HOMEWORK_DELETE_ONE,
  HOMEWORK_DELETE_ONE_SUCCESS,
  HOMEWORK_DELETE_ONE_ERROR,

  HOMEWORKS_DELETE_MANY,
  HOMEWORKS_DELETE_MANY_SUCCESS,
  HOMEWORKS_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
	homeworks: [],
  count: 0,
  homework: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case HOMEWORK_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case HOMEWORK_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        homeworks: action.payload.homeworks || [],
        count: action.payload.count || 0
      };
		case HOMEWORK_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };






      // GET ONE
    case HOMEWORK_GET_ONE:
      return { ...state, loading: true, error: ''};
    case HOMEWORK_GET_ONE_SUCCESS:
      
      return { ...state, loading: false, error: '',
        homework: action.payload.homework
        
      };
    case HOMEWORK_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




      // GET BY FILTER
      case HOMEWORKS_GET_BY_FILTER:
            
      return { ...state, loading: true, error: ''};
      case HOMEWORKS_GET_BY_FILTER_SUCCESS:
        
      return { ...state, loading: false, error: '',
      homeworks: action.payload.homeworks || [],
      count: action.payload.count || 0
      };
      case HOMEWORKS_GET_BY_FILTER_ERROR:
        
      return { ...state, loading: false, error: action.payload.message };




      // ADD ONE
    case HOMEWORK_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case HOMEWORK_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        homeworks: [action.payload.homework, ...state.homeworks]
      };
    case HOMEWORK_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case HOMEWORK_EDIT_ONE:
      return { ...state, loading: true,edit_sucess:false, error: ''};
    case HOMEWORK_EDIT_ONE_SUCCESS:    
    NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, edit_sucess:true,error: '',
      homework: action.payload.homework
      };
    case HOMEWORK_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false,edit_sucess:false, error: action.payload.message };

    




      // DELETE ONE
    case HOMEWORK_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case HOMEWORK_DELETE_ONE_SUCCESS:  
      const homeworks = state.homeworks 
      if(action.payload.index!=null && action.payload.index!=undefined)
      homeworks.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        homeworks
      };
    case HOMEWORK_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

         // DELETE MANY
    case HOMEWORKS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case HOMEWORKS_DELETE_MANY_SUCCESS:
      const homeworks_list = state.homeworks
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_homework = -1
          for (let j = 0; j < homeworks_list.length; j++) {
            if (homeworks_list[j]._id === action.payload.data.ids[i]) {
              index_homework = j; break;
            }
          }
          if (index_homework > -1) homeworks_list.splice(index_homework, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        homeworks: homeworks_list
      };
    case HOMEWORKS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
		default: return { ...state };
	}
}
