import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { CANTEENMENUS_GET_LIST,CANTEENMENU_GET_ONE,CANTEENMENU_ADD_ONE,CANTEENMENU_EDIT_ONE,CANTEENMENU_DELETE_ONE,CANTEENMENUS_DELETE_MANY
} from "../actions";

import {
    getCanteenMenusListSuccess,
    getCanteenMenusListError,


    getOneCanteenMenuSuccess,
    getOneCanteenMenuError,

    addCanteenMenuSuccess,
    addCanteenMenuError,

    editCanteenMenuSuccess,
    editCanteenMenuError,

    deleteOneCanteenMenuSuccess,
    deleteOneCanteenMenuError,

    deleteManyCanteenMenusSuccess,
    deleteManyCanteenMenusError
} from "./actions";

  //GET LIST
const getCanteenMenusListRequest = async (payload) => {
    return await axios.get(api.canteen_menus+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getCanteenMenusList({payload}) {
    try {
      const ret = yield call(getCanteenMenusListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getCanteenMenusListSuccess({canteen_menus: ret.data.canteen_menus || [], count: ret.data.count || 0}));
      
      else yield put(getCanteenMenusListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getCanteenMenusListError({error, message: (error) ? error.message : ''}));
    }
  }

//GET ONE
const getOneCanteenMenuRequest = async (payload) => {
    return await axios.get(api.canteen_menus+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneCanteenMenu({payload}) {
    try {
      const ret = yield call(getOneCanteenMenuRequest, payload);
  
      if (ret && ret.status==200) yield put(getOneCanteenMenuSuccess({canteen_menu: ret.data.canteen_menu}));
   
      else yield put(getOneCanteenMenuError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(getOneCanteenMenuError({error, message: (error) ? error.message : ''}));
    }
  }

//ADD ONE
const addCanteenMenuRequest = async (payload) => {
    return await axios.post(api.canteen_menus, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addCanteenMenu({payload}) {  
    try {
      const ret = yield call(addCanteenMenuRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/canteen-menus')
        yield put(addCanteenMenuSuccess({canteen_menu: ret.data.canteen_menu}));
      }
      else yield put(addCanteenMenuError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addCanteenMenuError({error, message: (error) ? error.message : ''}));
    }
  }

//EDIT ONE
const editCanteenMenuRequest = async (payload) => {
    return await axios.patch(`${api.canteen_menus}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editCanteenMenu({payload}) {  
    try {
      const ret = yield call(editCanteenMenuRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/canteen-menus')
        yield put(editCanteenMenuSuccess({canteen_menu: ret.data.canteen_menu}));
      }
      else yield put(editCanteenMenuError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editCanteenMenuError({error, message: (error) ? error.message : ''}));
    }
  }

 // DELETE ONE
 const deleteOneCanteenMenuRequest = async (payload) => {
    return await axios.delete(`${api.canteen_menus}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneCanteenMenu({payload}) {  
    try {
      const ret = yield call(deleteOneCanteenMenuRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/canteen/canteen-menus')
        yield put(deleteOneCanteenMenuSuccess({index}));
      }
      else yield put(deleteOneCanteenMenuError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneCanteenMenuError({error, message: (error) ? error.message : ''}));
    }
  }
  
//DELETE MANY
const deleteManyCanteenMenusRequest = async (payload) => {
  return await axios.delete(`${api.canteen_menus}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyCanteenMenus({ payload }) {
  try {
    const ret = yield call(deleteManyCanteenMenusRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/canteen/canteen-menus')
      yield put(deleteManyCanteenMenusSuccess({ data }));
    }
    else yield put(deleteManyCanteenMenusError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyCanteenMenusError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(CANTEENMENUS_GET_LIST, getCanteenMenusList);
  }

export function* watchGetOne() {
    yield takeEvery(CANTEENMENU_GET_ONE, getOneCanteenMenu);
  }
  
export function* watchEditOne() {
    yield takeEvery(CANTEENMENU_EDIT_ONE, editCanteenMenu);
  }
  
export function* watchAddOne() {
    yield takeEvery(CANTEENMENU_ADD_ONE, addCanteenMenu);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(CANTEENMENU_DELETE_ONE, deleteOneCanteenMenu);
  }
export function* watchDeleteMany() {
    yield takeEvery(CANTEENMENUS_DELETE_MANY,deleteManyCanteenMenus);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)

    ]);
  }