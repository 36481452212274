import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { MEALTYPES_GET_LIST,MEALTYPE_GET_ONE,MEALTYPE_ADD_ONE,MEALTYPE_EDIT_ONE,MEALTYPE_DELETE_ONE,MEALTYPES_DELETE_MANY
} from "../actions";

import {
    getMealTypesListSuccess,
    getMealTypesListError,

    getOneMealTypeSuccess,
    getOneMealTypeError,

    addMealTypeSuccess,
    addMealTypeError,

    editMealTypeSuccess,
    editMealTypeError,

    deleteOneMealTypeSuccess,
    deleteOneMealTypeError,

    deleteManyMealTypesSuccess,
    deleteManyMealTypesError
} from "./actions";

//GET LIST
const getMealTypesListRequest = async (payload) => {
    return await axios.get(api.meal_types+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getMealTypesList({payload}) {
    try {
      const ret = yield call(getMealTypesListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getMealTypesListSuccess({meal_types: ret.data.meal_types || [], count: ret.data.count || 0}));
      
      else yield put(getMealTypesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getMealTypesListError({error, message: (error) ? error.message : ''}));
    }
  }

//GET ONE
const getOneMealTypeRequest = async (payload) => {
    return await axios.get(api.meal_types+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneMealType({payload}) {  
    try {
      const ret = yield call(getOneMealTypeRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneMealTypeSuccess({meal_type: ret.data.meal_type}));
      
      else yield put(getOneMealTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneMealTypeError({error, message: (error) ? error.message : ''}));
    }
  }

  //ADD ONE
const addMealTypeRequest = async (payload) => {
    return await axios.post(api.meal_types, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addMealType({payload}) {  
    try {
      const ret = yield call(addMealTypeRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/meal-types')
        yield put(addMealTypeSuccess({meal_type: ret.data.meal_type}));
      }
      else yield put(addMealTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addMealTypeError({error, message: (error) ? error.message : ''}));
    }
  }

  //EDIT ONE
const editMealTypeRequest = async (payload) => {
    return await axios.patch(`${api.meal_types}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editMealType({payload}) {  
    try {
      const ret = yield call(editMealTypeRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/canteen/meal-types')
        yield put(editMealTypeSuccess({meal_type: ret.data.meal_type}));
      }
      else yield put(editMealTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editMealTypeError({error, message: (error) ? error.message : ''}));
    }
  }


  // DELETE ONE
const deleteOneMealTypeRequest = async (payload) => {
    return await axios.delete(`${api.meal_types}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneMealType({payload}) {  
    try {
      const ret = yield call(deleteOneMealTypeRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/canteen/meal-types')
        yield put(deleteOneMealTypeSuccess({index}));
      }
      else yield put(deleteOneMealTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneMealTypeError({error, message: (error) ? error.message : ''}));
    }
  }

//DELETE MANY
const deleteManyMealTypesRequest = async (payload) => {
  return await axios.delete(`${api.meal_types}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyMealTypes({ payload }) {
  try {
    const ret = yield call(deleteManyMealTypesRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/canteen/meal-types')
      yield put(deleteManyMealTypesSuccess({ data }));
    }
    else yield put(deleteManyMealTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyMealTypesError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(MEALTYPES_GET_LIST, getMealTypesList);
  }
  
export function* watchGetOne() {
    yield takeEvery(MEALTYPE_GET_ONE, getOneMealType);
  }
  
export function* watchEditOne() {
    yield takeEvery(MEALTYPE_EDIT_ONE, editMealType);
  }
  
export function* watchAddOne() {
    yield takeEvery(MEALTYPE_ADD_ONE, addMealType);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(MEALTYPE_DELETE_ONE, deleteOneMealType);
  }
export function* watchDeleteMany() {
    yield takeEvery(MEALTYPES_DELETE_MANY,deleteManyMealTypes);
  }
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)
    ]);
  }