import {
  SPECIALITY_GET_LIST,
	SPECIALITY_GET_LIST_SUCCESS,
  SPECIALITY_GET_LIST_ERROR,

  SPECIALITY_GET_ONE,
	SPECIALITY_GET_ONE_SUCCESS,
  SPECIALITY_GET_ONE_ERROR,

  SPECIALITY_ADD_ONE,
  SPECIALITY_ADD_ONE_SUCCESS,
  SPECIALITY_ADD_ONE_ERROR,

  SPECIALITY_EDIT_ONE,
  SPECIALITY_EDIT_ONE_SUCCESS,
  SPECIALITY_EDIT_ONE_ERROR,

  SPECIALITY_DELETE_ONE,
  SPECIALITY_DELETE_ONE_SUCCESS,
  SPECIALITY_DELETE_ONE_ERROR
} from '../actions';

  // GET LIST
export const getSpecialitysList = (payload) => ({
  type: SPECIALITY_GET_LIST,
  payload
});
export const getSpecialitysListSuccess = (payload) => ({
  type: SPECIALITY_GET_LIST_SUCCESS,
  payload
});
export const getSpecialitysListError = (payload) => ({
  type: SPECIALITY_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneSpeciality = (payload) => ({
  type: SPECIALITY_GET_ONE,
  payload
});
export const getOneSpecialitySuccess = (payload) => ({
  type: SPECIALITY_GET_ONE_SUCCESS,
  payload
});
export const getOneSpecialityError = (payload) => ({
  type: SPECIALITY_GET_ONE_ERROR,
  payload
});








  // ADD ONE
export const addSpeciality = (payload) => ({
  type: SPECIALITY_ADD_ONE,
  payload
});
export const addSpecialitySuccess = (payload) => ({
  type: SPECIALITY_ADD_ONE_SUCCESS,
  payload
});
export const addSpecialityError = (payload) => ({
  type: SPECIALITY_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editSpeciality = (payload) => ({
  type: SPECIALITY_EDIT_ONE,
  payload
});
export const editSpecialitySuccess = (payload) => ({
  type: SPECIALITY_EDIT_ONE_SUCCESS,
  payload
});
export const editSpecialityError = (payload) => ({
  type: SPECIALITY_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneSpeciality = (payload) => ({
  type: SPECIALITY_DELETE_ONE,
  payload
});
export const deleteOneSpecialitySuccess = (payload) => ({
  type: SPECIALITY_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneSpecialityError = (payload) => ({
  type: SPECIALITY_DELETE_ONE_ERROR,
  payload
});