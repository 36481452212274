import {
    EXAMMODULES_GET_LIST,
    EXAMMODULES_GET_LIST_SUCCESS,
    EXAMMODULES_GET_LIST_ERROR,

    EXAMMODULES_GET_ONE,
    EXAMMODULES_GET_ONE_SUCCESS,
    EXAMMODULES_GET_ONE_ERROR,
    
    EXAMMODULES_ADD_ONE,
    EXAMMODULES_ADD_ONE_SUCCESS,
    EXAMMODULES_ADD_ONE_ERROR,

    EXAMMODULES_EDIT_ONE,
    EXAMMODULES_EDIT_ONE_SUCCESS,
    EXAMMODULES_EDIT_ONE_ERROR,

    EXAMMODULES_DELETE_ONE,
    EXAMMODULES_DELETE_ONE_SUCCESS,
    EXAMMODULES_DELETE_ONE_ERROR,

    EXAMMODULES_DELETE_MANY,
    EXAMMODULES_DELETE_MANY_SUCCESS,
    EXAMMODULES_DELETE_MANY_ERROR
  } from '../actions';
  import { NotificationManager } from '../../components/common/react-notifications';
  import  notificationMessages  from '../../lang/locales/notificationMsg';

  const INIT_STATE = {
        exam_modules: [],
        count: 0,
        exam_module: null,
        error: '',
  };
  
  export default (state = INIT_STATE, action) => {
      const messages = notificationMessages()
      switch (action.type) {
        //GET LIST
        case EXAMMODULES_GET_LIST:
        return { ...state, loading: true, error: '', 
        count: 0
        };
        case EXAMMODULES_GET_LIST_SUCCESS:
  
        return { ...state, loading: false, 
        exam_modules: action.payload.exam_modules ,
        count: action.payload.count || 0
          
        };
  
        case EXAMMODULES_GET_LIST_ERROR:
        return { ...state, loading: false, error: action.payload.message };


           // GET ONE
        case EXAMMODULES_GET_ONE:
        return { ...state, loading: true, error: ''};
        case EXAMMODULES_GET_ONE_SUCCESS:
        return { ...state, loading: false, error: '',
        exam_module: action.payload.exam_module
        };
        case EXAMMODULES_GET_ONE_ERROR:
        return { ...state, loading: false, error: action.payload.message };


            // ADD ONE
        case EXAMMODULES_ADD_ONE:
        return { ...state, loading: true, error: ''};
        case EXAMMODULES_ADD_ONE_SUCCESS:    
            NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
              exam_modules: [action.payload.exam_module, ...state.exam_modules]
        };
        case EXAMMODULES_ADD_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };


        // EDIT ONE
        case EXAMMODULES_EDIT_ONE:
        return { ...state, loading: true,edit_sucess:false, error: ''};
        case EXAMMODULES_EDIT_ONE_SUCCESS:    
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        return { ...state, loading: false,edit_sucess:true, error: '',
        exam_module: action.payload.exam_module
        };
        case EXAMMODULES_EDIT_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false,edit_sucess:false, error: action.payload.message };
      


        // DELETE ONE
        case EXAMMODULES_DELETE_ONE:
        return { ...state, loading: true, error: ''};
        case EXAMMODULES_DELETE_ONE_SUCCESS:  
        const exam_modules = state.exam_modules
        if(action.payload.index!=null && action.payload.index!=undefined)
        exam_modules.splice(action.payload.index, 1)
        
            return { ...state, loading: false, error: '',
            exam_modules
                };
        case EXAMMODULES_DELETE_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
        
             // DELETE MANY
        case EXAMMODULES_DELETE_MANY:
            return { ...state, loading: true, error: '' };
          case EXAMMODULES_DELETE_MANY_SUCCESS:
            const exam_modules_list = state.exam_modules
          
  
            if (action.payload.data.ids.length > 0) {
        
              for (let i = 0; i < action.payload.data.ids.length; i++) {
  
                let index_exam_module = -1
                for (let j = 0; j < exam_modules_list.length; j++) {
                  if (exam_modules_list[j]._id === action.payload.data.ids[i]) {
                    index_exam_module = j; break;
                  }
                }
                if (index_exam_module > -1) exam_modules_list.splice(index_exam_module, 1)
              }
            }
            return {
              ...state, loading: false, error: '',
              exam_modules: exam_modules_list
            };
          case EXAMMODULES_DELETE_MANY_ERROR:
            NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
            return { ...state, loading: false, error: action.payload.message };
        default: return { ...state };
    
    }
    }