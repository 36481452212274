
import {
  EXAMSEARCHMODULES_GET_BY_FILTER,
  EXAMSEARCHMODULES_GET_BY_FILTER_SUCCESS,
  EXAMSEARCHMODULES_GET_BY_FILTER_ERROR,

  EXAMSEARCHMODULE_DELETE_ONE,
  EXAMSEARCHMODULE_DELETE_ONE_SUCCESS,
  EXAMSEARCHMODULE_DELETE_ONE_ERROR,

  EXAMSEARCHMODULES_ADD_ONE,
  EXAMSEARCHMODULES_ADD_ONE_SUCCESS,
  EXAMSEARCHMODULES_ADD_ONE_ERROR,

  EXAMSEARCHMODULE_PRINT_BY_USER,
  EXAMSEARCHMODULE_PRINT_BY_USER_SUCCESS,
  EXAMSEARCHMODULE_PRINT_BY_USER_ERROR,

  EXAMSEARCHMODULE_GET_ONE,
  EXAMSEARCHMODULE_GET_ONE_SUCCESS,
  EXAMSEARCHMODULE_GET_ONE_ERROR,


  EXAMSEARCHMODULE_BY_USER,
  EXAMSEARCHMODULE_BY_USER_SUCCESS,
  EXAMSEARCHMODULE_BY_USER_ERROR,

  EXAMSEARCHMODULE_EDIT_ONE,
  EXAMSEARCHMODULE_EDIT_ONE_SUCCESS,
  EXAMSEARCHMODULE_EDIT_ONE_ERROR,
} from '../actions';

export const getexamSearchModulesByFilter = (payload) => ({
  type: EXAMSEARCHMODULES_GET_BY_FILTER,
  payload
});
export const getexamSearchModulesByFilterSuccess = (payload) => ({
  type: EXAMSEARCHMODULES_GET_BY_FILTER_SUCCESS,
  payload
});
export const getexamSearchModulesByFilterError = (payload) => ({
  type: EXAMSEARCHMODULES_GET_BY_FILTER_ERROR,
  payload
})
// GET One
export const getOneExamSearchModule = (payload) => ({
  type: EXAMSEARCHMODULE_GET_ONE,
  payload
});
export const getOneExamSearchModuleSuccess = (payload) => ({
  type: EXAMSEARCHMODULE_GET_ONE_SUCCESS,
  payload
});
export const getOneExamSearchModuleError = (payload) => ({
  type: EXAMSEARCHMODULE_GET_ONE_ERROR,
  payload
});



// GET BY USER
export const getExamSearchModuleByUser = (payload) => ({
  type: EXAMSEARCHMODULE_BY_USER,
  payload
});
export const getExamSearchModuleByUserSuccess = (payload) => ({
  type: EXAMSEARCHMODULE_BY_USER_SUCCESS,
  payload
});
export const getExamSearchModuleByUserError = (payload) => ({
  type: EXAMSEARCHMODULE_BY_USER_ERROR,
  payload
});




// PRINT BY USER
export const printExamSearchModuleByUser = (payload) => ({
  type: EXAMSEARCHMODULE_PRINT_BY_USER,
  payload
});
export const printExamSearchModuleByUserSuccess = (payload) => ({
  type: EXAMSEARCHMODULE_PRINT_BY_USER_SUCCESS,
  payload
});
export const printExamSearchModuleByUserError = (payload) => ({
  type: EXAMSEARCHMODULE_PRINT_BY_USER_ERROR,
  payload
});


// ADD ONE
export const addexamSearchModules = (payload) => ({
  type: EXAMSEARCHMODULES_ADD_ONE,
  payload
});
export const addexamSearchModulesSuccess = (payload) => ({
  type: EXAMSEARCHMODULES_ADD_ONE_SUCCESS,
  payload
});
export const addexamSearchModulesError = (payload) => ({
  type: EXAMSEARCHMODULES_ADD_ONE_ERROR,
  payload
});
// EDIT ONE
export const editOneExamSearchModule = (payload) => ({
  type: EXAMSEARCHMODULE_EDIT_ONE,
  payload
});
export const editOneExamSearchModuleSuccess = (payload) => ({
  type: EXAMSEARCHMODULE_EDIT_ONE_SUCCESS,
  payload
});
export const editOneExamSearchModuleError = (payload) => ({
  type: EXAMSEARCHMODULE_EDIT_ONE_ERROR,
  payload
});

// DELETE ONE
export const deleteOneExamSearchModule = (payload) => ({
  type: EXAMSEARCHMODULE_DELETE_ONE,
  payload
});
export const deleteOneExamSearchModuleSuccess = (payload) => ({
  type: EXAMSEARCHMODULE_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneExamSearchModuleError = (payload) => ({
  type: EXAMSEARCHMODULE_DELETE_ONE_ERROR,
  payload
});