import {
    RESERVATION_TYPES_GET_LIST,
    RESERVATION_TYPES_GET_LIST_SUCCESS,
    RESERVATION_TYPES_GET_LIST_ERROR,

    RESERVATION_TYPE_GET_ONE,
    RESERVATION_TYPE_GET_ONE_SUCCESS,
    RESERVATION_TYPE_GET_ONE_ERROR,

    RESERVATION_TYPE_ADD_ONE,
    RESERVATION_TYPE_ADD_ONE_SUCCESS,
    RESERVATION_TYPE_ADD_ONE_ERROR,

    RESERVATION_TYPE_EDIT_ONE,
    RESERVATION_TYPE_EDIT_ONE_SUCCESS,
    RESERVATION_TYPE_EDIT_ONE_ERROR,

    RESERVATION_TYPE_DELETE_ONE,
    RESERVATION_TYPE_DELETE_ONE_SUCCESS,
    RESERVATION_TYPE_DELETE_ONE_ERROR,

    RESERVATION_TYPES_DELETE_MANY,
    RESERVATION_TYPES_DELETE_MANY_SUCCESS,
    RESERVATION_TYPES_DELETE_MANY_ERROR,
} from '../actions';

//GET LIST

export const getReservationTypesList = (payload) => ({
    type: RESERVATION_TYPES_GET_LIST,
    payload
  });
export const getReservationTypesListSuccess = (payload) => ({
    type: RESERVATION_TYPES_GET_LIST_SUCCESS,
    payload
  });
export const getReservationTypesListListError = (payload) => ({
    type: RESERVATION_TYPES_GET_LIST_ERROR,
    payload
  });

  // GET ONE
export const getOneReservationType = (payload) => ({
    type: RESERVATION_TYPE_GET_ONE,
    payload
  });
export const getOneReservationTypeSuccess = (payload) => ({
    type: RESERVATION_TYPE_GET_ONE_SUCCESS,
    payload
  });
export const getOneReservationTypeError = (payload) => ({
    type: RESERVATION_TYPE_GET_ONE_ERROR,
    payload
  });

// ADD ONE
export const addReservationType = (payload) => ({
    type: RESERVATION_TYPE_ADD_ONE,
    payload
  });
export const addReservationTypeSuccess = (payload) => ({
    type: RESERVATION_TYPE_ADD_ONE_SUCCESS,
    payload
  });
export const addReservationTypeError = (payload) => ({
    type: RESERVATION_TYPE_ADD_ONE_ERROR,
    payload
  });

// EDIT ONE
export const editReservationType= (payload) => ({
    type: RESERVATION_TYPE_EDIT_ONE,
    payload
  });
export const editReservationTypeSuccess = (payload) => ({
    type: RESERVATION_TYPE_EDIT_ONE_SUCCESS,
    payload
  });
export const editReservationTypeError = (payload) => ({
    type: RESERVATION_TYPE_EDIT_ONE_ERROR,
    payload
  });

  // DELETE ONE
export const deleteOneReservationType = (payload) => ({
    type: RESERVATION_TYPE_DELETE_ONE,
    payload
  });
export const deleteOneReservationTypeSuccess = (payload) => ({
    type: RESERVATION_TYPE_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneReservationTypeError = (payload) => ({
    type: RESERVATION_TYPE_DELETE_ONE_ERROR,
    payload
  });


// DELETE MANY
export const deleteManyReservationTypes = (payload) => ({
  type: RESERVATION_TYPES_DELETE_MANY,
  payload
});
export const deleteManyReservationTypesSuccess = (payload) => ({
  type: RESERVATION_TYPES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyReservationTypesError = (payload) => ({
  type: RESERVATION_TYPES_DELETE_MANY_ERROR,
  payload
});