import {
    ADMIN_COMMENT_TYPES_GET_LIST,
    ADMIN_COMMENT_TYPES_GET_LIST_SUCCESS,
    ADMIN_COMMENT_TYPES_GET_LIST_ERROR,

    ADMIN_COMMENT_TYPE_GET_ONE,
    ADMIN_COMMENT_TYPE_GET_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_GET_ONE_ERROR,

    ADMIN_COMMENT_TYPE_ADD_ONE,
    ADMIN_COMMENT_TYPE_ADD_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_ADD_ONE_ERROR,

    ADMIN_COMMENT_TYPE_EDIT_ONE,
    ADMIN_COMMENT_TYPE_EDIT_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_EDIT_ONE_ERROR,

    ADMIN_COMMENT_TYPE_DELETE_ONE,
    ADMIN_COMMENT_TYPE_DELETE_ONE_SUCCESS,
    ADMIN_COMMENT_TYPE_DELETE_ONE_ERROR,

    ADMIN_COMMENT_TYPES_DELETE_MANY,
    ADMIN_COMMENT_TYPES_DELETE_MANY_SUCCESS,
    ADMIN_COMMENT_TYPES_DELETE_MANY_ERROR,
  } from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
    admin_comment_types: [],
    count: 0,
    admin_comment_type: null,
    error: '',
  };
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

	switch (action.type) {

          ///GET LIST
    case ADMIN_COMMENT_TYPES_GET_LIST:
        return { ...state, loading: true, error: '', 
        count: 0
      };
    case ADMIN_COMMENT_TYPES_GET_LIST_SUCCESS:
       
        return { ...state, loading: false, 
        admin_comment_types: action.payload.admin_comment_types || [],
        count: action.payload.count 
        };
    case ADMIN_COMMENT_TYPES_GET_LIST_ERROR:
        return { ...state, loading: false, error: action.payload.message };




        // GET ONE
        case ADMIN_COMMENT_TYPE_GET_ONE:
            return { ...state, loading: true, error: ''};
        case ADMIN_COMMENT_TYPE_GET_ONE_SUCCESS:
        
            return { ...state, loading: false, error: '',
            admin_comment_type: action.payload.admin_comment_type
            };
        case ADMIN_COMMENT_TYPE_GET_ONE_ERROR:
            return { ...state, loading: false, error: action.payload.message };





        // ADD ONE
        case ADMIN_COMMENT_TYPE_ADD_ONE:
            return { ...state, loading: true, error: ''};
        case ADMIN_COMMENT_TYPE_ADD_ONE_SUCCESS:    
            NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
            return { ...state, loading: false, 
               admin_comment_types:[action.payload.admin_comment_type, ...state.admin_comment_types]
            };
        case ADMIN_COMMENT_TYPE_ADD_ONE_ERROR:
            NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
            return { ...state, loading: false, error: action.payload.message };



        
        // EDIT ONE
        case ADMIN_COMMENT_TYPE_EDIT_ONE:
        return { ...state, loading: true, error: ''};
      case ADMIN_COMMENT_TYPE_EDIT_ONE_SUCCESS:    
       NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        return { ...state, loading: false, error: '',
        admin_comment_type: action.payload.admin_comment_type
       };
      case ADMIN_COMMENT_TYPE_EDIT_ONE_ERROR:
        
       NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };




        // DELETE ONE
    case ADMIN_COMMENT_TYPE_DELETE_ONE:
        return { ...state, loading: true, error: ''};
      case ADMIN_COMMENT_TYPE_DELETE_ONE_SUCCESS:  
        const admin_comment_types = state.admin_comment_types 
        if(action.payload.index!=null && action.payload.index!=undefined)
        admin_comment_types.splice(action.payload.index, 1)
  
        return { ...state, loading: false, error: '',
        admin_comment_types
        };
      case ADMIN_COMMENT_TYPE_DELETE_ONE_ERROR:
        NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message }


           // DELETE MANY
    case ADMIN_COMMENT_TYPES_DELETE_MANY:
        return { ...state, loading: true, error: '' };
      case ADMIN_COMMENT_TYPES_DELETE_MANY_SUCCESS:
        const admin_comment_types_list = state.admin_comment_types
       
  
        if (action.payload.data.ids.length > 0) {
     
          for (let i = 0; i < action.payload.data.ids.length; i++) {
  
            let index_admin_comment_type = -1
            for (let j = 0; j < admin_comment_types_list.length; j++) {
              if (admin_comment_types_list[j]._id === action.payload.data.ids[i]) {
                index_admin_comment_type = j; break;
              }
            }
            if (index_admin_comment_type > -1) admin_comment_types_list.splice(index_admin_comment_type, 1)
          }
        }
        return {
          ...state, loading: false, error: '',
          admin_comment_types: admin_comment_types_list
        };
      case ADMIN_COMMENT_TYPES_DELETE_MANY_ERROR:
        NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };










        default: return { ...state };
    }}