import { all, call, fork, put, takeEvery } from "redux-saga/effects";

import { api } from "../../constants/defaultValues";
import axios from "axios";

import {
  EXAMSEARCHMODULES_GET_BY_FILTER, EXAMSEARCHMODULE_DELETE_ONE, EXAMSEARCHMODULES_ADD_ONE,
  EXAMSEARCHMODULE_GET_ONE, EXAMSEARCHMODULE_BY_USER, EXAMSEARCHMODULE_EDIT_ONE
,EXAMSEARCHMODULE_PRINT_BY_USER} from "../actions";

import {
  getexamSearchModulesByFilterSuccess,
  getexamSearchModulesByFilterError,

  deleteOneExamSearchModuleSuccess,
  deleteOneExamSearchModuleError,

  addexamSearchModulesSuccess,
  addexamSearchModulesError,

  editOneExamSearchModuleSuccess,
  editOneExamSearchModuleError,

  getOneExamSearchModuleSuccess,
  getOneExamSearchModuleError,

  printExamSearchModuleByUserSuccess,
  printExamSearchModuleByUserError,

  getExamSearchModuleByUserSuccess,
  getExamSearchModuleByUserError,




} from "./actions";

//GET BY FILTER
const getexamSearchModulesByFilterRequest = async (payload) => {
  return await axios.post(api.research_module + `/filters/${payload.offset}/${payload.limit}`, payload.filter, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getexamSearchModulesByFilter({ payload }) {
  try {
    const ret = yield call(getexamSearchModulesByFilterRequest, payload);


    if (ret && ret.status == 200)
      yield put(getexamSearchModulesByFilterSuccess({ research_modules: ret.data.research_modules, count: ret.data.count }));

    else yield put(getexamSearchModulesByFilterError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getexamSearchModulesByFilterError({ error, message: (error) ? error.message : '' }));
  }
}


//GET ONE
const getOneExamSearchModuleRequest = async (payload) => {
  return await axios.get(api.research_module + `/get-one/${payload._id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getOneExamSearchModule({ payload }) {
  try {
    const ret = yield call(getOneExamSearchModuleRequest, payload);

    if (ret && ret.status == 200)
      yield put(getOneExamSearchModuleSuccess({ research_module: ret.data.research_module }));

    else yield put(getOneExamSearchModuleError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getOneExamSearchModuleError({ error, message: (error) ? error.message : '' }));
  }
}


//GET_BY_USER
const getExamSearchModuleByUserRequest = async (payload) => {
 
  return await axios.get(api.research_module+`/user/${payload.id}`, payload.filter,
   { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* getExamSearchModuleByUser({ payload }) {
  try {
    const ret = yield call(getExamSearchModuleByUserRequest, payload);

    if (ret && ret.status == 200)
      yield put(getExamSearchModuleByUserSuccess({ research_modules: ret.data.research_modules }));

    else yield put(getExamSearchModuleByUserError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    yield put(getExamSearchModuleByUserError({ error, message: (error) ? error.message : '' }));
  }
}


//PRINT 
const printExamSearchModuleByUserRequest = async (payload) => {

  return await axios.post(`${api.research_module}/print-user`, payload.filter, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* printExamSearchModuleByUser({ payload }) {
  try {
    const ret = yield call(printExamSearchModuleByUserRequest, payload);
    //console.log('ret',ret);
    

    if (ret && ret.status == 200) {
      const { history } = payload;

      if (history) history.push('/app/exams/exam-search-module')
      yield put(printExamSearchModuleByUserSuccess({ path: ret.data.path }));
    }
    else yield put(printExamSearchModuleByUserError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    //console.log('saga err', error);
    yield put(printExamSearchModuleByUserError({ error, message: (error) ? error.message : '' }));
  }
}


//ADD EXAM MARK NOTES

const addexamSearchModulesRequest = async (payload) => {

  return await axios.post(api.research_module, payload.data, {
    headers: { Authorization: payload.token },

  })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* addexamSearchModules({ payload }) {
  try {
    const ret = yield call(addexamSearchModulesRequest, payload);

    if (ret && ret.status == 201) {
      const { history } = payload;

      if (history) history.push('/app/exams/exam-search-module')
      yield put(addexamSearchModulesSuccess({ research_module: ret.data.research_module }));
    }
    else yield put(addexamSearchModulesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
  
    yield put(addexamSearchModulesError({ error, message: (error) ? error.message : '' }));
  }
}


//EDIT ONE
const editOneExamSearchModuleRequest = async (payload) => {


  return await axios.patch(`${api.research_module}/edit-one/${payload._id}`, payload.data,
    {
      headers: { Authorization: payload.token }

    })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* editOneExamSearchModule({ payload }) {
  try {
    const ret = yield call(editOneExamSearchModuleRequest, payload);

    if (ret && ret.status == 200) {
      const { history } = payload;

      if (history) history.push('/app/exams/exam-search-module')
      yield put(editOneExamSearchModuleSuccess({ research_module: ret.data.research_module }));
    }
    else yield put(editOneExamSearchModuleError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(editOneExamSearchModuleError({ error, message: (error) ? error.message : '' }));
  }
}
// DELETE ONE
const deleteOneExamSearchModuleRequest = async (payload) => {
  return await axios.delete(`${api.research_module}/delete-one/${payload._id}`, { headers: { Authorization: payload.token } })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteOneExamSearchModule({ payload }) {
  try {
    const ret = yield call(deleteOneExamSearchModuleRequest, payload);

    if (ret && ret.status == 202) {
      const { history, index } = payload;

      if (history) history.push('/app/exams/exam-search-module')
      yield put(deleteOneExamSearchModuleSuccess({ index }));
    }
    else yield put(deleteOneExamSearchModuleError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneExamSearchModuleError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS


export function* watchGetFilter() {
  yield takeEvery(EXAMSEARCHMODULES_GET_BY_FILTER, getexamSearchModulesByFilter);
}
export function* watchGetOne() {
  yield takeEvery(EXAMSEARCHMODULE_GET_ONE, getOneExamSearchModule);
}

export function* watchGetByUser() {
  yield takeEvery(EXAMSEARCHMODULE_BY_USER, getExamSearchModuleByUser);
}
export function* watchprintExamSearchModuleByUser() {
  yield takeEvery(EXAMSEARCHMODULE_PRINT_BY_USER, printExamSearchModuleByUser);
}


export function* watchEditOne() {
  yield takeEvery(EXAMSEARCHMODULE_EDIT_ONE, editOneExamSearchModule);
}
export function* watchAddOne() {
  yield takeEvery(EXAMSEARCHMODULES_ADD_ONE, addexamSearchModules);
}
export function* watchDeleteOne() {
  yield takeEvery(EXAMSEARCHMODULE_DELETE_ONE, deleteOneExamSearchModule);
}


export default function* rootSaga() {
  yield all([

    fork(watchGetFilter),
    fork(watchGetOne),

    fork(watchGetByUser),
    fork(watchprintExamSearchModuleByUser),

    fork(watchAddOne),
    fork(watchEditOne),

    fork(watchDeleteOne),




  ]);
}