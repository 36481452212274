import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { TEACHER_GET_LIST,SCHOOL_TEACHER_GET_LIST, TEACHER_GET_LIST_PRIVATE, TEACHER_ADD_ONE, TEACHER_ADD_ONE_PRIVATE, TEACHER_GET_ONE, TEACHER_EDIT_ONE, 
  TEACHER_DELETE_ONE , TEACHER_GET_LIST_BY_FILTER,TEACHERS_DELETE_MANY
} from "../actions";

import {
  getTeachersListSuccess,
  getTeachersListError,

  getSchoolTeachersListSuccess,
  getSchoolTeachersListError,

  getTeachersListByFilterSuccess,
  getTeachersListByFilterError,

  getTeachersListPrivateSuccess,
  getTeachersListPrivateError,

  getOneTeacherSuccess,
  getOneTeacherError,

  addTeacherSuccess,
  addTeacherError,

  addTeacherPrivateSuccess,
  addTeacherPrivateError,

  editTeacherSuccess,
  editTeacherError,
  deleteOneTeacherSuccess,
  deleteOneTeacherError,

  deleteManyTeachersSuccess,
  deleteManyTeachersError,
} from "./actions";


  //GET LIST
const getTeachersListRequest = async (payload) => {
  return await axios.get(api.teachers+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTeachersList({payload}) {
  try {
    const ret = yield call(getTeachersListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getTeachersListSuccess({teachers: ret.data.teachers || [], count: ret.data.count || 0}));
    
    else yield put(getTeachersListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTeachersListError({error, message: (error) ? error.message : ''}));
  }
}
  //GET SCHOOL TEACHERS LIST
const getSchoolTeachersListRequest = async (payload) => {
  return await axios.get(api.teachers+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSchoolTeachersList({payload}) {
  try {
    const ret = yield call(getSchoolTeachersListRequest, payload);
    
    if (ret && ret.status==200)
    yield put(getSchoolTeachersListSuccess({teachers: ret.data.teachers || [], count: ret.data.count || 0}));
    
    else yield put(getSchoolTeachersListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getSchoolTeachersListError({error, message: (error) ? error.message : ''}));
  }
}

 //GET LIST BY FILTER
 const getTeachersListByFilterRequest = async (payload) => {
  return await axios.post(api.teachers+`/filters`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getTeachersListByFilter({payload}) {
  try {
    const ret = yield call(getTeachersListByFilterRequest, payload);
    //console.log("getTeachersListByFilter :",ret);
    
    if (ret && ret.status==200)
    yield put(getTeachersListByFilterSuccess({teachers: ret.data.teachers || [], count: ret.data.count || 0}));
    
    else yield put(getTeachersListByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getTeachersListByFilterError({error, message: (error) ? error.message : ''}));
  }
}


  //GET LIST PRIVATE TEACHERS
  const getTeachersListPrivateRequest = async (payload) => {
    return await axios.get(api.teachers+`/private/list/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getTeachersListPrivate({payload}) {
    try {
      const ret = yield call(getTeachersListPrivateRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getTeachersListPrivateSuccess({
        stacking: payload.stacking,
        teachers: ret.data.teachers || [], count: ret.data.count || 0
      }));
      
      else yield put(getTeachersListPrivateError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getTeachersListPrivateError({error, message: (error) ? error.message : ''}));
    }
  }



//GET ONE
const getOneTeachersRequest = async (payload) => {
  return await axios.get(api.teachers+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneTeacher({payload}) {  
  try {
    const ret = yield call(getOneTeachersRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneTeacherSuccess({teacher: ret.data.teacher}));
    
    else yield put(getOneTeacherError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneTeacherError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addTeacherRequest = async (payload) => {
  return await axios.post(api.teachers, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addTeacher({payload}) {  
  try {
    const ret = yield call(addTeacherRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/schooling/teachers')
      yield put(addTeacherSuccess({teacher: ret.data.teacher}));
    }
    else yield put(addTeacherError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addTeacherError({error, message: (error) ? error.message : ''}));
  }
}


  //ADD ONE PRIVATE
  const addTeacherPrivateRequest = async (payload) => {
    return await axios.post(api.teachers+"/private", payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* addTeacherPrivate({payload}) {  
    try {
      const ret = yield call(addTeacherPrivateRequest, payload);
      
      if (ret && ret.status==201){
        yield put(addTeacherPrivateSuccess({teacher: ret.data.teacher}));
      }
      else yield put(addTeacherPrivateError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addTeacherPrivateError({error, message: (error) ? error.message : ''}));
    }
  }





  //EDIT ONE
const editOneTeacherRequest = async (payload) => {
  return await axios.patch(`${api.teachers}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneTeacher({payload}) {  
  try {
    const ret = yield call(editOneTeacherRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/schooling/teachers')
      yield put(editTeacherSuccess({teacher: ret.data.teacher}));
    }
    else yield put(editTeacherError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editTeacherError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneTeacherRequest = async (payload) => {
  return await axios.delete(`${api.teachers}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneTeacher({payload}) {  
  try {
    const ret = yield call(deleteOneTeacherRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/schooling/teachers')
      yield put(deleteOneTeacherSuccess({index}));
    }
    else yield put(deleteOneTeacherError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneTeacherError({error, message: (error) ? error.message : ''}));
  }
}
//delete many
const deleteManyTeachersRequest = async (payload) => {
  return await axios.delete(`${api.teachers}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyTeachers({ payload }) {
  try {
    const ret = yield call(deleteManyTeachersRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/teachers')
      yield put(deleteManyTeachersSuccess({ data }));
    }
    else yield put(deleteManyTeachersError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyTeachersError({ error, message: (error) ? error.message : '' }));
  }
}

//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(TEACHER_GET_LIST, getTeachersList);
}
export function* watchGetSchoolTeachersList() {
  yield takeEvery(SCHOOL_TEACHER_GET_LIST,   getSchoolTeachersList);
}

export function* watchGetListByFilter() {
  yield takeEvery(TEACHER_GET_LIST_BY_FILTER, getTeachersListByFilter);
}

export function* watchGetListPrivate() {
  yield takeEvery(TEACHER_GET_LIST_PRIVATE, getTeachersListPrivate);
}

export function* watchGetOne() {
  yield takeEvery(TEACHER_GET_ONE, getOneTeacher);
}

export function* watchEditOne() {
  yield takeEvery(TEACHER_EDIT_ONE, editOneTeacher);
}

export function* watchAddOne() {
  yield takeEvery(TEACHER_ADD_ONE, addTeacher);
}

export function* watchAddOnePrivate() {
  yield takeEvery(TEACHER_ADD_ONE_PRIVATE, addTeacherPrivate);
}

export function* watchDeleteOne() {
  yield takeEvery(TEACHER_DELETE_ONE, deleteOneTeacher);
}
export function* watchDeleteMany() {
  yield takeEvery(TEACHERS_DELETE_MANY, deleteManyTeachers);
}
export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetSchoolTeachersList),
    fork(watchGetListByFilter),
    fork(watchGetListPrivate),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchAddOnePrivate),
    fork(watchDeleteOne),
    fork(watchDeleteMany)

  ]);
}
