import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { EXAMNOTES_GET_LIST,EXAMNOTES_GET_ONE,EXAMNOTES_ADD_ONE,EXAMNOTES_GET_BY_FILTER,EXAMNOTES_EDIT_ONE,EXAMNOTES_DELETE_ONE,EXAMNOTES_DELETE_MANY
} from "../actions";
import {
    getExamNotesListSuccess,
    getExamNotesListError,


    getOneExamNotesSuccess,
    getOneExamNotesError,

    getExamNotesByFilterSuccess,
    getExamNotesByFilterError,

    addExamNotesSuccess,
    addExamNotesError,

    editOneExamNotesSuccess,
    editOneExamNotesError,

    deleteOneExamNotesSuccess,
    deleteOneExamNotesError,

    deleteManyExamNotesSuccess,
    deleteManyExamNotesError,
  } from "./actions";

  //GET LIST
const getExamNotesListRequest = async (payload) => {
    return await axios.get(api.exam_notes+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getExamNotesList({payload}) {
    try {
      const ret = yield call(getExamNotesListRequest, payload);
      
      if (ret && ret.status==200)
    
      yield put(getExamNotesListSuccess({exam_notes: ret.data.exam_notes || [], count: ret.data.count || 0}));
 
    
      else yield put(getExamNotesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getExamNotesListError({error, message: (error) ? error.message : ''}));
    }
  }

//GET ONE
const getOneExamNotesRequest = async (payload) => {
    return await axios.get(api.exam_notes+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneExamNotes({payload}) {  
    try {
      const ret = yield call(getOneExamNotesRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneExamNotesSuccess({exam_note: ret.data.exam_note}));
      
      else yield put(getOneExamNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneExamNotesError({error, message: (error) ? error.message : ''}));
    }
  }


//GET EXAM NOTES BY FILTER
const getExamNotesByFilterRequest = async (payload) => {
  return await axios.post(api.exam_notes+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getExamNotesByFilter({payload}) {
  try {
    const ret = yield call(getExamNotesByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getExamNotesByFilterSuccess({exam_notes: ret.data.exam_notes}));
    
    else yield put(getExamNotesByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getExamNotesByFilterError({error, message: (error) ? error.message : ''}));
  }
}

//ADD ONE
const addExamNotesRequest = async (payload) => {
 
    return await axios.post(api.exam_notes,payload.data, { 
      headers: {Authorization: payload.token},
  
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addExamNotes({payload}) {  
    try {
      const ret = yield call(addExamNotesRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exam-notes')
        yield put(addExamNotesSuccess({exam_note: ret.data.exam_note}));
      }
      else yield put(addExamNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addExamNotesError({error, message: (error) ? error.message : ''}));
    }
  }


  //EDIT ONE
const editOneExamNotesRequest = async (payload) => {
  
    return await axios.patch(`${api.exam_notes}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

   })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneExamNotes({payload}) {  
    try {
      const ret = yield call(editOneExamNotesRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exam-notes/list')
        yield put(editOneExamNotesSuccess({exam_note: ret.data.exam_note}));
      }
      else yield put(editOneExamNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneExamNotesError({error, message: (error) ? error.message : ''}));
    }
  }

// DELETE ONE
const deleteOneExamNotesRequest = async (payload) => {
    return await axios.delete(`${api.exam_notes}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneExamNotes({payload}) {  
    try {
      const ret = yield call(deleteOneExamNotesRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/exams/exam-notes')
        yield put(deleteOneExamNotesSuccess({index}));
      }
      else yield put(deleteOneExamNotesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneExamNotesError({error, message: (error) ? error.message : ''}));
    }
  }

//DELETE MANY
const deleteManyExamNotesRequest = async (payload) => {
  return await axios.delete(`${api.exam_notes}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyExamNotes({ payload }) {
  try {
    const ret = yield call(deleteManyExamNotesRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/exams/exam-notes')
      yield put(deleteManyExamNotesSuccess({ data }));
    }
    else yield put(deleteManyExamNotesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyExamNotesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(EXAMNOTES_GET_LIST, getExamNotesList);
  }
  
export function* watchGetOne() {
    yield takeEvery(EXAMNOTES_GET_ONE, getOneExamNotes);
  }
export function* watchGetFilter() {
    yield takeEvery(EXAMNOTES_GET_BY_FILTER, getExamNotesByFilter);
  }
  
export function* watchEditOne() {
    yield takeEvery(EXAMNOTES_EDIT_ONE,editOneExamNotes);
  }
  
export function* watchAddOne() {
    yield takeEvery(EXAMNOTES_ADD_ONE, addExamNotes);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(EXAMNOTES_DELETE_ONE, deleteOneExamNotes);
  }
export function* watchDeleteMany() {
    yield takeEvery(EXAMNOTES_DELETE_MANY,deleteManyExamNotes);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)

    ]);
  }