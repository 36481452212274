import {
    EXEMPTION_GET_LIST,
    EXEMPTION_GET_LIST_SUCCESS,
    EXEMPTION_GET_LIST_ERROR,


    EXEMPTION_GET_ONE,
    EXEMPTION_GET_ONE_SUCCESS,
    EXEMPTION_GET_ONE_ERROR,
   
    EXEMPTION_ADD_ONE,
    EXEMPTION_ADD_ONE_SUCCESS,
    EXEMPTION_ADD_ONE_ERROR,

    EXEMPTION_EDIT_ONE,
    EXEMPTION_EDIT_ONE_SUCCESS,
    EXEMPTION_EDIT_ONE_ERROR,

    EXEMPTION_DELETE_ONE,
    EXEMPTION_DELETE_ONE_SUCCESS,
    EXEMPTION_DELETE_ONE_ERROR,

    EXEMPTIONS_DELETE_MANY,
    EXEMPTIONS_DELETE_MANY_SUCCESS,
    EXEMPTIONS_DELETE_MANY_ERROR
  } from '../actions';


//GET LIST
export const getExemptionsList = (payload) => ({
    type: EXEMPTION_GET_LIST,
    payload
  });
export const getExemptionsListSuccess = (payload) => ({
    type: EXEMPTION_GET_LIST_SUCCESS,
    payload
  });
export const getExemptionsListError = (payload) => ({
    type: EXEMPTION_GET_LIST_ERROR,
    payload
  });
  

// GET ONE
export const getOneExemption = (payload) => ({
    type: EXEMPTION_GET_ONE,
    payload
  });
export const getOneExemptionSuccess = (payload) => ({
    type: EXEMPTION_GET_ONE_SUCCESS,
    payload
  });
export const getOneExemptionError = (payload) => ({
    type: EXEMPTION_GET_ONE_ERROR,
    payload
  });

// ADD ONE
export const addExemption = (payload) => ({
    type: EXEMPTION_ADD_ONE,
    payload
  });
export const addExemptionSuccess = (payload) => ({
    type: EXEMPTION_ADD_ONE_SUCCESS,
    payload
  });
export const addExemptionError = (payload) => ({
    type: EXEMPTION_ADD_ONE_ERROR,
    payload
  });
  

// EDIT ONE
export const editOneExemption = (payload) => ({
    type: EXEMPTION_EDIT_ONE,
    payload
  });
export const editOneExemptionSuccess = (payload) => ({
    type: EXEMPTION_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneExemptionError = (payload) => ({
    type: EXEMPTION_EDIT_ONE_ERROR,
    payload
  });
  

  // DELETE ONE
export const deleteOneExemption = (payload) => ({
    type: EXEMPTION_DELETE_ONE,
    payload
  });
export const deleteOneExemptionSuccess = (payload) => ({
    type: EXEMPTION_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneExemptionError = (payload) => ({
    type: EXEMPTION_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManyExemptions = (payload) => ({
  type: EXEMPTIONS_DELETE_MANY,
  payload
});
export const deleteManyExemptionsSuccess = (payload) => ({
  type: EXEMPTIONS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyExemptionsError = (payload) => ({
  type: EXEMPTIONS_DELETE_MANY_ERROR,
  payload
});