import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { EXAMPERIODS_GET_LIST,EXAMPERIODS_GET_ONE,EXAMPERIODS_GET_BY_FILTER,EXAMPERIODS_ADD_ONE,EXAMPERIODS_EDIT_ONE,EXAMPERIODS_DELETE_ONE,EXAMPERIODS_DELETE_MANY
} from "../actions";
import {
    getExamPeriodsListSuccess,
    getExamPeriodsListError,

    getOneExamPeriodsSuccess,
    getOneExamPeriodsError,
  
    getExamPeriodsByFilterSuccess,
    getExamPeriodsByFilterError,

    addExamPeriodsSuccess,
    addExamPeriodsError,

    editOneExamPeriodsSuccess,
    editOneExamPeriodsError,

    deleteOneExamPeriodsSuccess,
    deleteOneExamPeriodsError,

    deleteManyExamPeriodsSuccess,
    deleteManyExamPeriodsError
  } from "./actions";


//GET LIST
const getExamPeriodsListRequest = async (payload) => {
    return await axios.get(api.exam_periods+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getExamPeriodsList({payload}) {
    try {
      const ret = yield call(getExamPeriodsListRequest, payload);
      
      if (ret && ret.status==200)
    
      yield put(getExamPeriodsListSuccess({exam_periods: ret.data.exam_periods || [], count: ret.data.count || 0}));
 
    
      else yield put(getExamPeriodsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getExamPeriodsListError({error, message: (error) ? error.message : ''}));
    }
  }
  

//GET ONE
const getOneExamPeriodsRequest = async (payload) => {
    return await axios.get(api.exam_periods+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneExamPeriods({payload}) {  
    try {
      const ret = yield call(getOneExamPeriodsRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneExamPeriodsSuccess({exam_period: ret.data.exam_period}));
      
      else yield put(getOneExamPeriodsError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneExamPeriodsError({error, message: (error) ? error.message : ''}));
    }
  }
 
//GET EXAM PERIODS BY FILTER
const getExamPeriodsByFilterRequest = async (payload) => {
  return await axios.post(api.exam_periods+`/filter/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getExamPeriodsByFilter({payload}) {
  try {
    const ret = yield call(getExamPeriodsByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getExamPeriodsByFilterSuccess({exam_periods: ret.data.exam_periods}));
    
    else yield put(getExamPeriodsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getExamPeriodsByFilterError({error, message: (error) ? error.message : ''}));
  }
}

//ADD ONE
const addExamPeriodsRequest = async (payload) => {
 
    return await axios.post(api.exam_periods,payload.data, { 
      headers: {Authorization: payload.token},
  
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addExamPeriods({payload}) {  
    try {
      const ret = yield call(addExamPeriodsRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exam-periods')
        yield put(addExamPeriodsSuccess({exam_period: ret.data.exam_period}));
      }
      else yield put(addExamPeriodsError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addExamPeriodsError({error, message: (error) ? error.message : ''}));
    }
  }

  //EDIT ONE
const editOneExamPeriodsRequest = async (payload) => {
  
    return await axios.patch(`${api.exam_periods}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

   })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneExamPeriods({payload}) {  
    try {
      const ret = yield call(editOneExamPeriodsRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exam-periods')
        yield put(editOneExamPeriodsSuccess({exam_period: ret.data.exam_period}));
      }
      else yield put(editOneExamPeriodsError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneExamPeriodsError({error, message: (error) ? error.message : ''}));
    }
  }

// DELETE ONE
const deleteOneExamPeriodsRequest = async (payload) => {
    return await axios.delete(`${api.exam_periods}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneExamPeriods({payload}) {  
    try {
      const ret = yield call(deleteOneExamPeriodsRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/exams/exam-periods')
        yield put(deleteOneExamPeriodsSuccess({index}));
      }
      else yield put(deleteOneExamPeriodsError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneExamPeriodsError({error, message: (error) ? error.message : ''}));
    }
  }
//DELETE MANY
const deleteManyExamPeriodsRequest = async (payload) => {
  return await axios.delete(`${api.exam_periods}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyExamPeriods({ payload }) {
  try {
    const ret = yield call(deleteManyExamPeriodsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/exams/exam-periods')
      yield put(deleteManyExamPeriodsSuccess({ data }));
    }
    else yield put(deleteManyExamPeriodsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyExamPeriodsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(EXAMPERIODS_GET_LIST, getExamPeriodsList);
  }
  
export function* watchGetOne() {
    yield takeEvery(EXAMPERIODS_GET_ONE, getOneExamPeriods);
  }
export function* watchGetFilter() {
    yield takeEvery(EXAMPERIODS_GET_BY_FILTER, getExamPeriodsByFilter);
  } 
export function* watchEditOne() {
    yield takeEvery(EXAMPERIODS_EDIT_ONE,editOneExamPeriods );
  }
  
export function* watchAddOne() {
    yield takeEvery(EXAMPERIODS_ADD_ONE, addExamPeriods);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(EXAMPERIODS_DELETE_ONE, deleteOneExamPeriods);
  }
export function* watchDeleteMany() {
    yield takeEvery(EXAMPERIODS_DELETE_MANY,deleteManyExamPeriods);
  }
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)

    ]);
  }