import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { api } from "../../constants/defaultValues";
import axios from "axios";
import { EXAMMODULES_GET_LIST,EXAMMODULES_GET_ONE,EXAMMODULES_ADD_ONE,EXAMMODULES_EDIT_ONE,EXAMMODULES_DELETE_ONE,EXAMMODULES_DELETE_MANY
} from "../actions";
import {
    getExamModulesListSuccess,
    getExamModulesListError,

    getOneExamModulesSuccess,
    getOneExamModulesError,

    addExamModulesSuccess,
    addExamModulesError,

    editOneExamModulesSuccess,
    editOneExamModulesError,


    deleteOneExamModulesSuccess,
    deleteOneExamModulesError,

    deleteManyExamModulesSuccess,
    deleteManyExamModulesError,
  } from "./actions";


  //GET LIST
const getExamModulesListRequest = async (payload) => {
    return await axios.get(api.exam_modules+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getExamModulesList({payload}) {
    try {
      const ret = yield call(getExamModulesListRequest, payload);
      
      if (ret && ret.status==200)
    
      yield put(getExamModulesListSuccess({exam_modules: ret.data.exam_modules || [], count: ret.data.count || 0}));
 
    
      else yield put(getExamModulesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getExamModulesListError({error, message: (error) ? error.message : ''}));
    }
  }

//GET ONE
const getOneExamModulesRequest = async (payload) => {
    return await axios.get(api.exam_modules+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneExamModules({payload}) {  
    try {
      const ret = yield call(getOneExamModulesRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneExamModulesSuccess({exam_module: ret.data.exam_module}));
      
      else yield put(getOneExamModulesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneExamModulesError({error, message: (error) ? error.message : ''}));
    }
  }

//ADD ONE
const addExamModulesRequest = async (payload) => {
 
    return await axios.post(api.exam_modules,payload.data, { 
      headers: {Authorization: payload.token},
  
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addExamModules({payload}) {  
    try {
      const ret = yield call(addExamModulesRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exam-modules')
        yield put(addExamModulesSuccess({exam_module: ret.data.exam_module}));
      }
      else yield put(addExamModulesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      //console.log('saga err', error);
      yield put(addExamModulesError({error, message: (error) ? error.message : ''}));
    }
  }


 //EDIT ONE
const editOneExamModulesRequest = async (payload) => {
  
    return await axios.patch(`${api.exam_modules}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

   })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneExamModules({payload}) {  
    try {
      const ret = yield call(editOneExamModulesRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/exams/exam-modules/list')
        yield put(editOneExamModulesSuccess({exam_module: ret.data.exam_module}));
      }
      else yield put(editOneExamModulesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneExamModulesError({error, message: (error) ? error.message : ''}));
    }
  }

// DELETE ONE
const deleteOneExamModulesRequest = async (payload) => {
    return await axios.delete(`${api.exam_modules}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneExamModules({payload}) {  
    try {
      const ret = yield call(deleteOneExamModulesRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/exams/exam-modules')
        yield put(deleteOneExamModulesSuccess({index}));
      }
      else yield put(deleteOneExamModulesError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneExamModulesError({error, message: (error) ? error.message : ''}));
    }
  }
//DELETE MANY
const deleteManyExamModulesRequest = async (payload) => {
  return await axios.delete(`${api.exam_modules}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyExamModules({ payload }) {
  try {
    const ret = yield call(deleteManyExamModulesRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/exams/exam-modules')
      yield put(deleteManyExamModulesSuccess({ data }));
    }
    else yield put(deleteManyExamModulesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyExamModulesError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(EXAMMODULES_GET_LIST, getExamModulesList);
  }
  
export function* watchGetOne() {
    yield takeEvery(EXAMMODULES_GET_ONE, getOneExamModules);
  }
  
  
export function* watchEditOne() {
    yield takeEvery(EXAMMODULES_EDIT_ONE,editOneExamModules);
  }
  
export function* watchAddOne() {
    yield takeEvery(EXAMMODULES_ADD_ONE, addExamModules);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(EXAMMODULES_DELETE_ONE, deleteOneExamModules);
  }
export function* watchDeleteMany() {
    yield takeEvery(EXAMMODULES_DELETE_MANY,deleteManyExamModules);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)
    ]);
  }