import {
  SMS_HISTORY_GET,
  SMS_HISTORY_GET_SUCCESS,
  SMS_HISTORY_GET_ERROR,

  ACCOUNTS_GET_LIST,
  ACCOUNTS_GET_LIST_SUCCESS,
  ACCOUNTS_GET_LIST_ERROR,

  ACCOUNT_GET_ONE,
  ACCOUNT_GET_ONE_SUCCESS,
  ACCOUNT_GET_ONE_ERROR,

  ACCOUNT_ADD_ONE,
  ACCOUNT_ADD_ONE_SUCCESS,
  ACCOUNT_ADD_ONE_ERROR,

  ACCOUNT_EDIT_ONE,
  ACCOUNT_EDIT_ONE_SUCCESS,
  ACCOUNT_EDIT_ONE_ERROR,

  ACCOUNT_DELETE_ONE,
  ACCOUNT_DELETE_ONE_SUCCESS,
  ACCOUNT_DELETE_ONE_ERROR,

  SYNCHRONIZE_ACCOUNT,
  SYNCHRONIZE_ACCOUNT_SUCCESS,
  SYNCHRONIZE_ACCOUNT_ERROR,

  CHECK_ACCOUNT,
  CHECK_ACCOUNT_SUCCESS,
  CHECK_ACCOUNT_ERROR,

  SMS_GET_LIST,
  SMS_GET_LIST_SUCCESS,
  SMS_GET_LIST_ERROR,


  SEND_SMS,
  SEND_SMS_SUCCESS,
  SEND_SMS_ERROR,

} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';

import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  accounts: [],
  sms_list: [],
  history: [],
  account: null,
  count_payed_sms: 0,
  count_sent: 0,
  error: '',
  is_active_account: null,
  default_account: null,
  loading: false,
  loading_get_sms:false
};


export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case SMS_HISTORY_GET:
      return {
        ...state, loading: true, error: ''
      };
    case SMS_HISTORY_GET_SUCCESS:
      return {
        ...state, loading: false,
        history: action.payload.history || []
      };
    case SMS_HISTORY_GET_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // GET ACCOUNTS LIST  

    case ACCOUNTS_GET_LIST:
      return {
        ...state, loading: true, error: ''
      };
    case ACCOUNTS_GET_LIST_SUCCESS:
      return {
        ...state, loading: false,
        accounts: action.payload.accounts || [],
        default_account: action.payload.accounts[0],
        is_active_account: (action.payload.accounts[0].active==1)?true:false,
      };
    case ACCOUNTS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    // CHECK ACCOUNT
    case CHECK_ACCOUNT:
      return { ...state, loading: true, error: '', is_active_account: false };
    case CHECK_ACCOUNT_SUCCESS:

      return {
        ...state, loading: false, error: '',
        is_active_account: (action.payload.account.active==1)?true:false,
        account: action.payload.account,
        default_account: action.payload.account,
      };
    case CHECK_ACCOUNT_ERROR:
      return { ...state, loading: false, error: action.payload.message, is_active_account: false };


    // SYNCHRONIZE ACCOUNT  
    case SYNCHRONIZE_ACCOUNT:
      return { ...state, loading: true, error: '' };
    case SYNCHRONIZE_ACCOUNT_SUCCESS:
      NotificationManager.success("Synchronized", "Success", 3000, null, null, '');
      console.log('action.payload.msgs_count.count_payed_sms', action.payload);

      return {
        ...state, loading: false,
        error: '',
        account: action.payload.account,
        default_account: action.payload.account,
        count_payed_sms: action.payload.msgs_count.count_payed_sms,
        count_sent: action.payload.msgs_count.count_sent,
        sms_list: action.payload.messages
      };
    case SYNCHRONIZE_ACCOUNT_ERROR:
      NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



    // GET ONE
    case ACCOUNT_GET_ONE:
      return { ...state, loading: true, error: '' };
    case ACCOUNT_GET_ONE_SUCCESS:

      return {
        ...state, loading: false, error: '',
        account: { ...action.payload.account_data },
        is_active_account: (action.payload.account_data.active==1)?true:false,
      };
    case ACCOUNT_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };



    // ADD ONE
    case ACCOUNT_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case ACCOUNT_ADD_ONE_SUCCESS:
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return {
        ...state, loading: false,
        accounts: [action.payload.account, ...state.accounts]
      };
    case ACCOUNT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };






    // EDIT ONE
    case ACCOUNT_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case ACCOUNT_EDIT_ONE_SUCCESS:
      NotificationManager.success("Edited", "Success", 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        account: action.payload.account,
        is_active_account: (action.payload.account.active==1)?true:false,
      };
    case ACCOUNT_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // DELETE ONE
    case ACCOUNT_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case ACCOUNT_DELETE_ONE_SUCCESS:
      const accounts = state.accounts
      if (action.payload.index != null && action.payload.index != undefined)
        accounts.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        accounts
      };
    case ACCOUNT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    // GET SMS LIST 

    case SMS_GET_LIST:
      return {
        ...state, loading_get_sms: true, error: ''
      };
    case SMS_GET_LIST_SUCCESS:
      return {
        ...state, loading_get_sms: false,
        sms_list: action.payload.sms_list || []
      };
    case SMS_GET_LIST_ERROR:
      return { ...state, loading_get_sms: false, error: action.payload.message };


    // SEND SMS 

    case SEND_SMS:
      return {
        ...state, loading: true, error: ''
      };
    case SEND_SMS_SUCCESS:
      if (!action.payload.data.status) {
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      }
      else {
        NotificationManager.success( messages["sent"], messages["Success"], 3000, null, null, '');
      }
      return { ...state, loading: false, error: '' };
    case SEND_SMS_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}
