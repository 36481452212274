import {
	TAX_GET_LIST,
	TAX_GET_LIST_SUCCESS,
  TAX_GET_LIST_ERROR,

  TAX_GET_ONE,
	TAX_GET_ONE_SUCCESS,
  TAX_GET_ONE_ERROR,

  TAX_ADD_ONE,
  TAX_ADD_ONE_SUCCESS,
  TAX_ADD_ONE_ERROR,

  TAX_EDIT_ONE,
  TAX_EDIT_ONE_SUCCESS,
  TAX_EDIT_ONE_ERROR,

  TAX_DELETE_ONE,
  TAX_DELETE_ONE_SUCCESS,
  TAX_DELETE_ONE_ERROR,

  TAXES_DELETE_MANY,
  TAXES_DELETE_MANY_SUCCESS,
  TAXES_DELETE_MANY_ERROR,
} from '../../actions';
import { NotificationManager } from '../../../components/common/react-notifications';
import  notificationMessages  from '../../../lang/locales/notificationMsg'
const INIT_STATE = {
	taxes: [],
  count: 0,
  tax: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case TAX_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case TAX_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        taxes: action.payload.taxes || [],
        count: action.payload.count || 0
      };
		case TAX_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };

      // GET ONE
    case TAX_GET_ONE:
      return { ...state, loading: true, error: '', tax: null};
    case TAX_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
        tax: action.payload.tax
      };
    case TAX_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




      // ADD ONE
    case TAX_ADD_ONE:
      //console.log('reducer add one');
      
      return { ...state, loading: true, error: ''};
    case TAX_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, 
        taxes: [action.payload.tax, ...state.taxes]
      };
    case TAX_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case TAX_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case TAX_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Edited"],  messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
      tax: action.payload.tax
      };
    case TAX_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case TAX_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case TAX_DELETE_ONE_SUCCESS:  
      const taxes = state.taxes 
      if(action.payload.index!=null && action.payload.index!=undefined)
      taxes.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        taxes
      };
    case TAX_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
      
      // DELETE MANY
      case TAXES_DELETE_MANY:
        return { ...state, loading: true, error: '' };
      case TAXES_DELETE_MANY_SUCCESS:
        const taxes_list = state.taxes
      

        if (action.payload.data.ids.length > 0) {

          for (let i = 0; i < action.payload.data.ids.length; i++) {

            let index_tax = -1
            for (let j = 0; j < taxes_list.length; j++) {
              if (taxes_list[j]._id === action.payload.data.ids[i]) {
                index_tax = j; break;
              }
            }
            if (index_tax > -1) taxes_list.splice(index_tax, 1)
          }
        }
        return {
          ...state, loading: false, error: '',
          taxes: taxes_list
        };
      case TAXES_DELETE_MANY_ERROR:
        NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };


    
		default: return { ...state };
	}
}
