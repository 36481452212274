import { all } from 'redux-saga/effects';
import authSagas from './auth/saga';
import institutionSagas from './institution/saga';
import SchoolYearsSagas from './school-years/saga';
import PeriodTypesSagas from './period-types/saga';
import EducationLevelsSagas from './education-levels/saga';
import SchoolLevelsSagas from './school-levels/saga';
import DiplomasSagas from './diplomas/saga';
import SpecialitiesSagas from './specialities/saga';
import SubjectsSagas from './subjects/saga';
import PartnersSagas from './partners/saga';
import ContactsSagas from './contacts/saga';
import TeachersSagas from './teachers/saga';
import StudentsSagas from './students/saga';
import ParentsSagas from './parents/saga';
import SkillsSagas from './skills/saga';
import ClassesSagas from './classes/saga';
import ModulesSagas from './modules/saga';
import RoomsSagas from './rooms/saga';
import TimetablesSagas from './timetables/saga';
import HomeworksSagas from './homeworks/saga';
import SanctionsSagas from './sanctions/saga';
import SanctionTypesSagas from './sanction-types/saga';
import Exemptions from './exemptions/saga';
import Absences from './absences/saga';
import Sessions from './sessions/saga';

import ExamTypes from './exam-types/saga';
import ExamPeriods from './exam-periods/saga';
import ExamSessions from './exam-sessions/saga';
import ExamNotes from './exam-notes/saga';
import ExamModules from './exam-modules/saga';
import ExamResults from './exam-results/saga';
import ExamMarkNotes from './exam-mark-notes/saga';
import AdminCommentTypes from'./admin-comment-types/saga'
import AdminComments from './admin-comments/saga'
import TeacherComments from './teacher-comments/saga';
import ResearchModules from './exam-search-modules/saga';

/*billing*/
import TaxesSagas from './billing.redux/taxes/saga';
import FeesSagas from './billing.redux/fees/saga';
import FeeCompositionsSagas from './billing.redux/fee-compositions/saga';
import FeeTypesSagas from './billing.redux/fee-types/saga';
import PaymentsSagas from './billing.redux/payments/saga';
import BillsSagas from './billing.redux/bills/saga';

/*appointements*/
import Appointements from './appointements/saga';
import ParentsSchool from './parents-school/saga';
import Sms from './sms/saga'
import ContactMessage from './contact-msg/saga'

/*cantine*/
import MealTypes from './meal-types/saga';
import Meals from './meals/saga';
import CanteenMenus from './canteen-menus/saga';
import ReservationTypes from './reservation-types/saga';
import CanteenReservations from './canteen-reservations/saga';

import Posts from './posts/saga';
import Events from './events/saga';
import Invitations from './invitations/saga';
import Jobs from './jobs/saga';
import Trainings from './trainings/saga';
import Courses from './courses/saga';
import ConfigSubjects from './configs/subjects/saga';
import ConfigLevels from './configs/education-levels/saga';
import Tutorings from "./tutorings/saga";
import Groups from "./groups/saga";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    institutionSagas(),
    SchoolYearsSagas(),
    PeriodTypesSagas(),
    EducationLevelsSagas(),
    SchoolLevelsSagas(),
    DiplomasSagas(),
    SpecialitiesSagas(),
    SubjectsSagas(),
    PartnersSagas(),
    ContactsSagas(),
    TeachersSagas(),
    StudentsSagas(),
    ParentsSagas(),
    SkillsSagas(),
    ClassesSagas(),
    ModulesSagas(),
    RoomsSagas(),
    TimetablesSagas(),
    HomeworksSagas(),
    SanctionsSagas(),
    SanctionTypesSagas(),
    Exemptions(),
    Absences(),
    Sessions(),
    ExamTypes(),
    ExamPeriods(),
    ExamSessions(),
    ExamNotes(),
    ExamModules(),
    ExamResults(),
    ExamMarkNotes(),
    AdminCommentTypes(),
    AdminComments(),
    TeacherComments(),
    ResearchModules(),
    /*billing*/
    TaxesSagas(),
    FeesSagas(),
    FeeCompositionsSagas(),
    FeeTypesSagas(),
    PaymentsSagas(),
    BillsSagas(),
    /*community*/
    Appointements(),
    ParentsSchool(),
    Sms(),
    ContactMessage(),
    /*cantine*/
    MealTypes(),
    Meals(),
    CanteenMenus(),
    ReservationTypes(),
    CanteenReservations(),

    Posts(),
    Events(),
    Invitations(),
    Jobs(),
    Trainings(),
    Courses(),
    ConfigSubjects(),
    ConfigLevels(),
    Tutorings(),
    Groups(),
  ]);
}
