import {
    CANTEEN_RESERVATIONS_GET_LIST,
    CANTEEN_RESERVATIONS_GET_LIST_SUCCESS,
    CANTEEN_RESERVATIONS_GET_LIST_ERROR,

    CANTEEN_RESERVATIONS_GET_BY_DATE,
    CANTEEN_RESERVATIONS_GET_BY_DATE_SUCCESS,
    CANTEEN_RESERVATIONS_GET_BY_DATE_ERROR,

    CANTEEN_RESERVATION_GET_ONE,
    CANTEEN_RESERVATION_GET_ONE_SUCCESS,
    CANTEEN_RESERVATION_GET_ONE_ERROR,

    CANTEEN_RESERVATIONS_GET_BY_FILTER,
    CANTEEN_RESERVATIONS_GET_BY_FILTER_SUCCESS,
    CANTEEN_RESERVATIONS_GET_BY_FILTER_ERROR,
    
    CANTEEN_RESERVATION_ADD_ONE,
    CANTEEN_RESERVATION_ADD_ONE_SUCCESS,
    CANTEEN_RESERVATION_ADD_ONE_ERROR,

    CANTEEN_RESERVATION_EDIT_ONE,
    CANTEEN_RESERVATION_EDIT_ONE_SUCCESS,
    CANTEEN_RESERVATION_EDIT_ONE_ERROR,

    CANTEEN_RESERVATION_DELETE_ONE,
    CANTEEN_RESERVATION_DELETE_ONE_SUCCESS,
    CANTEEN_RESERVATION_DELETE_ONE_ERROR,

    CANTEEN_RESERVATIONS_DELETE_MANY,
    CANTEEN_RESERVATIONS_DELETE_MANY_SUCCESS,
    CANTEEN_RESERVATIONS_DELETE_MANY_ERROR,
  } from '../actions';

    // GET LIST
export const getCanteenReservationsList = (payload) => ({
        type: CANTEEN_RESERVATIONS_GET_LIST,
        payload
      });
export const getCanteenReservationsListSuccess = (payload) => ({
        type: CANTEEN_RESERVATIONS_GET_LIST_SUCCESS,
        payload
      });
 export const getCanteenReservationsListError = (payload) => ({
        type: CANTEEN_RESERVATIONS_GET_LIST_ERROR,
        payload
      });

      //GET COUNT BY DATE
export const getCanteenReservationsBydate= (payload) => ({
        type: CANTEEN_RESERVATIONS_GET_BY_DATE,
        payload
      });
export const getCanteenReservationsBydateSuccess = (payload) => ({
        type: CANTEEN_RESERVATIONS_GET_BY_DATE_SUCCESS,
        payload
      });
 export const getCanteenReservationsBydateError = (payload) => ({
        type: CANTEEN_RESERVATIONS_GET_BY_DATE_ERROR,
        payload
      });



      
  // GET ONE
export const getOneCanteenReservation = (payload) => ({
    type: CANTEEN_RESERVATION_GET_ONE,
    payload
  });
export const getOneCanteenReservationSuccess = (payload) => ({
    type: CANTEEN_RESERVATION_GET_ONE_SUCCESS,
    payload
  });
export const getOneCanteenReservationError = (payload) => ({
    type: CANTEEN_RESERVATION_GET_ONE_ERROR,
    payload
  });


  // GET CANTEEN RESERVATIONS BY FILTER

export const getCanteenReservationsByFilter = (payload) => ({
  type: CANTEEN_RESERVATIONS_GET_BY_FILTER,
  payload
});
export const getCanteenReservationsByFilterSuccess = (payload) => ({
  type: CANTEEN_RESERVATIONS_GET_BY_FILTER_SUCCESS,
  payload
});

export const getCanteenReservationsByFilterError = (payload) => ({

  type: CANTEEN_RESERVATIONS_GET_BY_FILTER_ERROR,
  payload
});
  // ADD ONE
export const addCanteenReservation = (payload) => ({
    type: CANTEEN_RESERVATION_ADD_ONE,
    payload
  });
export const addCanteenReservationSuccess = (payload) => ({
    type: CANTEEN_RESERVATION_ADD_ONE_SUCCESS,
    payload
  });
export const addCanteenReservationError = (payload) => ({
    type: CANTEEN_RESERVATION_ADD_ONE_ERROR,
    payload
  });
  
  // EDIT ONE
export const editCanteenReservation = (payload) => ({
    type: CANTEEN_RESERVATION_EDIT_ONE,
    payload
  });
export const editCanteenReservationSuccess = (payload) => ({
    type: CANTEEN_RESERVATION_EDIT_ONE_SUCCESS,
    payload
  });
export const editCanteenReservationError = (payload) => ({
    type: CANTEEN_RESERVATION_EDIT_ONE_ERROR,
    payload
  });

  // DELETE ONE
export const deleteCanteenReservation = (payload) => ({
    type: CANTEEN_RESERVATION_DELETE_ONE,
    payload
  });
export const deleteCanteenReservationSuccess = (payload) => ({
    type: CANTEEN_RESERVATION_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteCanteenReservationError = (payload) => ({
    type: CANTEEN_RESERVATION_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManyCanteenReservations = (payload) => ({
  type: CANTEEN_RESERVATIONS_DELETE_MANY,
  payload
});
export const deleteManyCanteenReservationsSuccess = (payload) => ({
  type: CANTEEN_RESERVATIONS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyCanteenReservationsError = (payload) => ({
  type: CANTEEN_RESERVATIONS_DELETE_MANY_ERROR,
  payload
});