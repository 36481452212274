import {
  POST_GET_LIST,
  POST_GET_LIST_SUCCESS,
  POST_GET_LIST_ERROR,

  POST_GET_ONE,
  POST_GET_ONE_SUCCESS,
  POST_GET_ONE_ERROR,


  POST_GET_BY_PARTNER,
  POST_GET_BY_PARTNER_SUCCESS,
  POST_GET_BY_PARTNER_ERROR,


  POST_GET_BY_FILTER,
  POST_GET_BY_FILTER_SUCCESS,
  POST_GET_BY_FILTER_ERROR,

  POST_ADD_ONE,
  POST_ADD_ONE_SUCCESS,
  POST_ADD_ONE_ERROR,

  POST_EDIT_ONE,
  POST_EDIT_ONE_SUCCESS,
  POST_EDIT_ONE_ERROR,

  POST_DELETE_ONE,
  POST_DELETE_ONE_SUCCESS,
  POST_DELETE_ONE_ERROR,

  POST_LIKE,
  POST_LIKE_SUCCESS,
  POST_LIKE_ERROR,

  POST_COMMENT,
  POST_COMMENT_SUCCESS,
  POST_COMMENT_ERROR,

  DELETE_COMMENT,
  DELETE_COMMENT_SUCCESS,
  DELETE_COMMENT_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';

const INIT_STATE = {
  posts: [],
  filtered_posts: [],
  loading: false,
  load_posts: false,
  comment_added: false,
  count: 0,
  posts_filter_count: 0,
  post: null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
  switch (action.type) {
    case POST_GET_LIST:
      return {
        ...state, load_posts: true, error: '',
        count: 0
      };
    case POST_GET_LIST_SUCCESS:
      const old = state.posts
      return {
        ...state, load_posts: false,
        posts: action.payload.reset ? [...action.payload.posts] : [...old, ...action.payload.posts || []],
        count: action.payload.count || 0
      };
    case POST_GET_LIST_ERROR:
      return { ...state, load_posts: false, error: action.payload.message };






    // GET ONE
    case POST_GET_ONE:
      return { ...state, loading: true, error: '' };
    case POST_GET_ONE_SUCCESS:
      return {
        ...state, loading: false, error: '',
        post: action.payload.post
      };
    case POST_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };




    // GET BY PARTNER
    case POST_GET_BY_PARTNER:

      return { ...state, loading: true, error: '' };
    case POST_GET_BY_PARTNER_SUCCESS:

      return {
        ...state, loading: false, error: '',
        posts: action.payload.posts || [],
      };
    case POST_GET_BY_PARTNER_ERROR:

      return { ...state, loading: false, error: action.payload.message };


    // GET BY FILTER
    case POST_GET_BY_FILTER:

      return { ...state, loading: true, error: '' };
    case POST_GET_BY_FILTER_SUCCESS:
      let filtered_posts = state.filtered_posts;
      return {
        ...state, loading: false, error: '',
        filtered_posts: [...filtered_posts, ...action.payload.posts || []],
        posts_filter_count: action.payload.count
      };
    case POST_GET_BY_FILTER_ERROR:
      return { ...state, loading: false, error: action.payload.message };









    // ADD ONE
    case POST_ADD_ONE:
      return { ...state, loading: true, error: '' };
    case POST_ADD_ONE_SUCCESS:
      // NotificationManager.success("Added", "Success",3000, null, null, '');
      return {
        ...state, loading: false,
        posts: JSON.parse(JSON.stringify([action.payload.post, ...state.posts]))
      };
    case POST_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    // ADD LIKE
    case POST_LIKE:
      return { ...state, error: '' };
    case POST_LIKE_SUCCESS:

      const old__posts = state.posts;

      old__posts[old__posts.findIndex(element => element._id === action.payload.post._id)].likes = action.payload.post.likes;
      old__posts[old__posts.findIndex(element => element._id === action.payload.post._id)].is_liked = action.payload.post.is_liked;

      return {
        ...state, loading: false, error: '',
        posts: JSON.parse(JSON.stringify(old__posts))
      };
    case POST_LIKE_ERROR:
      // NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: '' };


    // ADD COMMENT
    case POST_COMMENT:
      return { ...state, error: '', loading: true, comment_added: false, };
    case POST_COMMENT_SUCCESS:
      const old_posts = state.posts;

      old_posts[old_posts.findIndex(element => element._id === action.payload.post._id)].comments = action.payload.post.comments;
      return {
        ...state, loading: false, error: '',
        comment_added: true, loading: false,
        posts: JSON.parse(JSON.stringify(old_posts))
      };
    case POST_COMMENT_ERROR:
      return { ...state, loading: false, error: '', loading: false, comment_added: false, };




    // DELETE COMMENT
    case DELETE_COMMENT:
      return { ...state, error: '', loading: true, };
    case DELETE_COMMENT_SUCCESS:
      const old_post_index = state.posts.findIndex(element => element._id === action.payload.post_id);
      const old_post = state.posts[old_post_index];
      state.posts[old_post_index].comments.splice(old_post.comments.findIndex(element => element._id === action.payload.comment_id), 1);
      return {
        ...state, loading: false, error: '',
        posts: JSON.parse(JSON.stringify(state.posts))

      };
    case DELETE_COMMENT_ERROR:
      // NotificationManager.warning(action.payload.message || "Unkown Error", "Error", 3000, null, null, '');
      return { ...state, loading: false, error: '' };




    // EDIT ONE
    case POST_EDIT_ONE:
      return { ...state, loading: true, error: '' };
    case POST_EDIT_ONE_SUCCESS:
      NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return {
        ...state, loading: false, error: '',
        post: action.payload.post
      };
    case POST_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };




    // DELETE ONE
    case POST_DELETE_ONE:
      return { ...state, loading: true, error: '' };
    case POST_DELETE_ONE_SUCCESS:
      const posts = state.posts
      if (action.payload.index != null && action.payload.index != undefined)
        posts.splice(action.payload.index, 1)

      return {
        ...state, loading: false, error: '',
        posts
      };
    case POST_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


    default: return { ...state };
  }
}
