import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { ABSENCE_GET_LIST,ABSENCE_GET_ONE,ABSENCE_GET_BY_FILTER,ABSENCE_ADD_ONE,ABSENCE_EDIT_ONE,ABSENCE_DELETE_ONE,ABSENCES_DELETE_MANY
} from "../actions";

import {
    getAbsencesListSuccess,
    getAbsencesListError,

    getOneAbsenceSuccess,
    getOneAbsenceError,

    getAbsencesByFilterSuccess,
    getAbsencesByFilterError,

    addAbsenceSuccess,
    addAbsenceError,


    editOneAbsenceSuccess,
    editOneAbsenceError,

    deleteOneAbsenceSuccess,
    deleteOneAbsenceError,

    deleteManyAbsencesSuccess,
    deleteManyAbsencesError,
  } from "./actions";



 //GET LIST
 const getAbsencesListRequest = async (payload) => {
    return await axios.get(api.absences+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getAbsencesList({payload}) {
    try {
      const ret = yield call(getAbsencesListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getAbsencesListSuccess({absences: ret.data.absences || [], count: ret.data.count || 0}));
      
      else yield put(getAbsencesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getAbsencesListError({error, message: (error) ? error.message : ''}));
    }
  }
  

 //GET ONE
 const getOneAbsenceRequest = async (payload) => {
    return await axios.get(api.absences+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneAbsence({payload}) {  
    try {
      const ret = yield call(getOneAbsenceRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneAbsenceSuccess({absence: ret.data.absence}));
      
      else yield put(getOneAbsenceError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneAbsenceError({error, message: (error) ? error.message : ''}));
    }
  }
  
//GET ABSENCES BY FILTER
const getAbsencesByFilterRequest = async (payload) => {
  return await axios.post(api.absences+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getAbsencesByFilter({payload}) {
  try {
    const ret = yield call(getAbsencesByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getAbsencesByFilterSuccess({absences: ret.data.absences , count:ret.data.count}));
    
    else yield put(getAbsencesByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getAbsencesByFilterError({error, message: (error) ? error.message : ''}));
  }
}

//ADD ONE
const addAbsenceRequest = async (payload) => {
    let frm = new FormData();
    
    
    for (let key in payload.data) {
    
       frm.append(key,payload.data[key])
    }
  
    return await axios.post(api.absences,payload.data, { 
      headers: {Authorization: payload.token},
     // 'content-type': 'multipart/form-data' 
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* addAbsence({payload}) {  
    try {
      const ret = yield call(addAbsenceRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/absences/list')
        yield put(addAbsenceSuccess({absence: ret.data.absence}));
      }
      else yield put(addAbsenceError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addAbsenceError({error, message: (error) ? error.message : ''}));
    }
  }
  
  
  //EDIT ONE
const editOneAbsenceRequest = async (payload) => {
  
    return await axios.patch(`${api.absences}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token}

   })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneAbsence({payload}) {  
    try {
      const ret = yield call(editOneAbsenceRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/absences/list')
        yield put(editOneAbsenceSuccess({absence: ret.data.absence}));
      }
      else yield put(editOneAbsenceError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneAbsenceError({error, message: (error) ? error.message : ''}));
    }
  }
  
 // DELETE ONE
const deleteOneAbsenceRequest = async (payload) => {
    return await axios.delete(`${api.absences}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneAbsence({payload}) {  
    try {
      const ret = yield call(deleteOneAbsenceRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/schooling/absences')
        yield put(deleteOneAbsenceSuccess({index}));
      }
      else yield put(deleteOneAbsenceError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneAbsenceError({error, message: (error) ? error.message : ''}));
    }
  }
 //delete many 
  const deleteManyAbsencesRequest = async (payload) => {
    return await axios.delete(`${api.absences}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
  function* deleteManyAbsences({ payload }) {
    try {
      const ret = yield call(deleteManyAbsencesRequest, payload);
  
      if (ret && ret.status == 202) {
        const { history, data } = payload;
  
        if (history) history.push('/app/schooling/absences')
        yield put(deleteManyAbsencesSuccess({ data }));
      }
      else yield put(deleteManyAbsencesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteManyAbsencesError({ error, message: (error) ? error.message : '' }));
    }
  } 
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(ABSENCE_GET_LIST, getAbsencesList);
  }
  
export function* watchGetOne() {
    yield takeEvery(ABSENCE_GET_ONE, getOneAbsence);
  }

export function* watchGetFilter() {
    yield takeEvery(ABSENCE_GET_BY_FILTER, getAbsencesByFilter);
  }
  
  
export function* watchEditOne() {
    yield takeEvery(ABSENCE_EDIT_ONE,editOneAbsence );
  }
  
export function* watchAddOne() {
    yield takeEvery(ABSENCE_ADD_ONE, addAbsence);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(ABSENCE_DELETE_ONE, deleteOneAbsence);
  }
export function* watchDeleteMany() {
    yield takeEvery(ABSENCES_DELETE_MANY,deleteManyAbsences);
  }  
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)

    ]);
  }
  