import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { EXEMPTION_GET_LIST,EXEMPTION_GET_ONE,EXEMPTION_ADD_ONE,EXEMPTION_EDIT_ONE,EXEMPTION_DELETE_ONE,EXEMPTIONS_DELETE_MANY
} from "../actions";
import {
    getExemptionsListSuccess,
    getExemptionsListError,

    getOneExemptionSuccess,
    getOneExemptionError,

    addExemptionSuccess,
    addExemptionError,

    editOneExemptionSuccess,
    editOneExemptionError,

    deleteOneExemptionSuccess,
    deleteOneExemptionError,

    deleteManyExemptionsSuccess,
    deleteManyExemptionsError,
  } from "./actions";


 //GET LIST
 const getExemptionsListRequest = async (payload) => {
    return await axios.get(api.exemptions+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getExemptionsList({payload}) {
    try {
      const ret = yield call(getExemptionsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getExemptionsListSuccess({exemptions: ret.data.exemptions || [], count: ret.data.count || 0}));
      
      else yield put(getExemptionsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getExemptionsListError({error, message: (error) ? error.message : ''}));
    }
  }
  

  //GET ONE
const getOneExemptionRequest = async (payload) => {
    return await axios.get(api.exemptions+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* getOneExemption({payload}) {  
    try {
      const ret = yield call(getOneExemptionRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneExemptionSuccess({exemption: ret.data.exemption}));
      
      else yield put(getOneExemptionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneExemptionError({error, message: (error) ? error.message : ''}));
    }
  }
  


//ADD ONE
const addExemptionRequest = async (payload) => {
    let frm = new FormData();
    
    
    for (let key in payload.data) {
    
       frm.append(key,payload.data[key])
    }
  
    return await axios.post(api.exemptions, frm, { 
      headers: {Authorization: payload.token},
      'content-type': 'multipart/form-data' 
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* addExemption({payload}) {  
    try {
      const ret = yield call(addExemptionRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/exemptions')
        yield put(addExemptionSuccess({exemption: ret.data.exemption}));
      }
      else yield put(addExemptionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addExemptionError({error, message: (error) ? error.message : ''}));
    }
  }
  
  
  
  
  
    //EDIT ONE
  const editOneExemptionRequest = async (payload) => {
    let frm = new FormData();
    for (let key in payload.data) {
    
      if(payload.data.attachments && key == 'attachments' )
   
      for (let i = 0; i < payload.data.attachments.length ; i++) {
  
      
        frm.append('attachments',payload.data.attachments[i])
        
      }
      if(key != 'attachments')
      frm.append(key,payload.data[key])
   }
    return await axios.patch(`${api.exemptions}/edit-one/${payload._id}`, frm, { headers: {Authorization: payload.token},'content-type': 'multipart/form-data'  })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* editOneExemption({payload}) {  
    try {
      const ret = yield call(editOneExemptionRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/exemptions')
        yield put(editOneExemptionSuccess({exemption: ret.data.exemption}));
      }
      else yield put(editOneExemptionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneExemptionError({error, message: (error) ? error.message : ''}));
    }
  }
  


  // DELETE ONE
  const deleteExemptionRequest = async (payload) => {
    return await axios.delete(`${api.exemptions}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* deleteOneExemption({payload}) {  
    try {
      const ret = yield call(deleteExemptionRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/schooling/exemptions')
        yield put(deleteOneExemptionSuccess({index}));
      }
      else yield put(deleteOneExemptionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneExemptionError({error, message: (error) ? error.message : ''}));
    }
  }
  
 //delete many 
 const deleteManyExemptionsRequest = async (payload) => {
  return await axios.delete(`${api.exemptions}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManyExemptions({ payload }) {
  try {
    const ret = yield call(deleteManyExemptionsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/exemptions')
      yield put(deleteManyExemptionsSuccess({ data }));
    }
    else yield put(deleteManyExemptionsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManyExemptionsError({ error, message: (error) ? error.message : '' }));
  }
} 
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(EXEMPTION_GET_LIST, getExemptionsList);
  }
  
  export function* watchGetOne() {
    yield takeEvery(EXEMPTION_GET_ONE, getOneExemption);
  }
  
  export function* watchEditOne() {
    yield takeEvery(EXEMPTION_EDIT_ONE, editOneExemption);
  }
  
  export function* watchAddOne() {
    yield takeEvery(EXEMPTION_ADD_ONE, addExemption);
  }
  
  export function* watchDeleteOne() {
    yield takeEvery(EXEMPTION_DELETE_ONE, deleteOneExemption);
  }
  export function* watchDeleteMany() {
    yield takeEvery(EXEMPTIONS_DELETE_MANY,deleteManyExemptions);
  } 
  export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)

    ]);
  }
  
  
