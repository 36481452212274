import {
    APPOINTEMENTS_GET_LIST,
    APPOINTEMENTS_GET_LIST_SUCCESS,
    APPOINTEMENTS_GET_LIST_ERROR,

    APPOINTEMENT_GET_ONE,
    APPOINTEMENT_GET_ONE_SUCCESS,
    APPOINTEMENT_GET_ONE_ERROR,

    APPOINTEMENTS_GET_BY_FILTER,
    APPOINTEMENTS_GET_BY_FILTER_SUCCESS,
    APPOINTEMENTS_GET_BY_FILTER_ERROR, 

    APPOINTEMENT_ADD_ONE,
    APPOINTEMENT_ADD_ONE_SUCCESS,
    APPOINTEMENT_ADD_ONE_ERROR,

    APPOINTEMENT_EDIT_ONE,
    APPOINTEMENT_EDIT_ONE_SUCCESS,
    APPOINTEMENT_EDIT_ONE_ERROR,

    APPOINTEMENT_DELETE_ONE,
    APPOINTEMENT_DELETE_ONE_SUCCESS,
    APPOINTEMENT_DELETE_ONE_ERROR,

    APPOINTEMENTS_DELETE_MANY,
    APPOINTEMENTS_DELETE_MANY_SUCCESS,
    APPOINTEMENTS_DELETE_MANY_ERROR,

  } from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

 
const INIT_STATE = {
    appointements: [],
    count: 0,
    appointement: null,
    error: '',
  };
export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()

	switch (action.type) {
    
        //GET LIST
	case APPOINTEMENTS_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case APPOINTEMENTS_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        appointements: action.payload.appointements || [],
        count: action.payload.count || 0
      };
	case APPOINTEMENTS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



     // GET ONE
     case APPOINTEMENT_GET_ONE:
     return { ...state, loading: true, error: '', class: null};
    case APPOINTEMENT_GET_ONE_SUCCESS:
     return { ...state, loading: false, error: '',
     appointement: action.payload.appointement
     };
    case APPOINTEMENT_GET_ONE_ERROR:
     return { ...state, loading: false, error: action.payload.message };

   

    // GET BY FILTER
    case APPOINTEMENTS_GET_BY_FILTER:
              
    return { ...state, loading: true, error: ''};
    case APPOINTEMENTS_GET_BY_FILTER_SUCCESS:
          
      return { ...state, loading: false, error: '',
      appointements: action.payload.appointements || [],
      count: action.payload.count || 0
        };
    case APPOINTEMENTS_GET_BY_FILTER_ERROR:
          
    return { ...state, loading: false, error: action.payload.message };

        // ADD ONE
    case APPOINTEMENT_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case APPOINTEMENT_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        appointements:[action.payload.appointement, ...state.appointements]
      };
    case APPOINTEMENT_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };


     // EDIT ONE
    case APPOINTEMENT_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case APPOINTEMENT_EDIT_ONE_SUCCESS:    
     NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
      return { ...state, loading: false, error: '',
     appointement: action.payload.appointement
     };
    case APPOINTEMENT_EDIT_ONE_ERROR:
      
     NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };



      // DELETE ONE
    case APPOINTEMENT_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case APPOINTEMENT_DELETE_ONE_SUCCESS:  
      const appointements = state.appointements 
      if(action.payload.index!=null && action.payload.index!=undefined)
      appointements.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
      appointements
      };
    case APPOINTEMENT_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    
        // DELETE MANY
    case APPOINTEMENTS_DELETE_MANY:
      return { ...state, loading: true, error: '' };
    case APPOINTEMENTS_DELETE_MANY_SUCCESS:
      const appointements_list = state.appointements
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_appointement = -1
          for (let j = 0; j < appointements_list.length; j++) {
            if (appointements_list[j]._id === action.payload.data.ids[i]) {
              index_appointement = j; break;
            }
          }
          if (index_appointement > -1) appointements_list.splice(index_appointement, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        appointements: appointements_list
      };
    case APPOINTEMENTS_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };
    
	default: return { ...state };
    }
}