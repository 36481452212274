import {
    EXAMRESULTS_GET_LIST,
    EXAMRESULTS_GET_LIST_SUCCESS,
    EXAMRESULTS_GET_LIST_ERROR,
 
    EXAMRESULTS_GET_ONE,
    EXAMRESULTS_GET_ONE_SUCCESS,
    EXAMRESULTS_GET_ONE_ERROR,

    EXAMRESULTS_ADD_ONE,
    EXAMRESULTS_ADD_ONE_SUCCESS,
    EXAMRESULTS_ADD_ONE_ERROR,

    EXAMRESULTS_EDIT_ONE,
    EXAMRESULTS_EDIT_ONE_SUCCESS,
    EXAMRESULTS_EDIT_ONE_ERROR,

    EXAMRESULTS_DELETE_ONE,
    EXAMRESULTS_DELETE_ONE_SUCCESS,
    EXAMRESULTS_DELETE_ONE_ERROR,

    EXAMRESULTS_DELETE_MANY,
    EXAMRESULTS_DELETE_MANY_SUCCESS,
    EXAMRESULTS_DELETE_MANY_ERROR
  } from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'


const INIT_STATE = {
        exam_results: [],
        count: 0,
        exam_result: null,
        error: '',
  };

  export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
    switch (action.type) {
      //GET LIST
      case EXAMRESULTS_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
      };
      case EXAMRESULTS_GET_LIST_SUCCESS:

      return { ...state, loading: false, 
      exam_results: action.payload.exam_results ,
      count: action.payload.count || 0
        
      };
      case EXAMRESULTS_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };


          // GET ONE
        case EXAMRESULTS_GET_ONE:
        return { ...state, loading: true, error: ''};
        case EXAMRESULTS_GET_ONE_SUCCESS:
        return { ...state, loading: false, error: '',
        exam_result: action.payload.exam_result
        };
        case EXAMRESULTS_GET_ONE_ERROR:
        return { ...state, loading: false, error: action.payload.message };


                 // ADD ONE
        case EXAMRESULTS_ADD_ONE:
        return { ...state, loading: true, error: ''};
        case EXAMRESULTS_ADD_ONE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
              exam_results: [action.payload.exam_result, ...state.exam_results]
        };
        case EXAMRESULTS_ADD_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };


         // EDIT ONE
        case EXAMRESULTS_EDIT_ONE:
        return { ...state, loading: true,edit_sucess:false, error: ''};
        case EXAMRESULTS_EDIT_ONE_SUCCESS:    
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        return { ...state, loading: false,edit_sucess:true, error: '',
        exam_result: action.payload.exam_result
        };
        case EXAMRESULTS_EDIT_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false,edit_sucess:false, error: action.payload.message };
      
        
          // DELETE ONE
        case EXAMRESULTS_DELETE_ONE:
        return { ...state, loading: true, error: ''};
        case EXAMRESULTS_DELETE_ONE_SUCCESS:  
        const exam_results = state.exam_results
        if(action.payload.index!=null && action.payload.index!=undefined)
        exam_results.splice(action.payload.index, 1)
        
            return { ...state, loading: false, error: '',
            exam_results
                };
        case EXAMRESULTS_DELETE_ONE_ERROR:
          NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
        
           // DELETE MANY
        case EXAMRESULTS_DELETE_MANY:
          return { ...state, loading: true, error: '' };
        case EXAMRESULTS_DELETE_MANY_SUCCESS:
          const exam_results_list = state.exam_results
        

          if (action.payload.data.ids.length > 0) {
      
            for (let i = 0; i < action.payload.data.ids.length; i++) {

              let index_exam_result = -1
              for (let j = 0; j < exam_results_list.length; j++) {
                if (exam_results_list[j]._id === action.payload.data.ids[i]) {
                  index_exam_result = j; break;
                }
              }
              if (index_exam_result > -1) exam_results_list.splice(index_exam_result, 1)
            }
          }
          return {
            ...state, loading: false, error: '',
            exam_results: exam_results_list
          };
        case EXAMRESULTS_DELETE_MANY_ERROR:
          NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          return { ...state, loading: false, error: action.payload.message };  
        default: return { ...state };
    
    }}
