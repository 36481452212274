import {
    FEE_TYPE_GET_LIST,
    FEE_TYPE_GET_LIST_SUCCESS,
    FEE_TYPE_GET_LIST_ERROR,
  
    FEE_TYPE_GET_ONE,
    FEE_TYPE_GET_ONE_SUCCESS,
    FEE_TYPE_GET_ONE_ERROR,
  
    FEE_TYPE_ADD_ONE,
    FEE_TYPE_ADD_ONE_SUCCESS,
    FEE_TYPE_ADD_ONE_ERROR,
  
    FEE_TYPE_EDIT_ONE,
    FEE_TYPE_EDIT_ONE_SUCCESS,
    FEE_TYPE_EDIT_ONE_ERROR,
    
    FEE_TYPE_DELETE_ONE,
    FEE_TYPE_DELETE_ONE_SUCCESS,
    FEE_TYPE_DELETE_ONE_ERROR
  } from '../../actions';
  
    // GET LIST
  export const getFeeTypesList = (payload) => ({
    type: FEE_TYPE_GET_LIST,
    payload
  });
  export const getFeeTypesListSuccess = (payload) => ({
    type: FEE_TYPE_GET_LIST_SUCCESS,
    payload
  });
  export const getFeeTypesListError = (payload) => ({
    type: FEE_TYPE_GET_LIST_ERROR,
    payload
  });
  
  
  
  
    // GET ONE
  export const getOneFeeType = (payload) => ({
    type: FEE_TYPE_GET_ONE,
    payload
  });
  export const getOneFeeTypeSuccess = (payload) => ({
    type: FEE_TYPE_GET_ONE_SUCCESS,
    payload
  });
  export const getOneFeeTypeError = (payload) => ({
    type: FEE_TYPE_GET_ONE_ERROR,
    payload
  });
  
  
  
    // ADD ONE
  export const addFeeType = (payload) => ({
    type: FEE_TYPE_ADD_ONE,
    payload
  });
  export const addFeeTypeSuccess = (payload) => ({
    type: FEE_TYPE_ADD_ONE_SUCCESS,
    payload
  });
  export const addFeeTypeError = (payload) => ({
    type: FEE_TYPE_ADD_ONE_ERROR,
    payload
  });
  
  
  
  
    // EDIT ONE
  export const editFeeType = (payload) => ({
    type: FEE_TYPE_EDIT_ONE,
    payload
  });
  export const editFeeTypeSuccess = (payload) => ({
    type: FEE_TYPE_EDIT_ONE_SUCCESS,
    payload
  });
  export const editFeeTypeError = (payload) => ({
    type: FEE_TYPE_EDIT_ONE_ERROR,
    payload
  });
  
  
  
  
    // DELETE ONE
  export const deleteOneFeeType = (payload) => ({
    type: FEE_TYPE_DELETE_ONE,
    payload
  });
  export const deleteOneFeeTypeSuccess = (payload) => ({
    type: FEE_TYPE_DELETE_ONE_SUCCESS,
    payload
  });
  export const deleteOneFeeTypeError = (payload) => ({
    type: FEE_TYPE_DELETE_ONE_ERROR,
    payload
  });