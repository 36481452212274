import {
  EXAMMARKSNOTES_GET_BY_FILTERS,
  EXAMMARKSNOTES_GET_BY_FILTERS_SUCCESS,
  EXAMMARKSNOTES_GET_BY_FILTERS_ERROR,

  EXAMMARKSNOTES_GET_BY_FILTER,
  EXAMMARKSNOTES_GET_BY_FILTER_SUCCESS,
  EXAMMARKSNOTES_GET_BY_FILTER_ERROR,

    EXAMMARKSNOTES_ADD_ONE,
    EXAMMARKSNOTES_ADD_ONE_SUCCESS,
    EXAMMARKSNOTES_ADD_ONE_ERROR,

    EXAMMARKSNOTES_EDIT_ONE,
    EXAMMARKSNOTES_EDIT_ONE_SUCCESS,
    EXAMMARKSNOTES_EDIT_ONE_ERROR,


    EXAMMARKSNOTES_DELETE_ONE,
    EXAMMARKSNOTES_DELETE_ONE_SUCCESS,
    EXAMMARKSNOTES_DELETE_ONE_ERROR,

    EXAMMARKSNOTES_GET_ONE,
    EXAMMARKSNOTES_GET_ONE_SUCCESS,
    EXAMMARKSNOTES_GET_ONE_ERROR,
  } from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'
const INIT_STATE = {
    exam_mark_notes: [],
      count: 0,
      exam_mark_note: null,
      error: '',
};
export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
      switch (action.type) {
        
   

         // GET BY FILTERS
         case EXAMMARKSNOTES_GET_BY_FILTERS:
              
            return { ...state, loading: true, error: ''};
            case EXAMMARKSNOTES_GET_BY_FILTERS_SUCCESS:
              
            return { ...state, loading: false, error: '',
            exam_mark_notes: action.payload.exam_mark_notes || [], count: action.payload.count || 0
            };
            case EXAMMARKSNOTES_GET_BY_FILTERS_ERROR:
              
            return { ...state, loading: false, error: action.payload.message };

          //GET BY FILTER

            case EXAMMARKSNOTES_GET_BY_FILTER:
              
              return { ...state, loading: true, error: ''};
              case EXAMMARKSNOTES_GET_BY_FILTER_SUCCESS:
                
              return { ...state, loading: false, error: '',
              exam_mark_notes: action.payload.exam_mark_notes || [], count: action.payload.count || 0
              };
              case EXAMMARKSNOTES_GET_BY_FILTER_ERROR:
                
              return { ...state, loading: false, error: action.payload.message };


             // GET ONE
          case EXAMMARKSNOTES_GET_ONE:
          return { ...state, loading: true, error: ''};
          case EXAMMARKSNOTES_GET_ONE_SUCCESS:
          return { ...state, loading: false, error: '',
          exam_mark_note: action.payload.exam_mark_note
          };
          case EXAMMARKSNOTES_GET_ONE_ERROR:
          return { ...state, loading: false, error: action.payload.message };

          // ADD ONE
          case EXAMMARKSNOTES_ADD_ONE:
            return { ...state, loading: true, error: ''};
            case EXAMMARKSNOTES_ADD_ONE_SUCCESS:    
            NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
            return { ...state, loading: false, 
              exam_mark_notes: [action.payload.exam_mark_note, ...state.exam_mark_notes]
            };
            case EXAMMARKSNOTES_ADD_ONE_ERROR:
              NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
            return { ...state, loading: false, error: action.payload.message };

          // EDIT ONE
          case EXAMMARKSNOTES_EDIT_ONE:
            return { ...state, loading: true,edit_sucess:false, error: ''};
            case EXAMMARKSNOTES_EDIT_ONE_SUCCESS:    
            NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
            return { ...state, loading: false,edit_sucess:true, error: '',
            exam_mark_note: action.payload.exam_mark_note
            };
            case EXAMMARKSNOTES_EDIT_ONE_ERROR:
              NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
            return { ...state, loading: false,edit_sucess:false, error: action.payload.message };

           // DELETE ONE
        case EXAMMARKSNOTES_DELETE_ONE:
          return { ...state, loading: true, error: ''};
          case EXAMMARKSNOTES_DELETE_ONE_SUCCESS:  
          const exam_mark_notes = state.exam_mark_notes
          if(action.payload.index!=null && action.payload.index!=undefined)
          exam_mark_notes.splice(action.payload.index, 1)
        
              return { ...state, loading: false, error: '',
               
                };
          case EXAMMARKSNOTES_DELETE_ONE_ERROR:
            NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
          return { ...state, loading: false, error: action.payload.message };


            default: return { ...state };
    }
}