import {
    ABSENCE_GET_LIST,
    ABSENCE_GET_LIST_SUCCESS,
    ABSENCE_GET_LIST_ERROR,
 
    ABSENCE_GET_ONE,
    ABSENCE_GET_ONE_SUCCESS,
    ABSENCE_GET_ONE_ERROR,

    ABSENCE_GET_BY_FILTER,
    ABSENCE_GET_BY_FILTER_SUCCESS,
    ABSENCE_GET_BY_FILTER_ERROR,

    ABSENCE_ADD_ONE,
    ABSENCE_ADD_ONE_SUCCESS,
    ABSENCE_ADD_ONE_ERROR,

    ABSENCE_EDIT_ONE,
    ABSENCE_EDIT_ONE_SUCCESS,
    ABSENCE_EDIT_ONE_ERROR,

    ABSENCE_DELETE_ONE,
    ABSENCE_DELETE_ONE_SUCCESS,
    ABSENCE_DELETE_ONE_ERROR,

    ABSENCES_DELETE_MANY,
   ABSENCES_DELETE_MANY_SUCCESS,
   ABSENCES_DELETE_MANY_ERROR
  } from '../actions';


//GET LIST
export const getAbsencesList = (payload) => ({
    type: ABSENCE_GET_LIST,
    payload
  });
export const getAbsencesListSuccess = (payload) => ({
    type: ABSENCE_GET_LIST_SUCCESS,
    payload
  });
export const getAbsencesListError = (payload) => ({
    type: ABSENCE_GET_LIST_ERROR,
    payload
  });

  
// GET ONE
export const getOneAbsence = (payload) => ({
    type: ABSENCE_GET_ONE,
    payload
  });
export const getOneAbsenceSuccess = (payload) => ({
    type: ABSENCE_GET_ONE_SUCCESS,
    payload
  });
export const getOneAbsenceError = (payload) => ({
    type: ABSENCE_GET_ONE_ERROR,
    payload
  });

// GET ABSENCES NOTE BY FILTER

export const getAbsencesByFilter = (payload) => ({
  type: ABSENCE_GET_BY_FILTER,
  payload
});
export const getAbsencesByFilterSuccess = (payload) => ({
  type: ABSENCE_GET_BY_FILTER_SUCCESS,
  payload
});
export const getAbsencesByFilterError = (payload) => ({
  type: ABSENCE_GET_BY_FILTER_ERROR,
  payload
});



// ADD ONE
export const addAbsence = (payload) => ({
    type: ABSENCE_ADD_ONE,
    payload
  });
export const addAbsenceSuccess = (payload) => ({
    type: ABSENCE_ADD_ONE_SUCCESS,
    payload
  });
export const addAbsenceError = (payload) => ({
    type: ABSENCE_ADD_ONE_ERROR,
    payload
  });
  

// EDIT ONE
export const editOneAbsence = (payload) => ({
    type: ABSENCE_EDIT_ONE,
    payload
  });
export const editOneAbsenceSuccess = (payload) => ({
    type: ABSENCE_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneAbsenceError = (payload) => ({
    type: ABSENCE_EDIT_ONE_ERROR,
    payload
  });

  // DELETE ONE
export const deleteOneAbsence = (payload) => ({
    type: ABSENCE_DELETE_ONE,
    payload
  });
export const deleteOneAbsenceSuccess = (payload) => ({
    type: ABSENCE_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneAbsenceError = (payload) => ({
    type: ABSENCE_DELETE_ONE_ERROR,
    payload
  });



// DELETE MANY
export const deleteManyAbsences = (payload) => ({
  type: ABSENCES_DELETE_MANY,
  payload
});
export const deleteManyAbsencesSuccess = (payload) => ({
  type: ABSENCES_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyAbsencesError = (payload) => ({
  type: ABSENCES_DELETE_MANY_ERROR,
  payload
});
