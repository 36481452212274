import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { SANCTION_GET_LIST, SANCTION_GET_ONE, SANCTION_ADD_ONE,SANCTIONS_GET_BY_FILTER,SANCTIONS_DELETE_MANY,
    SANCTION_EDIT_ONE,SANCTION_DELETE_ONE
} from "../actions";

import {
    getSanctionsListSuccess,
    getSanctionsListError,

    getOneSanctionSuccess,
    getOneSanctionError,
    
    getSanctionsByFilterSuccess,
    getSanctionsByFilterError,

    addSanctionSuccess,
    addSanctionError,

    editOneSanctionSuccess,
    editOneSanctionError,


    deleteOneSanctionSuccess,
    deleteOneSanctionError,

    deleteManySanctionsSuccess,
    deleteManySanctionsError
} from "./actions";



//GET LIST
const getSanctionsListRequest = async (payload) => {
    return await axios.get(api.sanctions+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
    };
function* getSanctionsList({payload}) {
    try {
      const ret = yield call(getSanctionsListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getSanctionsListSuccess({sanctions: ret.data.sanctions || [], count: ret.data.count || 0}));
      
      else yield put(getSanctionsListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getSanctionsListError({error, message: (error) ? error.message : ''}));
    }
  }


//GET ONE
const getOneSanctionRequest = async (payload) => {
    return await axios.get(api.sanctions+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneSanction({payload}) {  
    try {
      const ret = yield call(getOneSanctionRequest, payload);
  
      if (ret && ret.status==200)
      yield put(getOneSanctionSuccess({sanction: ret.data.sanction}));
      
      else yield put(getOneSanctionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getOneSanctionError({error, message: (error) ? error.message : ''}));
    }
  }
  
//GET SANCTIONS BY FILTER
const getSanctionsByFilterRequest = async (payload) => {
  return await axios.post(api.sanctions+`/filters/${payload.offset}/${payload.limit}`,payload.filter, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSanctionsByFilter({payload}) {
  try {
    const ret = yield call(getSanctionsByFilterRequest, payload);
    

    if (ret && ret.status==200)
    yield put(getSanctionsByFilterSuccess({sanctions: ret.data.sanctions , count:ret.data.count}));
    
    else yield put(getSanctionsByFilterError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getSanctionsByFilterError({error, message: (error) ? error.message : ''}));
  }
}
  //ADD ONE
const addSanctionRequest = async (payload) => {
    let frm = new FormData();
    
    for (let key in payload.data) {
    
       if(payload.data.attachment && key == 'attachment' )
      
        frm.append('attachment',payload.data.attachment)
         
       if(key != 'attachment')
       frm.append(key,payload.data[key])
    }
   
  
    return await axios.post(api.sanctions, frm, { 
      headers: {Authorization: payload.token},
      'content-type': 'multipart/form-data' 
    })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addSanction({payload}) {  
    try {
      const ret = yield call(addSanctionRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/sanctions')
        yield put(addSanctionSuccess({sanction: ret.data.sanction}));
      }
      else yield put(addSanctionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addSanctionError({error, message: (error) ? error.message : ''}));
    }
  }


    //EDIT ONE
const editSanctionRequest = async (payload) => {
    let frm = new FormData();
    
    for (let key in payload.data) {
       
      
      frm.append(key,payload.data[key])
   }
    return await axios.patch(`${api.sanctions}/edit-one/${payload._id}`, frm, { headers: {Authorization: payload.token},'content-type': 'multipart/form-data'  })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* editOneSanction({payload}) {  
    try {
      const ret = yield call(editSanctionRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/schooling/sanctions')
        yield put(editOneSanctionSuccess({sanction: ret.data.sanction}));
      }
      else yield put(editOneSanctionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneSanctionError({error, message: (error) ? error.message : ''}));
    }
  }


// DELETE ONE
const deleteOneSanctionRequest = async (payload) => {
    return await axios.delete(`${api.sanctions}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
  function* deleteOneSanction({payload}) {  
    try {
      const ret = yield call(deleteOneSanctionRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/schooling/sanctions')
        yield put(deleteOneSanctionSuccess({index}));
      }
      else yield put(deleteOneSanctionError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneSanctionError({error, message: (error) ? error.message : ''}));
    }
  }
  

//delete many
const deleteManySanctionsRequest = async (payload) => {
  return await axios.delete(`${api.sanctions}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
    .then(resp => resp)
    .catch(error => { return (error && error.response) ? error.response : error })
};
function* deleteManySanctions({ payload }) {
  try {
    const ret = yield call(deleteManySanctionsRequest, payload);

    if (ret && ret.status == 202) {
      const { history, data } = payload;

      if (history) history.push('/app/schooling/sanctions')
      yield put(deleteManySanctionsSuccess({ data }));
    }
    else yield put(deleteManySanctionsError({ message: (ret.data) ? ret.data.msg : "Network Error" }))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteManySanctionsError({ error, message: (error) ? error.message : '' }));
  }
}
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(SANCTION_GET_LIST, getSanctionsList);
  }
  
export function* watchGetOne() {
    yield takeEvery(SANCTION_GET_ONE, getOneSanction);
  }
export function* watchGetFilter() {
    yield takeEvery(SANCTIONS_GET_BY_FILTER, getSanctionsByFilter);
  }

export function* watchEditOne() {
    yield takeEvery(SANCTION_EDIT_ONE, editOneSanction);
  }
  
export function* watchAddOne() {
    yield takeEvery(SANCTION_ADD_ONE, addSanction);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(SANCTION_DELETE_ONE, deleteOneSanction);
  }
export function* watchDeleteMany() {
    yield takeEvery(SANCTIONS_DELETE_MANY,deleteManySanctions);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchGetFilter),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)

    ]);
  }
  