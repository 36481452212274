import {
  HOMEWORK_GET_LIST,
	HOMEWORK_GET_LIST_SUCCESS,
  HOMEWORK_GET_LIST_ERROR,

  HOMEWORK_GET_ONE,
	HOMEWORK_GET_ONE_SUCCESS,
  HOMEWORK_GET_ONE_ERROR,

  HOMEWORK_ADD_ONE,
  HOMEWORK_ADD_ONE_SUCCESS,
  HOMEWORK_ADD_ONE_ERROR,

  HOMEWORK_EDIT_ONE,
  HOMEWORK_EDIT_ONE_SUCCESS,
  HOMEWORK_EDIT_ONE_ERROR,

  HOMEWORK_DELETE_ONE,
  HOMEWORK_DELETE_ONE_SUCCESS,
  HOMEWORK_DELETE_ONE_ERROR,

  HOMEWORKS_GET_BY_FILTER,
  HOMEWORKS_GET_BY_FILTER_SUCCESS,
  HOMEWORKS_GET_BY_FILTER_ERROR,

  HOMEWORKS_DELETE_MANY,
  HOMEWORKS_DELETE_MANY_SUCCESS,
  HOMEWORKS_DELETE_MANY_ERROR
} from '../actions';

  // GET LIST
export const getHomeworksList = (payload) => ({
  type: HOMEWORK_GET_LIST,
  payload
});
export const getHomeworksListSuccess = (payload) => ({
  type: HOMEWORK_GET_LIST_SUCCESS,
  payload
});
export const getHomeworksListError = (payload) => ({
  type: HOMEWORK_GET_LIST_ERROR,
  payload
});







  // GET LIST
export const getOneHomework = (payload) => ({
  type: HOMEWORK_GET_ONE,
  payload
});
export const getOneHomeworkSuccess = (payload) => ({
  type: HOMEWORK_GET_ONE_SUCCESS,
  payload
});
export const getOneHomeworkError = (payload) => ({
  type: HOMEWORK_GET_ONE_ERROR,
  payload
});



// GET Homework BY FILTER

export const getHomeworksByFilter = (payload) => ({
  type: HOMEWORKS_GET_BY_FILTER,
  payload
});
export const getHomeworksByFilterSuccess = (payload) => ({
  type: HOMEWORKS_GET_BY_FILTER_SUCCESS,
  payload
});
export const getHomeworksByFilterError = (payload) => ({
  type: HOMEWORKS_GET_BY_FILTER_ERROR,
  payload
});




  // ADD ONE
export const addHomework = (payload) => ({
  type: HOMEWORK_ADD_ONE,
  payload
});
export const addHomeworkSuccess = (payload) => ({
  type: HOMEWORK_ADD_ONE_SUCCESS,
  payload
});
export const addHomeworkError = (payload) => ({
  type: HOMEWORK_ADD_ONE_ERROR,
  payload
});




  // EDIT ONE
export const editHomework = (payload) => ({
  type: HOMEWORK_EDIT_ONE,
  payload
});
export const editHomeworkSuccess = (payload) => ({
  type: HOMEWORK_EDIT_ONE_SUCCESS,
  payload
});
export const editHomeworkError = (payload) => ({
  type: HOMEWORK_EDIT_ONE_ERROR,
  payload
});




  // DELETE ONE
export const deleteOneHomework = (payload) => ({
  type: HOMEWORK_DELETE_ONE,
  payload
});
export const deleteOneHomeworkSuccess = (payload) => ({
  type: HOMEWORK_DELETE_ONE_SUCCESS,
  payload
});
export const deleteOneHomeworkError = (payload) => ({
  type: HOMEWORK_DELETE_ONE_ERROR,
  payload
});

// DELETE MANY
export const deleteManyHomeworks = (payload) => ({
  type: HOMEWORKS_DELETE_MANY,
  payload
});
export const deleteManyHomeworksSuccess = (payload) => ({
  type: HOMEWORKS_DELETE_MANY_SUCCESS,
  payload
});
export const deleteManyHomeworksError = (payload) => ({
  type: HOMEWORKS_DELETE_MANY_ERROR,
  payload
});