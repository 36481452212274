
import {
    TEACHER_COMMENTS_GET_LIST,
    TEACHER_COMMENTS_GET_LIST_SUCCESS,
    TEACHER_COMMENTS_GET_LIST_ERROR,


    TEACHER_COMMENTS_GET_BY_FILTER,
    TEACHER_COMMENTS_GET_BY_FILTER_SUCCESS,
    TEACHER_COMMENTS_GET_BY_FILTER_ERROR,

    TEACHER_COMMENT_GET_ONE,
    TEACHER_COMMENT_GET_ONE_SUCCESS,
    TEACHER_COMMENT_GET_ONE_ERROR,

    TEACHER_COMMENT_ADD_ONE,
    TEACHER_COMMENT_ADD_ONE_SUCCESS,
    TEACHER_COMMENT_ADD_ONE_ERROR,

    TEACHER_COMMENT_EDIT_ONE,
    TEACHER_COMMENT_EDIT_ONE_SUCCESS,
    TEACHER_COMMENT_EDIT_ONE_ERROR,

    TEACHER_COMMENT_DELETE_ONE,
    TEACHER_COMMENT_DELETE_ONE_SUCCESS,
    TEACHER_COMMENT_DELETE_ONE_ERROR,
   
    TEACHER_COMMENTS_DELETE_MANY,
    TEACHER_COMMENTS_DELETE_MANY_SUCCESS,
    TEACHER_COMMENTS_DELETE_MANY_ERROR,
  } from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
  teacher_comments: [],
  count: 0,
  teacher_comment: null,
  error: '',
}
export default (state = INIT_STATE, action) => {
    const messages = notificationMessages()
      switch (action.type) {

        //GET LIST
        case TEACHER_COMMENTS_GET_LIST:
            return { ...state, loading: true, error: '', 
            count: 0
          };
          case TEACHER_COMMENTS_GET_LIST_SUCCESS:
            //cons
            return { ...state, loading: false, 
             teacher_comments: action.payload.teacher_comments || [],
              count: action.payload.count || 0
            };
          case TEACHER_COMMENTS_GET_LIST_ERROR:
            return { ...state, loading: false, error: action.payload.message };
      


                  // GET BY FILTER
         case TEACHER_COMMENTS_GET_BY_FILTER:
              
          return { ...state, loading: true, error: ''};
          case TEACHER_COMMENTS_GET_BY_FILTER_SUCCESS:
            
          return { ...state, loading: false, error: '',
          teacher_comments: action.payload.teacher_comments || [],count: action.payload.count || 0
          };
          case TEACHER_COMMENTS_GET_BY_FILTER_ERROR:
            
          return { ...state, loading: false, error: action.payload.message }; 

      // GET ONE
        case TEACHER_COMMENT_GET_ONE:
          return { ...state, loading: true, error: ''};
        case TEACHER_COMMENT_GET_ONE_SUCCESS:
          return { ...state, loading: false, error: '',
          teacher_comment: action.payload.teacher_comment
        };
        case TEACHER_COMMENT_GET_ONE_ERROR:
          return { ...state, loading: false, error: action.payload.message };


      

      // ADD ONE
      case TEACHER_COMMENT_ADD_ONE:
        return { ...state, loading: true, error: ''};
      case TEACHER_COMMENT_ADD_ONE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
            teacher_comments: [action.payload.teacher_comment, ...state.teacher_comments]
        };
      case TEACHER_COMMENT_ADD_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
  

    
      // EDIT ONE
      case TEACHER_COMMENT_EDIT_ONE:
        return { ...state, loading: true, error: ''};
      case TEACHER_COMMENT_EDIT_ONE_SUCCESS:    
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        return { ...state, loading: false, error: '',
        teacher_comment: action.payload.teacher_comment
        };
      case TEACHER_COMMENT_EDIT_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };

    

        
      // DELETE ONE
      case TEACHER_COMMENT_DELETE_ONE:
        return { ...state, loading: true, error: ''};
      case TEACHER_COMMENT_DELETE_ONE_SUCCESS:  
        const teacher_comments = state.teacher_comments 
        if(action.payload.index!=null && action.payload.index!=undefined)
        teacher_comments.splice(action.payload.index, 1)
  
        return { ...state, loading: false, error: '',
        teacher_comments
        };
      case TEACHER_COMMENT_DELETE_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };




          // DELETE MANY
        case TEACHER_COMMENTS_DELETE_MANY:
          return { ...state, loading: true, error: '' };
        case TEACHER_COMMENTS_DELETE_MANY_SUCCESS:
        const teacher_comments_list = state.teacher_comments
       
  
        if (action.payload.data.ids.length > 0) {
     
          for (let i = 0; i < action.payload.data.ids.length; i++) {
  
            let index_teacher_comment = -1
            for (let j = 0; j < teacher_comments_list.length; j++) {
              if (teacher_comments_list[j]._id === action.payload.data.ids[i]) {
                index_teacher_comment = j; break;
              }
            }
            if (index_teacher_comment > -1) teacher_comments_list.splice(index_teacher_comment, 1)
          }
        }
        return {
          ...state, loading: false, error: '',
          teacher_comments: teacher_comments_list
        };
      case TEACHER_COMMENTS_DELETE_MANY_ERROR:
        NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };


        default: return { ...state };
      }}