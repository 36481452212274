import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { 
    ADMIN_COMMENT_TYPES_GET_LIST,ADMIN_COMMENT_TYPE_GET_ONE,ADMIN_COMMENT_TYPE_ADD_ONE,ADMIN_COMMENT_TYPE_EDIT_ONE,ADMIN_COMMENT_TYPE_DELETE_ONE,
    ADMIN_COMMENT_TYPES_DELETE_MANY
} from "../actions";

import {
    getAdminCommentTypesListSuccess,
    getAdminCommentTypesListError,

    getOneAdminCommentTypeSuccess,
    getOneAdminCommentTypeError,

    addAdminCommentTypeSuccess,
    addAdminCommentTypeError,

    editOneAdminCommentTypeSuccess,
    editOneAdminCommentTypeError,

    deleteOneAdminCommentTypeSuccess,
    deleteOneAdminCommentTypeError,

    deleteManyAdminCommentTypesSuccess,
    deleteManyAdminCommentTypesError
} from "./actions";

 //GET LIST
 const getAdminCommentTypesListRequest = async (payload) => {
    return await axios.get(api.admin_comment_types+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getAdminCommentTypesList({payload}) {
    try {
      const ret = yield call(getAdminCommentTypesListRequest, payload);
      
      if (ret && ret.status==200)
      yield put(getAdminCommentTypesListSuccess({admin_comment_types: ret.data.admin_comment_types || [], count: ret.data.count}));
      
      else yield put(getAdminCommentTypesListError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      yield put(getAdminCommentTypesListError({error, message: (error) ? error.message : ''}));
    }
  }
//GET ONE
const getOneAdminCommentTypeRequest = async (payload) => {
    return await axios.get(api.admin_comment_types+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* getOneAdminCommentType({payload}) {
    try {
      const ret = yield call(getOneAdminCommentTypeRequest, payload);
  
      if (ret && ret.status==200) yield put(getOneAdminCommentTypeSuccess({admin_comment_type: ret.data.admin_comment_type}));
   
      else yield put(getOneAdminCommentTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(getOneAdminCommentTypeError({error, message: (error) ? error.message : ''}));
    }
  }
//ADD ONE
const addAdminCommentTypeRequest = async (payload) => {
    return await axios.post(api.admin_comment_types, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* addAdminCommentType({payload}) {  
    try {
      const ret = yield call(addAdminCommentTypeRequest, payload);
      
      if (ret && ret.status==201){
        const { history } = payload;
  
        if(history) history.push('/app/exams/admin-comment-types')
        yield put(addAdminCommentTypeSuccess({admin_comment_type: ret.data.admin_comment_type}));
      }
      else yield put(addAdminCommentTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(addAdminCommentTypeError({error, message: (error) ? error.message : ''}));
    }
  }

//EDIT ONE
const editOneAdminCommentTypeRequest = async (payload) => {
    return await axios.patch(`${api.admin_comment_types}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* editOneAdminCommentType({payload}) {  
    try {
      const ret = yield call(editOneAdminCommentTypeRequest, payload);
      
      if (ret && ret.status==200){
        const { history } = payload;
  
        if(history) history.push('/app/exams/admin-comment-types')
        yield put(editOneAdminCommentTypeSuccess({admin_comment_type: ret.data.admin_comment_type}));
      }
      else yield put(editOneAdminCommentTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(editOneAdminCommentTypeError({error, message: (error) ? error.message : ''}));
    }
  }

 // DELETE ONE
 const deleteOneAdminCommentTypeRequest = async (payload) => {
    return await axios.delete(`${api.admin_comment_types}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
    .then(resp=>resp)
    .catch(error=>{return (error && error.response) ? error.response : error})
  };
function* deleteOneAdminCommentType({payload}) {  
    try {
      const ret = yield call(deleteOneAdminCommentTypeRequest, payload);
      
      if (ret && ret.status==202){
        const { history, index } = payload;
  
        if(history) history.push('/app/exams/admin-comment-types')
        yield put(deleteOneAdminCommentTypeSuccess({index}));
      }
      else yield put(deleteOneAdminCommentTypeError({message: (ret.data) ? ret.data.msg : "Network Error"}))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteOneAdminCommentTypeError({error, message: (error) ? error.message : ''}));
    }
  }

//DELETE MANY
const deleteManyAdminCommentTypesRequest = async (payload) => {
    return await axios.delete(`${api.admin_comment_types}/delete-many`, { headers: { Authorization: payload.token }, data: payload.data })
      .then(resp => resp)
      .catch(error => { return (error && error.response) ? error.response : error })
  };
  function* deleteManyAdminCommentTypes({ payload }) {
    try {
      const ret = yield call(deleteManyAdminCommentTypesRequest, payload);
  
      if (ret && ret.status == 202) {
        const { history, data } = payload;
  
        if (history) history.push('/app/exams/admin-comment-types')
        yield put( deleteManyAdminCommentTypesSuccess({ data }));
      }
      else yield put(deleteManyAdminCommentTypesError({ message: (ret.data) ? ret.data.msg : "Network Error" }))
  
    } catch (error) {
      console.log('saga err', error);
      yield put(deleteManyAdminCommentTypesError({ error, message: (error) ? error.message : '' }));
    }
  }
//////// WATCHERS
export function* watchGetList() {
    yield takeEvery(ADMIN_COMMENT_TYPES_GET_LIST, getAdminCommentTypesList);
  }


export function* watchGetOne() {
    yield takeEvery(ADMIN_COMMENT_TYPE_GET_ONE, getOneAdminCommentType);
  }
  

export function* watchEditOne() {
    yield takeEvery(ADMIN_COMMENT_TYPE_EDIT_ONE, editOneAdminCommentType);
  }
  
export function* watchAddOne() {
    yield takeEvery(ADMIN_COMMENT_TYPE_ADD_ONE, addAdminCommentType);
  }
  
export function* watchDeleteOne() {
    yield takeEvery(ADMIN_COMMENT_TYPE_DELETE_ONE, deleteOneAdminCommentType);
  }
export function* watchDeleteMany() {
    yield takeEvery(ADMIN_COMMENT_TYPES_DELETE_MANY,deleteManyAdminCommentTypes);
  } 
export default function* rootSaga() {
    yield all([
      fork(watchGetList),
      fork(watchGetOne),
      fork(watchEditOne),
      fork(watchAddOne),
      fork(watchDeleteOne),
      fork(watchDeleteMany)


    ]);
  }