import {
    TEACHER_COMMENTS_GET_LIST,
    TEACHER_COMMENTS_GET_LIST_SUCCESS,
    TEACHER_COMMENTS_GET_LIST_ERROR,

    TEACHER_COMMENT_GET_ONE,
    TEACHER_COMMENT_GET_ONE_SUCCESS,
    TEACHER_COMMENT_GET_ONE_ERROR,
 

    TEACHER_COMMENTS_GET_BY_FILTER,
    TEACHER_COMMENTS_GET_BY_FILTER_SUCCESS,
    TEACHER_COMMENTS_GET_BY_FILTER_ERROR,

    TEACHER_COMMENT_ADD_ONE,
    TEACHER_COMMENT_ADD_ONE_SUCCESS,
    TEACHER_COMMENT_ADD_ONE_ERROR,

    TEACHER_COMMENT_EDIT_ONE,
    TEACHER_COMMENT_EDIT_ONE_SUCCESS,
    TEACHER_COMMENT_EDIT_ONE_ERROR,

    TEACHER_COMMENT_DELETE_ONE,
    TEACHER_COMMENT_DELETE_ONE_SUCCESS,
    TEACHER_COMMENT_DELETE_ONE_ERROR,
   
    TEACHER_COMMENTS_DELETE_MANY,
    TEACHER_COMMENTS_DELETE_MANY_SUCCESS,
    TEACHER_COMMENTS_DELETE_MANY_ERROR,
  } from '../actions';


  //GET LIST
export const getTeacherCommentsList = (payload) => ({
  type: TEACHER_COMMENTS_GET_LIST,
    payload
  });
export const getTeacherCommentsListSuccess = (payload) => ({
    type: TEACHER_COMMENTS_GET_LIST_SUCCESS,
    payload
  });
export const getTeacherCommentsListError = (payload) => ({
    type: TEACHER_COMMENTS_GET_LIST_ERROR,
    payload
  });

//GET by filter
export const getTeacherCommentsByFilter = (payload) => ({
    type: TEACHER_COMMENTS_GET_BY_FILTER,
    payload
  });
export const getTeacherCommentsByFilterSuccess = (payload) => ({
    type:TEACHER_COMMENTS_GET_BY_FILTER_SUCCESS,
    payload
  });
export const getTeacherCommentsByFilterError = (payload) => ({
    type: TEACHER_COMMENTS_GET_BY_FILTER_ERROR,
    payload
  })
// GET One
export const getOneTeacherComment = (payload) => ({
    type: TEACHER_COMMENT_GET_ONE,
    payload
  });
export const getOneTeacherCommentSuccess = (payload) => ({
    type: TEACHER_COMMENT_GET_ONE_SUCCESS,
    payload
  });
export const getOneTeacherCommentError = (payload) => ({
    type: TEACHER_COMMENT_GET_ONE_ERROR,
    payload
  });

  // ADD ONE
export const addTeacherComment = (payload) => ({
    type: TEACHER_COMMENT_ADD_ONE,
    payload
  });
export const addTeacherCommentSuccess = (payload) => ({
    type:TEACHER_COMMENT_ADD_ONE_SUCCESS,
    payload
  });
export const addTeacherCommentError = (payload) => ({
    type:TEACHER_COMMENT_ADD_ONE_ERROR,
    payload
  });


// EDIT ONE
export const editOneTeacherComment = (payload) => ({
    type: TEACHER_COMMENT_EDIT_ONE,
    payload
  });
export const editOneTeacherCommentSuccess = (payload) => ({
    type: TEACHER_COMMENT_EDIT_ONE_SUCCESS,
    payload
  });
export const editOneTeacherCommentError = (payload) => ({
    type: TEACHER_COMMENT_EDIT_ONE_ERROR,
    payload
  });
// DELETE ONE
export const deleteOneTeacherComment = (payload) => ({
    type: TEACHER_COMMENT_DELETE_ONE,
    payload
  });
export const deleteOneTeacherCommentSuccess = (payload) => ({
    type: TEACHER_COMMENT_DELETE_ONE_SUCCESS,
    payload
  });
export const deleteOneTeacherCommentError = (payload) => ({
    type: TEACHER_COMMENT_DELETE_ONE_ERROR,
    payload
  });

// DELETE MANY
export const deleteManyTeacherComments = (payload) => ({
    type:   TEACHER_COMMENTS_DELETE_MANY,
    payload
  });
export const deleteManyTeacherCommentsSuccess = (payload) => ({
    type: TEACHER_COMMENTS_DELETE_MANY_SUCCESS,
    payload
  });
export const deleteManyTeacherCommentsError = (payload) => ({
    type: TEACHER_COMMENTS_DELETE_MANY_ERROR,
    payload
  });