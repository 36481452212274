import { all, call, fork, put, takeEvery } from "redux-saga/effects";
// import { getDateWithFormat } from "../../helpers/Utils";
import { api } from "../../constants/defaultValues";
import axios from "axios";

import { SPECIALITY_GET_LIST, SPECIALITY_ADD_ONE, SPECIALITY_GET_ONE, SPECIALITY_EDIT_ONE, SPECIALITY_DELETE_ONE
} from "../actions";

import {
  getSpecialitysListSuccess,
  getSpecialitysListError,

  getOneSpecialitySuccess,
  getOneSpecialityError,

  addSpecialitySuccess,
  addSpecialityError,

  editSpecialitySuccess,
  editSpecialityError,
  deleteOneSpecialitySuccess,
  deleteOneSpecialityError,
} from "./actions";


  //GET LIST
const getSpecialitysListRequest = async (payload) => {
  return await axios.get(api.specialities+`/list/${payload.inst_id}/${payload.offset}/${payload.limit}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getSpecialitysList({payload}) {
  try {
    const ret = yield call(getSpecialitysListRequest, payload);

    // console.log("RET", ret);
    
    
    if (ret && ret.status==200)
    yield put(getSpecialitysListSuccess({specialities: ret.data.specialities || [], count: ret.data.count || 0}));
    
    else yield put(getSpecialitysListError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getSpecialitysListError({error, message: (error) ? error.message : ''}));
  }
}



//GET ONE
const getOneSpecialitysRequest = async (payload) => {
  return await axios.get(api.specialities+`/get-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* getOneSpeciality({payload}) {  
  try {
    const ret = yield call(getOneSpecialitysRequest, payload);

    if (ret && ret.status==200)
    yield put(getOneSpecialitySuccess({speciality: ret.data.speciality}));
    
    else yield put(getOneSpecialityError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    yield put(getOneSpecialityError({error, message: (error) ? error.message : ''}));
  }
}




  //ADD ONE
const addSpecialityRequest = async (payload) => {
  return await axios.post(api.specialities, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* addSpeciality({payload}) {  
  try {
    const ret = yield call(addSpecialityRequest, payload);
    
    if (ret && ret.status==201){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/specialities')
      yield put(addSpecialitySuccess({speciality: ret.data.speciality}));
    }
    else yield put(addSpecialityError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(addSpecialityError({error, message: (error) ? error.message : ''}));
  }
}





  //EDIT ONE
const editOneSpecialityRequest = async (payload) => {
  return await axios.patch(`${api.specialities}/edit-one/${payload._id}`, payload.data, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* editOneSpeciality({payload}) {  
  try {
    const ret = yield call(editOneSpecialityRequest, payload);
    
    if (ret && ret.status==200){
      const { history } = payload;

      if(history) history.push('/app/my-institutions/specialities')
      yield put(editSpecialitySuccess({speciality: ret.data.speciality}));
    }
    else yield put(editSpecialityError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(editSpecialityError({error, message: (error) ? error.message : ''}));
  }
}


  // DELETE ONE
const deleteOneSpecialityRequest = async (payload) => {
  return await axios.delete(`${api.specialities}/delete-one/${payload._id}`, { headers: {Authorization: payload.token} })
  .then(resp=>resp)
  .catch(error=>{return (error && error.response) ? error.response : error})
};
function* deleteOneSpeciality({payload}) {  
  try {
    const ret = yield call(deleteOneSpecialityRequest, payload);
    
    if (ret && ret.status==202){
      const { history, index } = payload;

      if(history) history.push('/app/my-institutions/specialities')
      yield put(deleteOneSpecialitySuccess({index}));
    }
    else yield put(deleteOneSpecialityError({message: (ret.data) ? ret.data.msg : "Network Error"}))

  } catch (error) {
    console.log('saga err', error);
    yield put(deleteOneSpecialityError({error, message: (error) ? error.message : ''}));
  }
}


//////// WATCHERS
export function* watchGetList() {
  yield takeEvery(SPECIALITY_GET_LIST, getSpecialitysList);
}

export function* watchGetOne() {
  yield takeEvery(SPECIALITY_GET_ONE, getOneSpeciality);
}

export function* watchEditOne() {
  yield takeEvery(SPECIALITY_EDIT_ONE, editOneSpeciality);
}

export function* watchAddOne() {
  yield takeEvery(SPECIALITY_ADD_ONE, addSpeciality);
}

export function* watchDeleteOne() {
  yield takeEvery(SPECIALITY_DELETE_ONE, deleteOneSpeciality);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetList),
    fork(watchGetOne),
    fork(watchEditOne),
    fork(watchAddOne),
    fork(watchDeleteOne)
  ]);
}
