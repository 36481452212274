import {
	PERIODTYPE_GET_LIST,
	PERIODTYPE_GET_LIST_SUCCESS,
  PERIODTYPE_GET_LIST_ERROR,

  PERIODTYPE_GET_ONE,
	PERIODTYPE_GET_ONE_SUCCESS,
  PERIODTYPE_GET_ONE_ERROR,

  PERIODTYPE_SELECT_PERIOD,
 
  PERIODTYPE_GET_ACTUEL,
  PERIODTYPE_GET_ACTUEL_SUCCESS,
  PERIODTYPE_GET_ACTUEL_ERROR,

  PERIODTYPE_ADD_ONE,
  PERIODTYPE_ADD_ONE_SUCCESS,
  PERIODTYPE_ADD_ONE_ERROR,

  PERIODTYPE_EDIT_ONE,
  PERIODTYPE_EDIT_ONE_SUCCESS,
  PERIODTYPE_EDIT_ONE_ERROR,

  PERIODTYPE_DELETE_ONE,
  PERIODTYPE_DELETE_ONE_SUCCESS,
  PERIODTYPE_DELETE_ONE_ERROR,
  
  PERIODTYPES_DELETE_MANY,
  PERIODTYPES_DELETE_MANY_SUCCESS,
  PERIODTYPES_DELETE_MANY_ERROR
} from '../actions';
import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg';

import { nullLiteral } from '@babel/types';

const INIT_STATE = {
	period_types: [],
  count: 0,
  period_type: null,
  period_type_actuel: null,
  actual_period: null,
  selected_period:null,
  error: '',
};

export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {
		case PERIODTYPE_GET_LIST:
      return { ...state, loading: true, error: '', 
      count: 0
    };
    case PERIODTYPE_GET_LIST_SUCCESS:
      return { ...state, loading: false, 
        period_types: action.payload.period_types || [],
        count: action.payload.count || 0
      };
		case PERIODTYPE_GET_LIST_ERROR:
      return { ...state, loading: false, error: action.payload.message };



      case PERIODTYPE_SELECT_PERIOD:
      return { ...state, loading: true, error: '',
      selected_period: action.payload || null,

    };
    
  


      // GET ONE
    case PERIODTYPE_GET_ONE:
      return { ...state, loading: true, error: ''};
    case PERIODTYPE_GET_ONE_SUCCESS:
      return { ...state, loading: false, error: '',
      period_type: action.payload.period_type
      };
		case PERIODTYPE_GET_ONE_ERROR:
      return { ...state, loading: false, error: action.payload.message };

    
    //GET ACTUEL

    case PERIODTYPE_GET_ACTUEL:
        return { ...state, loading: true,get_period_type_success:false, error: ''};
    case PERIODTYPE_GET_ACTUEL_SUCCESS:
       
       return { ...state, loading: false, error: '',get_period_type_success:true,
       period_type_actuel: action.payload.period_type_actuel,
       actual_period: action.payload.actual_period,
     
      };
    case PERIODTYPE_GET_ACTUEL_ERROR:
       return { ...state, loading: false,get_period_type_success:false, error: action.payload.message };







      // ADD ONE
    case PERIODTYPE_ADD_ONE:
      return { ...state, loading: true, error: ''};
    case PERIODTYPE_ADD_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, 
        period_types: [action.payload.period_type, ...state.period_types]
      };
    case PERIODTYPE_ADD_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };





    
      // EDIT ONE
    case PERIODTYPE_EDIT_ONE:
      return { ...state, loading: true, error: ''};
    case PERIODTYPE_EDIT_ONE_SUCCESS:    
      NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
      return { ...state, loading: false, error: '',
      period_type: action.payload.period_type
      };
    case PERIODTYPE_EDIT_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

    




      // DELETE ONE
    case PERIODTYPE_DELETE_ONE:
      return { ...state, loading: true, error: ''};
    case PERIODTYPE_DELETE_ONE_SUCCESS:  
      const period_types = state.period_types 
      if(action.payload.index!=null && action.payload.index!=undefined)
      period_types.splice(action.payload.index, 1)

      return { ...state, loading: false, error: '',
        period_types
      };
    case PERIODTYPE_DELETE_ONE_ERROR:
      NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

     // DELETE MANY
     case PERIODTYPES_DELETE_MANY:
      return { ...state, loading: true, error: '' };
  case PERIODTYPES_DELETE_MANY_SUCCESS:
      const period_types_list = state.period_types
     

      if (action.payload.data.ids.length > 0) {
   
        for (let i = 0; i < action.payload.data.ids.length; i++) {

          let index_period_type= -1
          for (let j = 0; j < period_types_list.length; j++) {
            if (period_types_list[j]._id === action.payload.data.ids[i]) {
              index_period_type = j; break;
            }
          }
          if (index_period_type > -1) period_types_list.splice(index_period_type, 1)
        }
      }
      return {
        ...state, loading: false, error: '',
        period_types: period_types_list
      };
  case PERIODTYPES_DELETE_MANY_ERROR:
      NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
      return { ...state, loading: false, error: action.payload.message };

		default: return { ...state };
	}
}
