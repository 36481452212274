import {
    ABSENCE_GET_LIST,
    ABSENCE_GET_LIST_SUCCESS,
    ABSENCE_GET_LIST_ERROR,
 
    ABSENCE_GET_ONE,
    ABSENCE_GET_ONE_SUCCESS,
    ABSENCE_GET_ONE_ERROR,


    ABSENCE_GET_BY_FILTER,
    ABSENCE_GET_BY_FILTER_SUCCESS,
    ABSENCE_GET_BY_FILTER_ERROR,

    ABSENCE_ADD_ONE,
    ABSENCE_ADD_ONE_SUCCESS,
    ABSENCE_ADD_ONE_ERROR,

    ABSENCE_EDIT_ONE,
    ABSENCE_EDIT_ONE_SUCCESS,
    ABSENCE_EDIT_ONE_ERROR,

    ABSENCE_DELETE_ONE,
    ABSENCE_DELETE_ONE_SUCCESS,
    ABSENCE_DELETE_ONE_ERROR,
    ABSENCES_DELETE_MANY,
    ABSENCES_DELETE_MANY_SUCCESS,
    ABSENCES_DELETE_MANY_ERROR
  } from '../actions';

import { NotificationManager } from '../../components/common/react-notifications';
import  notificationMessages  from '../../lang/locales/notificationMsg'

const INIT_STATE = {
    absences: [],
    count: 0,
    absence: null,
    error: '',
    loading : false
  };

  function deleteAbsence(list, index) {
    list.splice(index, 1);
    return list
}


export default (state = INIT_STATE, action) => {
  const messages = notificationMessages()
	switch (action.type) {

        //GET LIST 
        case ABSENCE_GET_LIST:
        return { ...state, loading: true, error: '', 
        count: 0
        };
        case ABSENCE_GET_LIST_SUCCESS:
        return { ...state, loading: false, 
         absences: action.payload.absences || [],
          count: action.payload.count || 0
        };
        case ABSENCE_GET_LIST_ERROR:
        return { ...state, loading: false, error: action.payload.message };

       
        // GET ONE
        case ABSENCE_GET_ONE:
        return { ...state, loading: true, error: ''
        };

        case ABSENCE_GET_ONE_SUCCESS:   
        return { ...state, loading: false, error: '',
           absence: action.payload.absence
                
        };
        case ABSENCE_GET_ONE_ERROR:
        return { ...state, loading: false, error: action.payload.message };


          // GET BY FILTER
        case ABSENCE_GET_BY_FILTER:
              
          return { ...state, loading: true, error: ''};
        case ABSENCE_GET_BY_FILTER_SUCCESS:
            
          return { ...state, loading: false, error: '',
          absences: action.payload.absences || [],
          count: action.payload.count || 0
          };
        case ABSENCE_GET_BY_FILTER_ERROR:
            
        return { ...state, loading: false, error: action.payload.message };

        // ADD ONE
        case ABSENCE_ADD_ONE:
        return { ...state, loading: true, error: ''};
        case ABSENCE_ADD_ONE_SUCCESS:    
        NotificationManager.success(messages["Added"], messages["Success"],3000, null, null, '');
        return { ...state, loading: false, 
            absences: [action.payload.absence, ...state.absences]
        };
        case ABSENCE_ADD_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };
 


        // EDIT ONE
        case ABSENCE_EDIT_ONE:
        return { ...state, loading: true,edit_sucess:false, error: ''};
        case ABSENCE_EDIT_ONE_SUCCESS:    
        NotificationManager.success(messages["Edited"], messages["Success"], 3000, null, null, '');
        return { ...state, loading: false, edit_sucess:true,error: '',
        absence: action.payload.absence
        };
        case ABSENCE_EDIT_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false,edit_sucess:false, error: action.payload.message };



        // DELETE ONE
        case ABSENCE_DELETE_ONE:
        return { ...state, loading: true, error: ''};
        case ABSENCE_DELETE_ONE_SUCCESS:  
        //let new_absences = state.absences 
        
        //if(action.payload.index!=null && action.payload.index!=undefined)
        //new_absences=state.absences.splice(action.payload.index, 1)
        NotificationManager.success(messages["Deleted"], messages["Success"], 3000, null, null, '');
       // console.log("ABSENCE_DELETE_ONE_SUCCESS : ", new_absences);
        return { ...state, loading: false, error: '',
        absences :  deleteAbsence(state.absences, action.payload.index)
        };
        case ABSENCE_DELETE_ONE_ERROR:
        NotificationManager.warning(action.payload.message ||  messages["Unkown Error"], messages["Error"], 3000, null, null, '');
        return { ...state, loading: false, error: action.payload.message };


          // DELETE MANY
          case ABSENCES_DELETE_MANY:
            return { ...state, loading: true, error: '' };
          case ABSENCES_DELETE_MANY_SUCCESS:
            const absences_list = state.absences
          

            if (action.payload.data.ids.length > 0) {

              for (let i = 0; i < action.payload.data.ids.length; i++) {

                let index_absence = -1
                for (let j = 0; j < absences_list.length; j++) {
                  if (absences_list[j]._id === action.payload.data.ids[i]) {
                    index_absence = j; break;
                  }
                }
                if (index_absence > -1) absences_list.splice(index_absence, 1)
              }
            }
            return {
              ...state, loading: false, error: '',
              absences: absences_list
            };
          case ABSENCES_DELETE_MANY_ERROR:
            NotificationManager.warning(action.payload.message || messages["Unkown Error"], messages["Error"], 3000, null, null, '');
            return { ...state, loading: false, error: action.payload.message };
                  
        default: return { ...state };
    }

}
  